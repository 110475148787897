.infoBar {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
}

.infoBarText {
  font-size: small;
  padding-left: .5rem;
}
