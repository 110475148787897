@import "../../../../media";
@import "../../../theme/fonts";
@import "../../../theme/colours";
@import "../base/variables";

:global {
  // BORDER
  .dx-texteditor.dx-texteditor.dx-texteditor {
    border: .1rem solid $brown-grey;
    border-radius: .2rem;

    &.dx-state-focused {
      @include focus-border;
    }
  }

  .dx-datebox-calendar.dx-state-focused {
    outline: .2rem solid $ocean;
    outline-offset: -2px;
  }
}

.inputLabelWrapper {
  margin-bottom: var(--space-medium);
  width: $input-width;
}

.fullWidthInputLabelWrapper {
  width: 100%;
}

div.textContainer.textContainer.textContainer {
  font-family: $font-body !important;
  font-size: $regular !important;

  :global {
    //placeholder
    .dx-placeholder::before {
      @include base-input;
      @include placeholder-color;

      border: none;
      margin-left: .2rem; // match placeholder position with text position
    }

    .dx-dropdowneditor-button {
      border-radius: 0;
      padding: 0;
      position: relative;
      width: 3rem;

      .dx-button-content {
        border-radius: .2rem;
        height: calc(100% - .4rem);
        position: absolute;
        right: .2rem;
        top: .2rem;
        transition: background .2s ease-in-out;

        &::before {
          @include divider;

          content: "";
          display: block;
          height: calc(100% - 1.4rem);
          margin-left: .5rem;
          position: relative;
          right: .35rem;
          width: .15rem;
        }

        &:hover {
          background: $sl-light-grey;
        }
      }

      &:hover .dx-dropdowneditor-icon::before {
        color: darken($ocean, 10%);
      }

      .dx-dropdowneditor-icon {
        background-color: transparent !important;
        border-radius: 0;
        width: 3rem;

        &::before {
          color: $ocean;
          transition: color .2s ease-in-out;
        }
      }
    }
  }

  input {
    @include base-input;
    @include input-typography;

    background: transparent;
    border: none;
    height: auto;
  }

  &.textContainerMini {
    input {
      padding: .4rem;
    }
  }
}

.textContainerError {
  border-color: $brick !important;

  :global {
    .dx-dropdowneditor-icon::before {
      color: $brick !important;
    }
  }
}

.textContainerDisabled {
  background-color: $pebble05 !important;
  color: $brown-grey !important;
  cursor: not-allowed !important;
  // disabled
  opacity: 1 !important;

  input {
    color: $brown-grey !important;
  }

  :global {
    .dx-dropdowneditor-icon {
      &::before {
        color: $brown-grey !important;
      }
    }
  }
}
