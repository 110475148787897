@import 'grid.scss';

.saveButtonWrapper {
  @include grid_btn(2);
}

.saveButton {
  min-width: unset;
}

.thirdPartyContentWrapper {
  border: none;
}
