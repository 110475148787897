@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.contentWrapper {
  display: flex;
  line-height: normal;

  .logType {
    color: $sl-dark-grey;
    flex-shrink: 0;
    margin-right: 1rem;
    max-width: 12rem;
    min-width: 7rem;
    width: 20%;
  }

  .content {
    color: $sl-dark-grey;
    margin: 0;
  }
}
