@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.summaryContentWrapper {
  background-color: $pebble05;
  border: none;
  display: flex;
  min-height: 5rem;
  padding: var(--space-s);

  > * {
    &:not(:first-of-type) {
      border-top: none;
    }
  }
}

.summaryFieldWrapper {
  padding-right: var(--space-xl);
}
