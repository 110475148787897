@import "shared/theme/fonts.scss";

.swimLanesWrapper {
  border: 1px solid darken($sl-grey, 10%);
  display: flex;
  flex-direction: column;
  height: 90vh;
  margin-top: 20px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.swimLanesHeader {
  display: flex;
  position: sticky;
  top: 0;
  width: fit-content;
  z-index: 1;

  .statusName {
    background-color: $white;
    border-bottom: solid .1rem darken($sl-grey, 10);
    flex-shrink: 0;
    font-size: 1.1rem;
    font-weight: 600;
    height: 4.2rem;
    margin-bottom: .5rem;
    padding: 0 1rem 0 2rem;
    position: relative;
    width: 27rem;

    .statusDiv {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .statusLoanAmount {
      font-weight: normal;
    }

    &::after {
      border-bottom: 2.1rem solid transparent;
      border-left: 1rem solid $white;
      border-top: 2.1rem solid transparent;
      content: "";
      display: block;
      position: absolute;
      right: -1rem;
      top: 0;
      z-index: 10;
    }

    &::before {
      border-bottom: 2.1rem solid transparent;
      border-left: 1rem solid darken($sl-grey, 10%);
      border-top: 2.1rem solid transparent;
      content: "";
      display: block;
      left: .1rem;
      position: absolute;
      top: 0;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.swimLanesContent {
  display: flex;
  flex: 1;

  .applicationsColumn {
    min-height: fit-content;
    padding: 1rem;
    position: relative;
    width: 27rem;
  }

  .draggingColumns {
    background-color: $green;
    border: 2px dashed blue;
    border-radius: 4px;

    & * {
      opacity: .5;
    }
  }
}

.droppableContainer {
  display: flex;
  height: fit-content;
  min-height: 100%;
}

.emptyMask {
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(var(--space) * 10);
}

.notesReminderWapper {
  max-width: 44.8rem;
  width: 35rem;

  .notesReminderContents {
    margin-bottom: 1.3rem;
    margin-top: 1rem;
  }

  .notesButton {
    align-items: center;
    display: flex;
    font-size: $regular;
    justify-content: space-around;
  }

  .notesDoneButton {
    align-items: center;
    display: flex;
    font-size: $regular;
    justify-content: space-around;
  }
}
