@import "grid.scss";
@import "shared/theme/fonts.scss";

.dashboard {
  .welcome {
    font-family: $font-header;
    font-size: 2rem;
  }

  .content {
    @include grid(12);

    .logContainer {
      @include span(12);
      @include row(2);

      @include desktop {
        @include span(6);
        @include row(1);
      }
    }

    .taskContainer {
      @include span(12);

      @include desktop {
        grid-column: 8 / span 5;
      }
    }
  }
}
