@import "shared/theme/colours.scss";
@import "media.scss";
@import "./ApplicationNotFound.scss";

.htmlAtApplicationDetail {
  @include phone {
    font-size: 70% !important;
  }
}

.page {
  font-size: 1.3rem;
}

.applicationDetailsHeaderContent {
  @include phone {
    padding: var(--space) 0;
  }

  align-items: center;
  display: flex;
  justify-content: space-between;

  h1 {
    @include phone {
      padding-bottom: var(--space-medium);
      padding-top: var(--space-medium);
    }

    padding-top: var(--space-large);
  }

}

.addNoteButton {
  @include phone {
    height: 3.5rem;
    min-width: 10rem;
  }

  align-items: center;
  display: flex;
  min-width: 13rem;
  padding: var(--space-small) var(--space-small) var(--space-small) var(--space-medium);

  .noteButtonText {
    @include phone {
      margin-right: 2.5rem;
    }

    margin-right: 3.5rem;
  }
}

.applicationNewNoteModal {
  @include phone {
    height: 48vh;
    min-height: 25rem;
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  @include tablet-portrait {
    height: 48vh;
    min-height: 25rem;
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  height: 50vh;
  width: 50vw;

  & > div[class*="Modal_body"]:nth-child(2) {
    overflow: hidden;
  }

  & > div[class*="Modal_footer"] {
    border-top: solid .1rem $sl-grey;
  }

  .newNoteModalTitle {
    font-weight: bold;
    padding-bottom: 0;
  }

  .applicationNoteEditor {
    padding: var(--space) 0;
    width: 100%;
  }

  .saveNoteButton {
    min-width: 13rem;
  }
}

:global(.dx-htmleditor) {
  background-color: white;
  height: 100%;
  width: 100%;
}

.loadingGif {
  margin: 0 calc(50% - 5rem);
  width: 10rem;
}

.title {
  font-size: 1.1rem;
}

:global(.link) {
  color: $ocean;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  padding: calc(var(--space) * .2) 0;

  &:hover {
    text-decoration: none;
  }
}

.applicationDetailsTitle {
  font-family: Museo-Sans-700, sans-serif;
  font-size: 2rem;
  letter-spacing: .02rem;
  margin-top: var(--space-medium);
}
