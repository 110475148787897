@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "media.scss";

.contentWrapper {
  align-items: flex-end;
  display: flex;

  .content {
    color: $mine-shaft;
    font-size: $regular;
    margin-right: 2rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .placeholder {
    color: $brown-grey;
    font-size: $regular;
    margin-right: 2rem;
  }

  .editIcon {
    flex-shrink: 0;
    line-height: 1.1rem;
  }
}

.saveButton {
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
  min-width: 13rem;
  width: 13rem;
}

.bodyWrapper {
  margin: 1.2rem 0 0;
  width: 100%;
}

.inputWrapper {
  > span {
    color: $sl-dark-grey;
    padding: .7rem 0;
  }
}

.errorMessage {
  color: $brick;
  font-size: 1.1rem;
  height: 2rem;
  line-height: normal;
}

.overlay {
  justify-content: center;

  > div {
    align-self: center;
    margin: unset;

    @include less-than-desktop {
      align-self: flex-start;
    }
  }
}
