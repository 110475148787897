@import "fonts.scss";
@import "colours.scss";
@import "sizes.scss";

.pageTitle {
  font-family: $font-header-bold;
  font-size: $xxxlarge;
  margin-bottom: $size-xxlarge;
  margin-top: $size-mediumLarge;
}
