@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";

.overrideSectionWrapper {
  @include layout-details;

  grid-row-gap: 0;
}

.sectionWrapper {
  margin-bottom: 1.5rem;
}

.fieldWrapper {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  grid-column: unset;
  grid-column-end: span 2;
}

.buttonWrapper {
  margin-top: 2rem;

  @include layout-details;
}

.singleRow {
  grid-row-gap: 0;
}

.saveButton {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 60%;

  @include place(2, 1/span 2);
}

.addButton {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: auto;
  padding: 0 1rem;
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  padding: var(--space-m);

  button {
    padding: 0;
  }
}

.actionWrap {
  align-items: center;
  background: $pebble15;
  display: grid !important;
  font-family: $font-body-bold;
  font-size: $xlarge;
  margin: 0 !important;
  min-height: 4.8rem;
  padding: 0 1rem;

  @include layout_details;

  :global {
    .show-more-icon {
      grid-column: span 1;
      grid-column-end: -1;
      grid-row: 1;
      justify-self: end;
    }

    .show-more-name {
      grid-column-end: -2;
      grid-column-start: 1;
      grid-row: 1;
    }
  }
}
