@import "shared/theme/colours.scss";

.applicationCard {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: var(--space-large);
  position: relative;

  .lenderInfo {
    align-items: center;
    border-bottom: .1rem solid $sl-grey;
    display: flex;
    font-size: 1.3rem;
    height: 3.5rem;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 var(--space-small);

    .lenderIcon {
      align-items: center;
      display: flex;
      height: calc(100% - .8rem);
    }
  }

  .applicationInfo {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: var(--space);
    width: 100%;

    p {
      margin-bottom: 0;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    & > div {
      flex-wrap: wrap;
      min-height: 5rem;
      width: 11.2rem;

      &:nth-child(even) {
        text-align: right;
      }

      &:nth-child(n+2) {
        min-height: 4rem;
      }

      &.dateCreated {
        grid-column-start: 2;
        grid-row-start: 2;
      }

      .title {
        font-size: 1.1rem;
        letter-spacing: .01rem;
      }

      .info {
        font-size: 1.3rem;
        letter-spacing: .02rem;
        white-space: pre-wrap;
      }
    }
  }
}

.cardWrapper {
  display: flex;
}

.card {
  background-color: $white;
  border: .5px solid $sl-grey;
  border-radius: 1.4px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, .3);
  transition: transform 200ms;
  width: 25rem;

  &.draggable:hover {
    cursor: pointer;
    transform: scale(1.02) rotate(-1deg);
  }

  &.dragged {
    cursor: grabbing;
    transform: rotate(-10deg);
  }

  &.dropped {
    transform: scale(1.02) rotate(-1deg);
    transition: transform 1ms;
  }
}

.placeholder {
  border: none;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  width: 0;
}
