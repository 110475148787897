@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "shared/theme/colours.scss";

.insuranceReferrer {
  @include phone {
    display: flex;
    flex-direction: column;
  }

  display: grid;
  grid-gap: var(--space-s) var(--space-m);
  grid-template: repeat(7, auto) / 208px 208px 140px;
}

@mixin select {
  width: 100% !important;

  label {
    color: $sl-dark-grey;
    font-family: $font-body !important;
    font-size: 1.2rem !important;

    :global(.select__control) {
      border-color: $brown-grey;
      color: $mine-shaft;
    }
  }
}

@mixin textInput {
  span {
    color: $sl-dark-grey;
    font-size: 1.2rem;
  }

  input[disabled] {
    background-color: $sl-light-grey;
  }
}

.referrer {
  @include select;

  grid-area: 1 / 1;

  & > div:last-child {
    font-size: 1.2rem;
    padding: 0 0 .4rem 0;
  }
}

.applicant {
  @include select;

  grid-area: 1 / 2;
}

.phoneNumber {
  @include textInput;

  grid-area: 2 / 1;
  margin-bottom: .5rem;
}

.alternativePhoneNumber {
  @include textInput;

  grid-area: 2 / 2;
  margin-bottom: .5rem;
}

.preferredTime {
  grid-area: 2 / 3;
  width: 100%;

  > span {
    color: $sl-dark-grey;
    display: block;
    font-family: $font-body;
    font-size: 1.2rem;
    margin-bottom: .5rem;
    padding: .4rem 0;
  }

  :global(.dx-placeholder) {
    color: $mine-shaft;
  }

  :global(.dx-datebox.dx-editor-outlined) {
    border-color: $brown-grey;
    border-radius: .2rem;
    height: 3.3rem;
    width: 100% !important;
  }

  :global(.dx-state-disabled.dx-widget) {
    background-color: $sl-light-grey;
    color: $brown-grey;
    cursor: not-allowed;
    opacity: 1;
  }

  :global(.dx-texteditor-input-container) {
    font-family: $font-body;
    font-size: 1.3rem;

    input[disabled] {
      background-color: $sl-light-grey;
      color: $brown-grey;
      cursor: not-allowed;
    }
  }

  :global(.dx-state-disabled .dx-dropdowneditor-button .dx-dropdowneditor-icon) {
    opacity: .5;
  }

  :global(.dx-state-disabled .dx-placeholder) {
    color: $brown-grey;
  }
}

.agreementCheckContainer {
  grid-area: 6 / 1 / 6 / 4;
  padding: var(--space-s) 0;

  input {
    flex-shrink: 0;
  }

  span {
    margin-left: var(--space-s);
  }

  .description {
    font-weight: bold;
  }

  .wrapper {
    align-items: center;
    border: 1px solid $snowgum;
    border-radius: 3px;
    box-sizing: border-box;
    display: grid;
    margin: var(--space) auto;
    padding: var(--space-medium);

    span:first-of-type {
      grid-area: 1 / 2;
    }

    span:nth-of-type(2) {
      grid-area: 2 / 2;
      margin-top: var(--space-l);
    }
  }
}

.switch {
  grid-area: 1;
  margin-bottom: 0;
  margin-right: var(--space-medium);
}

.agreement2CheckContainer {
  grid-area: 7 / 1 / 7 / 4;
  padding-left: var(--space-large);

  input {
    flex-shrink: 0;
  }

  span {
    margin-left: var(--space-s);
  }
}

.optOutButton {
  height: 4rem;
  margin: 0 var(--space-xxxl);
  padding: 0 calc(var(--space) * 5);

  &:disabled {
    color: $brown-grey;
    cursor: not-allowed;
  }
}

.emailAddress {
  @include textInput;

  grid-area: 3 / 1 / 3 / 4;
  margin-bottom: .5rem;
}

.referralReason {
  @include select;

  grid-area: 4 / 1 / 4 / 4;
  margin-bottom: .5rem;
}

.referralReasonInput {
  height: auto;
  margin-bottom: 0;
  min-height: 3.3rem;
  width: 100%;

  div {
    overflow: inherit;
    text-overflow: inherit;
    white-space: normal;
  }
}

.insuranceReferrerReasonsLabel {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.externalInfoLink {
  margin-bottom: 0;
}

.externalInfoText {
  display: inline;
  font-size: 1.2rem;
  margin-left: var(--space-small);
}

.message {
  @include textInput;

  display: flex;
  flex-direction: column;
  grid-area: 5 / 1 / 5 / 4;

  textarea {
    flex-grow: 1;
  }

  textarea[disabled] {
    background-color: $sl-light-grey;
    color: $brown-grey;
    cursor: not-allowed;
  }

  span {
    padding: 0 0 .6rem 0;
  }
}

.buttonGroup {
  margin: var(--space-medium) 0;
}

.insuranceSentMessage {
  margin-bottom: var(--space);
}
