@import "shared/theme/colours.scss";
@import "media.scss";
$navbar-height: 5.2rem;

.topNavbar {
  background-color: $sl-light-grey;
  box-shadow: 0 .2rem .9rem 0 rgba(0, 0, 0, .2);
  color: $sl-dark-grey;
  display: flex;
  font-family: Museo-Sans-700, sans-serif;
  font-size: 1.3rem;
  height: $navbar-height;
  justify-content: space-between;
  padding: 0 var(--space-large);

  .section {
    display: flex;
    height: 100%;
  }

  .desktopMenuContainer {
    @include desktop {
      display: flex;
    }

    display: none;
  }

  .mobileMenuContainer {
    @include desktop {
      display: none;
    }

    display: block;

    .menuPopover {
      background: $sl-light-grey;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2);
      left: -2rem;
      max-width: 35rem;
      overflow: hidden;
      position: absolute;
      top: 100%;
      transition: max-height .4s;
      width: 95vw;
      z-index: 2021;
    }

    .menuToggle {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
    }
  }
}
