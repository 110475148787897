.switch {
  background-color: rgb(233, 234, 237);
  border-radius: 99999px;
  color: rgb(133, 133, 133);
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  height: 30px;
  margin-bottom: 0;
  min-width: 70px;
  padding: 2px;
  position: relative;
  transition: background-color .3s;
  user-select: none;
  white-space: nowrap;

  & > div {
    align-items: center;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    padding: 0 var(--space);
    width: 50%;
  }

  & > div:nth-child(1) {
    opacity: 0;
  }

  & > div:nth-child(2) {
    opacity: 1;
  }

  .slideContainer {
    border-radius: 99999px;
    display: block;
    height: 100%;
    left: 0;
    padding: var(--space-s);
    position: absolute;
    top: 0;
    width: 100%;

    .slide {
      background-color: white;
      border-radius: 99999px;
      height: 100%;
      left: 0;
      position: relative;
      transition: left .3s;
      width: 50%;
    }
  }
}

.input {
  height: 0;
  opacity: 0;
  width: 0;
}

.input[type="checkbox"]:checked + label {
  background-color: rgb(58, 71, 81);
  color: white;

  & > div:nth-child(1) {
    opacity: 1;
  }

  & > div:nth-child(2) {
    opacity: 0;
  }

  .slideContainer {
    .slide {
      background: white;
      left: 50%;
    }
  }
}

.input[type="checkbox"]:disabled + label {
  cursor: default;
  opacity: .5;
}

.input:focus + label {
  outline: auto 5px -moz-focus-ring-color;
  outline: auto 5px -webkit-focus-ring-color;
  outline: auto 5px -ms-focus-ring-color;
  outline: auto 5px -o-focus-ring-color;
}
