@import "media.scss";
@import "shared/theme/fonts.scss";

.nav {
  @include desktop {
    border: solid .1rem $sl-grey;
    margin-right: calc(var(--space) * 1.6);

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }

  @include less-than-desktop {
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }

  border: solid .2rem $ocean;
  flex: 1;
  margin-right: 0;
  max-width: 40rem;
}

.navBarTitleWrapper {
  border-bottom: 1px solid $sl-grey;
  position: relative;
}

.navBarIcon {
  @include desktop {
    display: none;
  }

  position: absolute;
  right: .5rem;
  top: .5rem;
}

.navBarTitle {
  display: inline-block;
  font-family: Museo-Sans-300, sans-serif;
  padding: var(--space);
}

.navItemWrapper {
  margin: var(--space-small);
  max-height: 50rem;
  padding: 0;
  position: relative;

  &.invisible {
    margin: 0;
    max-height: 0;
    overflow: hidden;
  }
}

.inVisible {
  @include desktop {
    display: block;
  }

  display: none;
}

.navItem {
  height: 3rem;
  line-height: 3rem;
  list-style: none;
  margin: var(--space-small) 0;
  padding-left: calc(var(--space) * 1.8);

  &:hover {
    background-color: $pebble15;
  }

  .navItemButton {
    color: $mine-shaft;
    display: flex;
    font-family: $font-body;
    justify-content: space-between;
    width: 100%;
  }

  span {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.active {
  background-color: $pebble05;
  font-weight: 600;

  &::before {
    background-color: $bluestone;
    content: "";
    display: block;
    height: 3rem;
    left: 0;
    position: absolute;
    width: .6rem;
  }
}

.circleNumber {
  background-color: $sl-gold;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;

  .number {
    color: $white;
    display: block;
    font-size: 1rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
  }
}
