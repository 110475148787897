@import "../base/variables";
@import "shared/theme/styles.scss";
@import "shared/theme/colours.scss";
$height: 3.3rem;

.wrapper {
  position: relative;
}

.checkboxDropdownError {
  :global {
    border-color: $brick !important;

    svg path {
      fill: $brick;
    }
  }
}

.fakeSelectBox {
  @include input-border;
  @include input-wrapper-label;

  display: flex;
  height: $height;
}

input[type="checkbox"] {
  align-self: baseline;
  margin-top: .3rem;
}

.text {
  @include input-typography;

  color: $mine-shaft;
  flex: 1;
  overflow: hidden;
  padding: .6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder {
  color: $brown-grey;
}

.divider {
  @include divider;

  margin-bottom: .9rem;
  margin-top: .9rem;
  width: .15rem;
}

.rhsBox {
  align-items: center;
  align-self: center;
  border-radius: .2rem;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: -.2rem .2rem;
  padding: 0 .6rem;
  transition: all .2s ease-in-out;
  width: 2.8rem;

  &:hover {
    background: $sl-light-grey;
  }
}

.chevron {
  background: red;
}

.popover {
  @include shadow;

  background: white;
  padding: 10px;
  position: fixed;
  text-align: left;
  top: 0;
  white-space: normal;
  width: 100%;
  word-break: break-word;
  z-index: 600;
}

.ul {
  margin-bottom: 0;
  padding-left: 2rem;

  > li {
    margin-bottom: .2rem;
  }
}
