@import "shared/theme/colours.scss";

.dx-datebox-wrapper {
  .dx-calendar-cell.dx-calendar-empty-cell {
    background: none;
  }

  .dx-button-content {
    font-family: Museo-Sans-300, sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .dx-toolbar-before .dx-toolbar-button {
    border: solid .1rem $bluestone;
    border-radius: .2rem;
  }

  .dx-calendar-cell.dx-calendar-selected-date {
    &.dx-calendar-contoured-date,
    &.dx-calendar-today.dx-calendar-contoured-date {
      background-color: $bluestone;
      box-shadow: none;
    }

    &.dx-calendar-today.dx-calendar-contoured-date span {
      border: none;
      box-shadow: none;
    }

    border-radius: 3px;
  }

  .dx-calendar-cell {
    &.dx-calendar-today span {
      border: none;
      font-weight: 800;
    }

    &.dx-calendar-selected-date span {
      background-color: $bluestone;
    }

    &.dx-calendar-contoured-date span {
      box-shadow: none;
    }
  }

  .dx-toolbar-before .dx-button-content {
    background-color: $white;
    color: $bluestone;
    width: 7rem;
  }

  .dx-button {
    border: none;
  }

  .dx-toolbar-after .dx-toolbar-button {
    &:first-child .dx-button-content {
      background-color: $bluestone;
      border-radius: .2rem;
      color: $white;
      width: 10rem;
    }

    &:last-child .dx-button-content {
      background-color: transparent;
      border: none;
      color: $bluestone;
      width: 10rem;
    }

    .dx-popup-cancel .dx-button-content {
      color: $ocean;
    }
  }

  .dx-button-mode-contained.dx-state-hover {
    background-color: transparent;
    border: none;
  }

  .dx-calendar-navigator .dx-button-content .dx-button-text {
    color: $mine-shaft;
  }

  .dx-calendar-navigator-previous-view,
  .dx-calendar-navigator-previous-month,
  .dx-calendar-navigator-next-view,
  .dx-calendar-navigator-next-month {
    &.dx-button {
      .dx-icon {
        color: $ocean;
      }
    }
  }
}

.dx-datebox {
  .dx-invalid-message {
    display: none;
  }

  .dx-texteditor-input-container::after {
    display: none;
  }
}

.dx-datebox-calendar .dx-dropdowneditor-icon {
  color: $ocean;
}
