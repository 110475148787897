@import "shared/theme/colours.scss";
@import "grid.scss";

.showMoreHeader {
  align-items: center;
  background: $pebble15;
  display: grid !important;
  font-family: museo-sans-500, sans-serif;
  font-size: 1.6rem;
  margin: 0 !important;
  padding: 0 1rem;

  @include layout_details;

  :global {
    .show-more-icon {
      grid-column: span 1;
      grid-column-end: -1;
      grid-row: 1;
      justify-self: end;
    }

    .show-more-name {
      grid-column-end: -2;
      grid-column-start: 1;
      grid-row: 1;
    }
  }
}

.saveButtonWrapper {
  @include grid_btn(2);
}

.saveButton {
  margin-top: var(--space-m);
}
