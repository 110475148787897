@import "media.scss";

.tooltip {
  cursor: pointer;
}

.hintSmall:hover::after {
  max-width: 30rem;
}

.hintLarge:hover::after {
  width: 80rem;

  @include phone {
    width: 10rem;
  }
}
