div.box {
  border: none;
  margin-bottom: 0;
  width: 100%;
}

.wrapperInput {
  margin-bottom: 0;
  width: 100%;
}
