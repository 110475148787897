.position-relative.dx-g-bs4-header-cell:hover {
  .bg-primary {
    background-color: rgb(43, 110, 210) !important;
    height: calc(100% - 4px);
    left: 8px;
    top: 2px;
  }
}

.dx-g-bs4-header-cell.dx-g-bs4-fixed-cell {
  .dx-g-bs4-resizing-control-wrapper {
    right: -6px;
  }
}
