@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "shared/theme/colours.scss";

.container {
  border: solid .2rem $sl-grey;
  border-radius: .2rem;
  font-size: $regular;
  margin-top: 3.4rem;
  padding: var(--space-xxl);
  white-space: pre-wrap;
  width: 100%;
}
