@import "grid.scss";
@import "shared/theme/spacing.scss";

.container {
  background: white;
  border-radius: .2rem;
  box-shadow: 0 .8rem 1.6rem 0 rgba(0, 0, 0, .2);
  margin-top: .5rem;
  padding: 10px;
  position: absolute;
  width: 40rem;
  z-index: 20;
}

.buttonContainer {
  @include grid(2);

  margin-top: $space-medium;

  button {
    min-width: auto;
  }
}

.textInput {
  margin: 0;
}
