@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";

.plainText {
  align-items: center;
  display: flex;
  font-size: $large;
  min-height: 3.1rem;
  width: 100%;
  word-break: break-word;
}
