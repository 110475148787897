@import "grid.scss";
@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";

.loanSplitInput {
  @include grid(4);
}

.modalBody {
  @include grid(2, $space-large);

  @include tablet-portrait {
    display: initial;
  }

  @include phone {
    display: initial;
  }

  margin-top: $space-large;
  width: 100%;

  :global {
    .input-label-wrapper {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.accountNumber {
  & div:last-child {
    font-family: $font-body-bold;
  }
}

.numberRemoveWrapper {
  display: flex;
}

.removeButton {
  color: $brick;
  font-size: $small;
  margin-top: 2.3rem;
  padding: $space-small $space;
}

.removeButtonWrapper {
  width: 9rem;
}

.datePicker {
  @include span(2);
}

.inputColumns {
  @include phone {
    display: initial;
  }

  display: grid;
  grid-column-gap: $space-medium;
  grid-row-gap: $space;
  grid-template-columns: 1fr 1fr;
  margin-bottom: $space-medium;
}

.whiteBox {
  background: white;
  margin-bottom: $space-medium;
  padding: $space-medium;
}

.compliance {
  padding: $space-medium;

  .declaration {
    margin-top: $space-large;

    .checkBoxLabel {
      align-items: initial;
    }

    .checkBoxInput {
      margin-top: .3rem;
    }
  }

  .boldText {
    font-family: $font-body-bold;
  }
}
