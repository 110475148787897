.circleNumber {
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  width: 2rem;

  .number {
    display: block;
    font-size: 1rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
  }
}
