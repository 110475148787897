@import "shared/theme/fonts.scss";

.applicationNotFound {
  border: .1rem solid $sl-grey;
  border-radius: .1rem;
  margin: calc(var(--space) * 3.4) 0 0;
  padding: calc(var(--space) * 3);
  text-align: left;

  .title {
    font-family: $font-header-bold;
    font-size: 1.8rem;
    font-weight: bold;
  }
}
