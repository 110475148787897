@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.commentContainer {
  margin-bottom: calc(3 * var(--space));
}

.commentWrapper {
  display: flex;

  .left {
    min-height: 7rem;
    position: relative;

    &::before {
      border: solid .05rem $sl-blue;
      content: "";
      height: calc(100% - 2.2rem);
      left: 47%;
      position: absolute;
      top: 4.3rem;
    }

    .iconWrapper {
      height: 2.4rem;
      margin-top: 2rem;
    }
  }

  &:last-child {
    .left {
      &::before {
        display: none;
      }
    }
  }

  .right {
    flex: 1;
    margin-bottom: .5rem;
    margin-left: 1rem;

    .commentDateTime {
      height: 2rem;
      margin-left: .4rem;

      .commentDate {
        color: $mine-shaft;
        font-family: $font-body-bold;
        font-size: $regular;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
      }

      .commentYear {
        color: $brown-grey;
        font-family: $font-body;
        font-size: $regular;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        margin-left: 1rem;
      }
    }

    .commentBubble {
      background: white;
      border: solid 0 $sl-grey;
      border-radius: .4rem;
      box-shadow: none;
      margin-right: 0;
      max-width: 618px;
      padding: calc(.75 * var(--space)) var(--space-large) var(--space-small);

      &::before {
        border-right-color: transparent;
      }

      &::after {
        top: .3rem;
      }

      > pre {
        white-space: pre-wrap;
      }

      .commentTitle {
        color: black;
        font-family: $font-body;
        font-size: 1.1rem;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
      }

      .commentContent {
        color: black;
        font-family: $font-body;
        font-size: $large;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        margin-bottom: var(--space-medium);
        margin-top: var(--space-small);
      }
    }
  }
}

.commentTextArea {
  width: 100%;

  > label {
    font-family: $font-body-bold;
    font-size: $xlarge;
    font-weight: normal;
    margin-top: calc(var(--space) * 3);
    padding: 0;
    color: #323232;
  }

  > textarea {
    height: 65px;
    margin-top: var(--space);
    max-height: 265px;
    min-height: 65px;
    padding: var(--space-small);
    resize: vertical;
  }
}

.modalFooter {
  background: $white;
  padding: 0 calc(var(--space) * 3);
  width: 100%;
  z-index: 0;
}

.buttonGroup {
  margin-bottom: var(--space-large);
  margin-top: var(--space-medium);

  button {
    border-radius: .2rem;
    font-family: $font-body;
    font-size: $regular;
    font-weight: 600;
    height: 3rem;
    min-width: 13.2rem;
    padding: 0;
  }

  .deleteButton {
    border-color: $brick;
    color: $brick;
    float: right;

    .buttonText {
      margin-right: 1rem;
    }

    * {
      vertical-align: middle;
    }
  }
}
