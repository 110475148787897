@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.commissionQueryModal {
  min-height: 45rem;
  width: 52rem;
}

.commissionQueryFooter {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.textArea {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;

  > textarea {
    resize: none;
  }
}

.error {
  color: $brick;
  font-size: 1.3rem;
  padding: .8rem 0;
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
