@import "shared/theme/colours.scss";

.note {
  background-color: $pebble15;
  display: inline-block;
  padding: var(--space) var(--space-l);
}

.withSideBar {
  border-left-style: solid;
  border-left-width: .4rem;
}
