@import "shared/theme/colours";
@import "shared/theme/fonts";

.validationModal {
  font-size: $regular;

  .description {
    margin: 1.2rem 0;
  }

  .listTitle {
    font-family: $font-header-bold;
  }

  .header {
    color: $mine-shaft;
    font-family: $font-header-bold;
    font-size: $xlarge;
    font-weight: 900;
  }
}

.section {
  margin: 1rem 0;

  > .node {
    > .name:first-child {
      color: $mine-shaft;
      font-family: $font-header-bold;
    }
  }
}

.node {
  margin: .5rem 0 .5rem 1rem;

  a {
    color: $ocean;

    &:hover {
      text-decoration: none;
    }
  }

  .name {
    list-style-type: none;
    position: relative;

    &.required {
      list-style-type: disc;
    }
  }
}
