@import "media.scss";
@import "shared/theme/fonts.scss";

.nav {
  @include desktop {
    border: solid .1rem $sl-grey;
    margin-right: calc(var(--space) * 1.6);

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
      position: sticky;
      top: var(--space);
    }
  }

  @include tablet-landscape-touchable {
    border: solid .2rem $ocean;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }

  @include less-than-desktop {
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }

  border: solid .2rem $ocean;
  flex: 1;
  margin-right: 0;
  max-width: 40rem;
}

.navBarTitleWrapper {
  position: relative;
}

.navBarIcon {
  @include desktop {
    display: none;
  }

  @include tablet-landscape-touchable {
    display: block;
  }

  position: absolute;
  right: .5rem;
  top: .5rem;
}

.navBarTitle {
  display: inline-block;
  font-family: Museo-Sans-300, sans-serif;
  padding: var(--space);
}

.navItemsWrap {
  margin: var(--space-small);
  margin-top: 0;
  padding: 0;
  position: relative;

  &.invisible {
    margin: 0;
    max-height: 0;
    overflow: hidden;
  }
}

.inVisible {
  @include desktop {
    display: block;
  }

  display: none;
}

.section {
  list-style: none;
  margin: var(--space-small);

  .children {
    margin-left: var(--space-large);
  }

  .itemWrap {
    display: flex;
    margin: var(--space-small) 0;

    &:hover {
      background-color: $pebble15;
    }

    &:last-child {
      margin: 0;
    }
  }

  .placeHolder {
    background-color: $bluestone;
    visibility: hidden;
    width: .6rem;
  }

  .navItem {
    flex: 1;

    .navItemLink {
      align-items: center;
      color: $mine-shaft;
      display: flex;
      font-family: $font-body;
      justify-content: space-between;
      padding: var(--space) 0 var(--space) var(--space-large);
      text-decoration: none;
      width: 100%;
    }
  }
}

.active {
  background-color: $pebble05;
  font-weight: 600;

  .placeHolder {
    visibility: visible;
  }
}

.circleNumber {
  background-color: $sl-gold;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;

  .number {
    color: $white;
    display: block;
    font-size: 1rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
  }
}

.tabHeader {
  margin-left: var(--space-xl);
}
