@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.content {
  font-family: $font-body;
  font-size: $large;
  width: 100%;
}

.textContainer {
  border: .1rem solid $brown-grey;
  border-radius: .2rem;
  height: 100%;
  padding: 1rem;
  resize: none;
  width: 100%;
}

.labelContainer {
  font-size: $large;
}

.label {
  display: block;
  font-family: $font-body;
  font-size: 1.1rem;
  padding: .4rem 0;
}

.textContainerError {
  border-color: $brick;
}

.error {
  color: $brick;
  font-size: 1.3rem;
  padding: .8rem 0;
}

.disabled {
  background-color: $pebble05;
  color: $brown-grey;
  cursor: not-allowed;
}
