@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "./media.scss";
@import "shared/components/Navbar/Navbar";
@import "shared/components/Navbar/userMenu/UserMenu";
@import "ProductSearch/ProductSearchCriteria/ProductsSearchCriteria";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

html {
  height: 100%;
  overflow: hidden;
  user-select: none;
}

body {
  color: $mine-shaft;
  height: 100%;
  overflow: auto;
  user-select: text;
}

b,
strong {
  font-weight: bold; /* Fix conflicts from node_modules/bootstrap/scss/_reboot.scss */
}

.App,
body {
  font-family: Museo-Sans-300, sans-serif;
  font-size: 62.5%;
  letter-spacing: .02rem;
  line-height: 1.25;
  margin: 0;
  padding: 0;

  .content {
    padding: var(--space-large);
    padding-top: var(--space-xxl);
  }
}

.htmlForMobile {
  @include phone {
    font-size: 70% !important;
  }
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  transform: translate(0);
}

body * {
  box-sizing: border-box;
}

button {
  &.close-button {
    background-color: transparent;
    border: none;
    padding-left: 7px;
    padding-right: 7px;

    &::after {
      content: "\00d7";
      display: inline;
      font-size: 2.5rem;
    }
  }
}

button:hover {
  cursor: hand;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 8rem;
}

.App-header {
  background-color: $mine-shaft;
  color: white;
  height: 15rem;
  padding: 2rem;
}

.App-title {
  font-size: 2rem;
}

.App-intro {
  font-size: large;
}

.loading-spinner {
  padding: 3.4rem;
  text-align: center;

  .loading-image {
    height: 5rem;
  }
}

.focusedFormField {
  border-color: rgb(22, 115, 177);
  box-shadow: 0 0 0 2px rgba(22, 115, 177, .25);
  outline: 0;
}

.disabledFormFields {
  opacity: .6;
}

.error {
  color: rgb(170, 32, 32);
}

.Toastify__toast-container.toast-container {
  @include desktop {
    width: 600px;
  }
}

.toast-container {
  font-size: 1.4rem;
  line-height: 1.4;

  .Toastify__toast {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: $mine-shaft;
    min-height: 50px;
    padding: var(--space);
    padding-left: 0;
  }

  .Toastify__toast-body {
    direction: ltr;
  }

  .Toastify__toast--error {
    background-color: $misty-rose;
    border-color: $red;
  }

  .Toastify__toast--success,
  .Toastify__toast--info {
    background-color: lighten($green, 45%);
    border-color: $green;
  }

  .Toastify__toast--warning {
    background-color: $light-squash;
    border-color: $sl-gold;
  }

  .toast-message {
    display: flex;
    padding: 0 var(--space-small);
  }

  .toast-message-icon {
    align-items: center;
    display: flex;
    padding: var(--space-small);
  }

  .toast-message-container {
    align-items: center;
    display: flex;
    flex: 1;
    font-family: $font-body;
  }
}

.hide {
  display: none;
}

label.checkbox {
  align-items: center;
  color: $mine-shaft;
  cursor: pointer;
  display: flex;
  font-family: Museo-Sans-300, sans-serif;
  font-size: 1.3rem;
  margin-bottom: .9rem;
  min-height: 1.8rem;

  input {
    flex-shrink: 0;
    margin-right: var(--space);
  }
}

label.radioButtonGroup {
  align-items: center;
  color: $sl-dark-grey;
  display: flex;
  font-family: Museo-Sans-300, sans-serif;
  font-size: 1.3rem;
  margin-bottom: .9rem;
  min-height: 1.8rem;

  input {
    margin-right: .4rem;
  }
}

svg {
  vertical-align: baseline;
}

.popover {
  max-width: unset;
}

//fix img overflow issue in html editor
.custom-html-content img {
  max-width: 100%;
}

@font-face {
  font-display: swap;
  font-family: "Museo-Sans-100";
  src: url("./assets/fonts/MuseoSans_100.otf");
}

@font-face {
  font-display: swap;
  font-family: "Museo-Sans-300";
  src: url("./assets/fonts/museo-sans-300-v1.eot");
  src:
    url("./assets/fonts/museo-sans-300-v1.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/museo-sans-300-v1.woff")
    format("woff"),
    url("./assets/fonts/museo-sans-300-v1.ttf")
    format("truetype"),
    url("./assets/fonts/museo-sans-300-v1.svg#Museo-Sans-300")
    format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Museo-Sans-500";
  src: url("./assets/fonts/museo-sans-500-v1.eot");
  src:
    url("./assets/fonts/museo-sans-500-v1.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/museo-sans-500-v1.woff")
    format("woff"),
    url("./assets/fonts/museo-sans-500-v1.ttf")
    format("truetype"),
    url("./assets/fonts/museo-sans-500-v1.svg#Museo-Sans-500")
    format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Museo-Sans-700";
  src: url("./assets/fonts/museo-sans-700-v1.eot");
  src:
    url("./assets/fonts/museo-sans-700-v1.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/museo-sans-700-v1.woff")
    format("woff"),
    url("./assets/fonts/museo-sans-700-v1.ttf")
    format("truetype"),
    url("./assets/fonts/museo-sans-700-v1.svg#Museo-Sans-700")
    format("svg");
}

.blinkEle {
  animation: blink 1s 3;
}

.blinkErrorEle {
  animation: errorBlink 1s 3;
}

@keyframes blink {
  0% {
    box-shadow: 0 0 5px 0 rgba(0, 128, 166, 0);
  }

  25% {
    box-shadow: 0 0 5px 0 rgba(0, 128, 166, 1);
  }

  50% {
    box-shadow: 0 0 5px 0 rgba(0, 128, 166, 0);
  }

  75% {
    box-shadow: 0 0 5px 0 rgba(0, 128, 166, 1);
  }

  100% {
    box-shadow: 0 0 5px 0 rgba(0, 128, 166, 0);
  }
}

@keyframes errorBlink {
  0% {
    box-shadow: 0 0 5px 0 rgba(223, 88, 92, 0);
  }

  25% {
    box-shadow: 0 0 5px 0 rgba(223, 88, 92, 1);
  }

  50% {
    box-shadow: 0 0 5px 0 rgba(223, 88, 92, 0);
  }

  75% {
    box-shadow: 0 0 5px 0 rgba(223, 88, 92, 1);
  }

  100% {
    box-shadow: 0 0 5px 0 rgba(223, 88, 92, 0);
  }
}

.hint {
  position: relative;

  &:hover::after {
    background-color: $sl-light-grey;
    box-shadow: .5px 1px 2.5px 0 rgba(0, 0, 0, .3);
    content: attr(hint);
    font-family: Museo-Sans-300, sans-serif;
    left: 1rem;
    max-width: 30rem;
    padding: var(--space);
    position: absolute;
    top: 2rem;
    z-index: 10;
  }
}

@media print {
  html {
    overflow: visible;
  }
}
