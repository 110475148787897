@import "shared/theme/colours.scss";

.lenderCommentsWrapper {
  padding: var(--space-medium);
  width: 100%;

  .textAreaWrapper {
    width: 100%;

    textarea {
      border-color: $sl-grey;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
