@import "shared/theme/colours.scss";
@import "media.scss";
@import "shared/theme/fonts.scss";

.wrapper {
  display: flex;

  .fieldWrapper {
    border-radius: 1px;
    padding: 0 .6rem;
    width: 100%;

    &.noPadding {
      padding: 0;
    }

    .title {
      color: $sl-dark-grey;
      font-size: 1.2rem;
      margin-bottom: .6rem;
    }

    input[type="text"] {
      margin-top: 0 !important;
    }

    .content {
      color: $mine-shaft;
      font-size: $regular;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}

.sideBar {
  border-left: .4rem solid;
  min-height: 3.2rem;
}

.sideBarDefault {
  border-color: $botticelli;
}

.sideBarEggShellBlue {
  border-color: $eggShellBlue;
}

.sideBarPink {
  border-color: $misty-rose;
}

.sideBarDarkBlue {
  border-color: $teal-blue;
}

.sideBarLightSquash {
  border-color: $light-squash;
}

.sideBarOcean {
  border-color: $ocean;
}

.noDisplay {
  display: none;
}

.alignRight {
  text-align: right;
}

.hidden {
  @include phone {
    display: none;
  }

  @include tablet-portrait {
    display: none;
  }

  visibility: hidden;
}
