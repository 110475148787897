@import "shared/theme/colours.scss";

.reminder {
  align-items: center;
  border-top: .1rem solid $sl-grey;
  color: $mine-shaft;
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  min-height: 2.4rem;
  padding: 0 .8rem;

  .time {
    text-align: right;
  }

  .item {
    flex: 1;
  }

  .label {
    color: $slDarkGrey;
    width: 13rem;
  }

  .overDue {
    color: $brick;
  }
}
