@import "shared/theme/colours.scss";

.confirmDeleteModal {
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, .2);
  width: 44rem;
}

.header {
  font-weight: 900;
}

.message {
  padding: var(--space-medium) 0;

  & > p {
    font-size: 1.4rem;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.deleteButton {
  min-width: 14rem;
  padding: var(--space-s) var(--space);
}

.cancelButton {
  min-width: 14rem;
  padding: var(--space-s) var(--space);
}
