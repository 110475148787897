@import "media.scss";
@import "shared/theme/spacing.scss";
@import "./ApplicationCard/ApplicationCard.scss";
@import "./ApplicationSwimLanes/ApplicationSwimLanes.scss";

.applicationTracking {
  letter-spacing: 0;
  position: relative;
  text-align: left;

  .headingSection {
    @include phone {
      align-items: inherit;
      flex-direction: column;
    }

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-large;

    .pageHeading {
      @include phone {
        padding-bottom: $space-large;
      }

      padding-bottom: 0;
    }
  }
}

.applicationTrackingTitle {
  font-family: Museo-Sans-700, sans-serif;
  font-size: 2rem;
}
