@import "shared/theme/colours.scss";

.user-menu {
  @include tablet-portrait {
    .full-username {
      display: none;
    }

    .initials {
      display: flex;
    }
  }

  @include desktop {
    .initials {
      display: none;
    }

    .full-username {
      display: inline-block;
    }
  }

  @include phone {
    .initials {
      display: none;
    }
  }

  display: flex;
  height: 100%;
  vertical-align: middle;

  button {
    align-items: center;
    background-color: transparent;
    color: $sl-dark-grey;
    display: inline-flex;
    font-family: Museo-Sans-700, sans-serif;
    height: 100%;
    padding: 0 7px;
  }
}
