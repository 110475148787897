@import "grid.scss";
@import "media.scss";

.applicationDocumentsWrapper {
  @include grid(6);

  .formsSection {
    @include span(2);

    @include phone {
      @include span(6);

      padding: 0 var(--space-medium);
    }

    @include tablet-portrait {
      @include span(6);
    }

    padding: 0 var(--space-medium);
  }

  .somaSelection {
    @include span(4);

    @include phone {
      @include span(6);

      padding: 0 var(--space-medium);
    }

    @include tablet-portrait {
      @include span(6);

      padding: 0 var(--space-medium);
    }

    padding: var(--space-medium);
  }
}
