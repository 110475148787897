@import "media.scss";

.contentNavWrapper {
  @include desktop {
    align-items: flex-start;
    display: flex;
  }

  font-size: 1.3rem;
  margin-top: calc(var(--space) * 3);
}

.appContent {
  @include desktop {
    margin-top: 0;
  }

  flex: 5;
  margin-top: var(--space);
}
