.legendRoot {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  padding: var(--space-small, var(--space));

  & > div {
    padding: var(--space-small) var(--space-l);
  }
}

.markerContainer {
  align-items: center;
  cursor: pointer;
  display: flex;

  &:focus {
    outline: none;
  }

  & > span:nth-child(1) {
    margin-right: var(--space-small);
  }
}

.barLegend {
  height: 12px;
  width: 12px;
}

.splineLegend {
  height: 4px;
  width: 12px;
}
