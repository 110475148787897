@import "grid.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.tableWrapper {
  border: solid .1rem $sl-grey;
  border-radius: .1rem;
  color: $mine-shaft;

  .fixedSmall {
    @include span(1);
  }

  .fixedMedium {
    @include span(2);
  }

  .fixedMediumShort {
    @include span(2);

    min-height: 3rem !important;
  }

  .variableSmallMedium {
    @include span(2);

    @include tablet-portrait {
      @include span(1);
    }
  }

  .stripped {
    @include desktop {
      &:nth-child(odd) {
        background-color: $sl-light-grey;
      }
    }

    @include tablet-portrait {
      &:nth-child(odd) {
        background-color: $sl-light-grey;
      }
    }
  }

  .header {
    @include grid(10);

    @include tablet-portrait {
      @include grid(8);
    }

    font-size: $small;
  }

  .line {
    @include grid(10);

    @include tablet-portrait {
      @include grid(8);
    }

    border-bottom: solid .1rem $sl-grey;
    padding: 0 1rem;
    word-break: break-all;

    input[type="radio"] {
      height: 1.4rem;
      width: 1.4rem;
    }

    &:last-child {
      @include phone {
        border-bottom: solid .1rem $sl-grey;
      }

      border-bottom: none;
    }

    .cell {
      align-items: center;
      display: flex;
      min-height: 5rem;
      word-break: break-word;

      .title {
        display: none;
      }

      a {
        color: $ocean;
        text-decoration: none;
      }
    }
  }
}

@include phone {
  .tableWrapper {
    @include span-full;

    border: none;

    .line {
      display: block;
      padding: .5rem .5rem;

      .cell {
        @include span-full;
        @include grid(2);

        margin: 1rem 0;
        min-height: 4rem;

        .title {
          @include layout-span(1);

          color: $sl-dark-grey;
          display: block;
          font-size: $small;
        }

        .content {
          @include layout-span(1);
        }
      }
    }

    .header {
      display: none;
    }

    &.emptyTable {
      .header {
        display: block;
      }
    }
  }
}
