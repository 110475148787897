@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/sizes.scss";

.applicationUpdate {
  margin-bottom: var(--space-medium);
  width: 100%;
}

.annotationWrapper {
  padding: .8rem 8.7rem .8rem 4rem;

  .annotation {
    color: $mine-shaft;
    font-family: $font-body;
    font-size: 1.3rem;
    font-weight: normal;
    letter-spacing: .02rem;
    line-height: normal;
    white-space: pre-wrap;
  }
}

@mixin smallButton {
  font-size: $regular;
  height: $size-xxlarge;
  min-width: auto;
  padding: 0 var(--space);
}

.okButton {
  @include smallButton;

  background-color: $sl-light-grey;
  border: solid .2rem $bluestone;
  padding: 0 var(--space-large);
  width: $size-xxxxlarge;
}

.referButton {
  @include smallButton;

  padding: 0 var(--space-large);
  width: $size-xxxxlarge;
}

.goToGmailButton {
  @include smallButton;

  margin-right: var(--space-small);
}

.goToContactPageButton {
  @include smallButton;
}
