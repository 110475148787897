@import "media.scss";

@include desktop {
  :root {
    --space: 8px;
  }
}

@include tablet-portrait {
  :root {
    --space: 8px;
  }
}

@include phone {
  :root {
    --space: 6px;
  }
}

:root {
  --space-small: calc(var(--space) * .5);
  --space-s: calc(var(--space) * .5);
  --space-medium: calc(var(--space) * 1.5);
  --space-m: calc(var(--space) * 1.5);
  --space-large: calc(var(--space) * 2);
  --space-l: calc(var(--space) * 2);
  --space-xl: calc(var(--space) * 2.5);
  --space-xxl: calc(var(--space) * 3);
  --space-xxxl: calc(var(--space) * 3.5);
}

$space-small: calc(var(--space) * .5);
$space: var(--space);
$space-medium: calc(var(--space) * 1.5);
$space-large: calc(var(--space) * 2);
