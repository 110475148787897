@import "media.scss";

.downloadButton {
  @include phone {
    svg {
      margin-left: 0;
    }
  }

  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: var(--space-s);
  padding: 0 var(--space-m);

  svg {
    margin-left: var(--space-s);
  }
}
