@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.essentialDates {
  width: 100%;

  .essentialDatesContent {
    @include desktop {
      width: fit-content;
    }

    border: solid .1rem $sl-grey;
    border-bottom: none;
    width: 100%;

    .essentialStatus {
      @include desktop {
        display: flex;
        padding-left: 0;
        width: fit-content;
      }

      border-bottom: solid .1rem $sl-grey;
      font-family: Museo-Sans-300, sans-serif;
      padding-left: var(--space-medium);

      .statusStageCell {
        @include desktop {
          border-right: solid .1rem $sl-grey;
          font-size: 1.4rem;
          width: 25rem;

          &:last-child {
            border-right: none;
          }
        }

        font-size: 1.5rem;
        letter-spacing: .28px;
        width: 100%;

        .statusStageCellTitle {
          display: none;
        }

        .statusName {
          order: 2;

          @include desktop {
            order: 1;
          }
        }

        .reasonContainer {
          order: 1;

          @include desktop {
            order: 2;
          }
        }

        .statusStageCellContent {
          @include desktop {
            align-items: center;
            font-size: 1.4rem;
            font-weight: normal;
            height: 5rem;
            letter-spacing: .17px;
            padding-left: calc(var(--space) * 1.3);
            vertical-align: middle;
          }

          align-items: center;
          display: flex;
          font-size: 1.5rem;
          font-weight: 600;
          height: 4.5rem;
          vertical-align: middle;

          h3 {
            font-weight: 600;
            padding-top: var(--space);
          }
        }

        .suitableOption {
          padding-right: 4rem;
        }
      }

      .statusManualCell {
        @include desktop {
          border-right: solid .1rem $sl-grey;
          font-size: 1.4rem;
          width: 25rem;

          &:last-child {
            border-right: none;
          }
        }

        font-size: 1.3rem;
        letter-spacing: .2px;
        width: 100%;

        .statusManualCellTitle {
          @include desktop {
            display: none;
          }

          font-weight: 600;
          height: 1.5rem;
        }

        .statusManualCellContent {
          @include desktop {
            align-items: center;
            display: flex;
            height: 5rem;
            letter-spacing: .17px;
            padding-left: calc(var(--space) * 1.3);
            padding-right: calc(var(--space) * 1.3);
            vertical-align: middle;
          }

          align-items: center;
          display: flex;
          height: 5rem;
          padding-right: var(--space-medium);
          vertical-align: middle;

          .manualInput {
            @include desktop {
              font-size: 1.4rem;
              font-weight: normal;
              height: 3rem;
            }

            border: solid 1px $sl-grey;
            border-radius: 1px;
            font-family: Museo-Sans-300, sans-serif;
            font-size: 1.3rem;
            font-weight: normal;
            height: 4rem;
            padding-left: var(--space);
            width: 100% !important;
          }
        }

        .settlementButton {
          height: 3rem;
          width: 100%;
        }
      }

      .editReason {
        color: $teal-blue;
        cursor: pointer;
        padding-left: 1rem;
        text-align: center;
      }

      .statusApplyCell {
        @include desktop {
          border-right: solid .1rem $sl-grey;
          font-size: 1.4rem;
          height: 5rem;
          margin: 0;
          width: 25rem;

          &:last-child {
            border-right: none;
          }
        }

        align-items: center;
        display: flex;
        font-size: 1.2rem;
        height: 2rem;
        letter-spacing: .2px;
        margin-bottom: var(--space);
        width: 100%;

        .statusApplyCellTitle {
          @include desktop {
            display: none;
          }

          color: $brown-grey;
          font-weight: 600;
        }

        .statusApplyCellContent {
          @include desktop {
            align-items: center;
            display: flex;
            height: 5rem;
            letter-spacing: .17px;
            padding-left: calc(var(--space) * 1.3);
            vertical-align: middle;
          }

          font-weight: normal;
          padding-left: calc(var(--space) * .8);
        }
      }
    }

    .statusHeader {
      @include desktop {
        border-bottom: solid .1rem $sl-grey;
        display: flex;
        font-family: Museo-Sans-300, sans-serif;
        font-size: 1.4rem;
        width: fit-content;

        .statusHeaderCell {
          align-items: center;
          border-right: solid .1rem $sl-grey;
          display: flex;
          font-weight: 600;
          height: 3.3rem;
          letter-spacing: .22px;
          padding-left: calc(var(--space) * 1.3);
          vertical-align: middle;
          width: 25rem;

          &:last-child {
            border-right: none;
          }
        }
      }

      display: none;
    }
  }

  .notes {
    margin-top: .5rem;
    padding-left: 1rem;
  }
}

.reasonTitle {
  color: $sl-dark-grey;
  font-size: 1.1rem;
  margin-top: -1rem;
}

.reasonDetail {
  display: flex;
  font-size: 1.3rem;
}

.reasonArea {
  margin-top: -1rem;
}

.notesReminderWapper {
  max-width: 44.8rem;
  width: 35rem;

  .notesReminderContents {
    margin-bottom: 1.3rem;
    margin-top: 1rem;
  }

  .notesButton {
    align-items: center;
    display: flex;
    font-size: $regular;
    justify-content: space-around;
  }

  .notesDoneButton {
    align-items: center;
    display: flex;
    font-size: $regular;
    justify-content: space-around;
  }
}

.dueDateTitle {
  font-family: Museo-Sans-500, sans-serif;
  font-size: 2rem;
  padding: var(--space-xxxl) var(--space-medium) var(--space-medium);
}
