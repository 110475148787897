@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "../base/variables";

.textContainer {
  @include base-input;

  line-height: 1.5;
  width: 100%;

  &::placeholder {
    @include placeholder-color;
  }

  &:focus {
    @include focus-border;
  }
}

.textContainerError {
  border-color: $brick;
}

.disabled {
  background-color: $pebble05;
  color: $sl-dark-grey;
  cursor: not-allowed;
}
