@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.dropdownTrigger {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  font-family: $font-body-bold;
  font-size: 1.4rem;
  height: 4rem;
  min-width: 20rem;
  padding-left: var(--space-medium);
}

.dropdownMenu {
  background-color: $white;
  border-radius: 1px;
  box-shadow: 0 .2rem .9rem 0 rgba(0, 0, 0, .2);
  display: block;
  font-family: $font-body;
  position: absolute;
  width: 100%;
}

.dropdownItem {
  background: none;
  border: none;
  padding: var(--space-medium);
  text-align: left;
  width: 100%;

  &:hover {
    background-color: $blueExtraLight;
  }
}
