@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.tabList {
  border-bottom: 1px solid $sl-grey;
  display: flex;
  margin: 0 -2rem 2rem -2rem;
  padding: 0 2rem 0 2rem;
}

.tab {
  align-items: center;
  background: $sl-light-grey;
  border: 1px solid $sl-grey;
  border-radius: 2px 2px 0 0;
  bottom: -1px;
  color: $bluestone;
  cursor: pointer;
  display: flex;
  font-family: $font-body-bold;
  font-size: 13px;
  justify-content: center;
  list-style: none;
  margin-right: 5px;
  min-height: 4rem;
  padding: 1rem 2rem;
  position: relative;
  text-align: center;
  width: 15rem;
}

.tabSelected {
  background: $white;
  border-bottom: none;
  color: inherit;
}
