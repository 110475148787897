@import 'grid.scss';
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

@mixin common {
  margin: 0;
  width: 100%;
}

.container {
  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  border: solid $sl-grey .1rem;
  display: grid;
  margin-bottom: var(--space);
  padding: var(--space);
  width: 100%;
}

.type,
.companyName,
.surname,
.firstName,
.emailAddress,
.mobile,
.remove,
.workPhone {
  @include common;
}

.type {
  @include column(1);
}

.companyName {
  @include column(2/span 2);
}

.firstName {
  @include column(4);
}

.surname {
  @include column(5);
}

.emailAddress {
  @include column(6/span 2);
}

.mobile {
  @include column(8);
}

.workPhone {
  @include column(9);
}

.remove {
  @include column(10);

  color: $roman;
  font-family: $font-open-sans;
  font-size: 1.3rem;
  justify-self: end;
  width: auto;
}

.addButtonWrapper {
  @include grid_btn(2);
}

.addButton {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: var(--space);
  min-width: unset;
  padding: 0 1.6rem;
}

.plainText {
  margin: 0;
}

.modal {
  p {
    margin-top: 1rem;
  }
}

.modalContainer {
  margin: 0;
  padding: 0;
  width: 100%;
}

.fields {
  > div {
    width: 100%;
  }
}

.businessPartnerSelect {
  min-width: 100%;
  width: 45rem;

  @include phone {
    width: 100%;
  }
}
