@import "media.scss";

.firstSection {
  display: flex;

  @include phone {
    flex-direction: column-reverse;
  }

  @include tablet-portrait {
    flex-direction: column-reverse;
  }

  .lenderCommentsSection {
    width: 100%;
  }
}
