@import "media.scss";
@import "grid.scss";

@mixin grid-container {
  @include desktop {
    @include span(6);
  }

  @include tablet-portrait {
    @include span(8);
  }

  @include phone {
    @include span(4);
  }
}

.applicationUpdate {
  @include row(1);
  @include grid-container;
}

.activityLog {
  @include row(3);

  @include desktop {
    @include row(2);
  }

  @include grid-container;
}

.taskSection {
  @include row(2);
  @include grid-container;
}

.activityContent {
  @include desktop {
    @include grid(10, var(--space-m));
  }

  @include tablet-portrait {
    @include grid(8, var(--space-m));

    &:not(:first-of-type) {
      margin-top: var(--space-l);
    }
  }

  @include phone {
    @include grid(4, var(--space-m));

    &:not(:first-of-type) {
      margin-top: var(--space-l);
    }
  }
}
