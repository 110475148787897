.financialYear {
  font-size: 1.3rem;

  :global {
    .react-select__menu {
      z-index: 501;
    }
  }
}

.minHeight {
  align-items: center;
  display: flex;
  min-height: 2.2em;
}

.totalAmounts {
  font-family: Museo-Sans-300, sans-serif;
  margin-top: 1rem;
  position: absolute;
  right: var(--space);
  z-index: 100;
}

.categories {
  word-break: break-word;
}

.amountHeaderCell {
  width: 100%;
}
