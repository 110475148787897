@import "shared/theme/fonts.scss";

.alert {
  font-size: $xlarge;
  margin-bottom: 0;

  p {
    margin: 0;
  }

  .button {
    font-size: 1.8rem;
  }
}
