@import "shared/theme/colours.scss";

.headerSection {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-top: 2rem;
}

:global(.mobiusTable.mobiusTable.mobiusTable).stickyTable {
  height: 95vh;
}

:global(.mobiusTable.mobiusTable.mobiusTable).splittedTable {
  thead {
    & > tr:nth-child(2) > th:nth-child(n + 2) {
      background-color: $mine-shaft;
      color: white;
    }

    & > tr:nth-child(2) > th:nth-child(4n + 4) {
      background-color: $brown-grey;
    }
  }
}

:global(.mobiusTable.mobiusTable.mobiusTable).table {
  border: none;

  thead > tr:first-child > th:first-child {
    z-index: 401;
  }

  table {
    margin-bottom: 0 !important;
  }

  tbody tr:not([class*="mobius-table"]) {
    display: none;
  }

  div[class~="dx-g-bs4-table-container"] {
    height: auto !important;
    overflow: visible;
    width: fit-content;
  }

  tr > th:last-child,
  tr > td:last-child {
    display: none;
  }

  th {
    padding: .75rem;
    vertical-align: middle;
  }

  td,
  th {
    border-top: none;
  }

  :global {
    .border-right {
      border-right: none !important;
    }
  }

  thead {
    & > tr:first-child > th:nth-child(n + 2) {
      background-color: $mine-shaft;
      color: white;
    }

    & > tr:first-child > th:nth-child(4n + 4) {
      background-color: $brown-grey;
    }
  }

  .tableCell {
    font-family: Museo-Sans-300, sans-serif;
    font-size: 1.4rem;
    padding: var(--space-medium);
    text-align: right;
  }

  .headerCell {
    line-height: 1.5;
    padding: var(--space-medium);
    text-align: left;
  }

  .rowHeaderCell {
    padding: var(--space) calc(var(--space) * 4);
  }

  .featureSectionHeader {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .totals {
    display: flex;
    font-size: 1rem;
    line-height: 1.4;

    & > div:nth-child(2) {
      padding-left: var(--space-large);
    }
  }

  .totalRepaymentText {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  .lenderName {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .number {
    font-size: 1.6rem;
    font-weight: bold;
    margin: .2rem 0 0;
  }

  .blankCell {
    background-color: white !important;
    padding: 0;

    &::after {
      display: none;
    }
  }

  .groupName,
  .featureSectionHeader > span {
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
  }

  :global(.mobius-table-even-row) > td {
    background-color: $white;
  }

  :global(.mobius-table-odd-row) > td {
    background-color: $sl-grey;
  }

  :global(.mobius-table-even-row):not(.groupNameRow) > td.evenColumnCell {
    background-color: darken($white, 1%);
  }

  .groupNameRow > td {
    background-color: darken($sl-grey, 10%);
  }

  .includeCommentInPrintControl {
    & > span {
      display: block;
      white-space: normal;
      width: 13rem;
    }
  }
}

:global(.mobiusTable.mobiusTable.mobiusTable).tableWithPreferredRadio {
  thead {
    & > tr:first-child > th:nth-child(n + 2) {
      background-color: white;
      color: unset;
      z-index: 401;
    }

    & > tr:nth-child(2) > th:nth-child(n + 2) {
      background-color: $mine-shaft;
      color: white;
    }

    & > tr:nth-child(2) > th:nth-child(4n + 4) {
      background-color: $brown-grey;
    }
  }
}

:global(.mobiusTable.mobiusTable.mobiusTable).splittedTable.tableWithPreferredRadio {
  thead {
    & > tr:first-child > th:nth-child(n + 2) {
      background-color: white;
      color: unset;
      z-index: 401;
    }

    & > tr:nth-child(3) > th:nth-child(n + 2) {
      background-color: $mine-shaft;
      color: white;
    }

    & > tr:nth-child(3) > th:nth-child(4n + 4) {
      background-color: $brown-grey;
    }
  }
}

.radio {
  align-items: center;
  display: inline-flex;
  font-size: 1.6rem;

  input {
    height: 2rem;
    margin: 0 var(--space-s) 0 0;
    width: 2rem;
  }
}

.tableHintContainer {
  padding-left: 240px;
  position: relative;
}

.calculationExplanation {
  background-color: white;
  display: inline-block;
  width: 240px;
  z-index: 501;
}

.replacedCalculationExplanation {
  height: 4rem;
  left: 0;
  position: absolute;
  top: 2rem;
}

.preferredOptionMessage {
  padding-left: var(--space);
  white-space: nowrap;
}

.comments {
  margin-top: var(--space);
  width: 100%;
}

.redStar {
  &::before {
    content: '*';
  }

  color: $asterisk;
  font-size: 1.2rem;
}

.emptyHint {
  margin-bottom: var(--space-m);
}

.saveButton {
  margin-right: var(--space-m);
}

.downloadButton {
  margin-right: var(--space-m);
}

.restoreButton {
  padding: 0 var(--space-m);
}
