@import "media.scss";
@import "grid.scss";

@mixin resetPositionForPhone {
  @include phone {
    @include span(2);

    grid-column: initial;
    grid-row: initial;
  }
}

.detailsGrid {
  @include grid($number-of-columns: 5, $spacing: var(--space-medium));

  @include phone {
    @include grid($number-of-columns: 2, $spacing: var(--space-medium));

    & > div {
      @include span(1);
    }
  }
}

.originationFee {
  @include tablet-portrait {
    grid-column: 7 / 9;
    grid-row: 2;
  }

  @include phone {
    grid-column: 3 / 5;
    grid-row: 3;
  }

  @include desktop {
    grid-column: 7 / 9;
    grid-row: 2;
  }
}

.settledAmount {
  @include resetPositionForPhone();

  grid-column: 4;
  grid-row: 2;
}

.expectedSmartlineUpfront {
  @include resetPositionForPhone();

  grid-column: 2;
  grid-row: 2;
}

.actualSmartlineUpfront {
  @include resetPositionForPhone();

  grid-column: 3;
  grid-row: 2;
}

.expectedBusinessUpfront {
  @include resetPositionForPhone();

  grid-column: 2;
  grid-row: 1;
}

.upfrontPercentage {
  @include resetPositionForPhone();

  grid-column: 1;
  grid-row: 2;
}

.upfrontPercentageOverride {
  @include resetPositionForPhone();

  grid-column: 1;
  grid-row: 3;
}

.commissionLoanAmount {
  @include resetPositionForPhone();

  grid-column: 4/6;
  grid-row: 1;
}

.expectedSmartlineupfrontoverride {
  @include resetPositionForPhone();

  grid-column: 2;
  grid-row: 3;
}

.marketingLevyAmount {
  @include resetPositionForPhone();

  grid-column: 3;
  grid-row: 3;
}

.select {
  div {
    margin-top: 0;
  }
}
