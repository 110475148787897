@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "../base/variables";

.textArea {
  @include base-input;
  @include input-typography;

  width: 100%;

  &:focus {
    @include focus-border;
  }

  &::placeholder {
    @include placeholder-color;
  }
}

.disabled {
  background-color: $pebble05;
  color: $sl-dark-grey;
  cursor: not-allowed;
}

.error {
  border-color: $brick;
}
