@import 'grid.scss';
@import 'shared/theme/colours.scss';
@import 'shared/theme/fonts.scss';

.container {
  border: 1px solid $sl-grey;
  margin-bottom: var(--space-m);
  padding: 1rem;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landPurchasePriceField {
  @include place(2, 8);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    text-align: right;
  }
}

.statusField {
  @include place(3, 1);
}

.isPreApproval {
  @include place(3, 2);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.state {
  @include place(3, 3);
}

.postcode {
  @include place(3, 4);
}

.contractPriceField {
  @include place(2, 9);

  input {
    text-align: right;
  }
}

.residents {
  grid-column: 8 / span 2;

  .readOnlyOptions {
    width: 100%;

    & > div {
      display: inline-block;
      margin-right: var(--space);
    }
  }
}

.checkboxDropdown {
  margin: 0;
  width: 100%;
}

.textClassName {
  justify-content: flex-end;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}
