@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.wrapper {
  @include layout-details;

  grid-row-gap: 0;
}

.saveButton {
  grid-column: unset;
  grid-column-end: span 2;
  min-width: unset;
  padding: 0;
}

.textAreaWrapper {
  width: 100%;

  @include span(8);

  textarea {
    border-color: $sl-grey;
  }
}
