@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.content {
  font-family: $font-body;
  margin-bottom: var(--space-medium);
  margin-right: var(--space-medium);
  width: 24rem;
}

.redStar {
  &::before {
    content: '*';
  }

  color: $asterisk;
  font-size: 1.2rem;
}

.label {
  color: $sl-dark-grey;
  display: block;
  font-family: $font-body;
  font-size: 1.2rem;
  margin: 0;
  padding: .4rem 0;
}

.error {
  color: $brick;
  font-size: 1.1rem;
  padding: .4rem 0;
}

.fullWidth {
  width: 100%;
}

.inline {
  display: inline-block;
  vertical-align: top;
}
