@import "shared/theme/spacing.scss";
@import "grid.scss";
@import "media.scss";

.updateFixedTrailButton {
  margin-right: var(--space-s);
}

.modal {
  min-width: 60%;

  .modalBody {
    @include phone {
      flex-direction: column;
    }

    margin-top: $space-large;
    width: 100%;

    :global {
      .input-label-wrapper {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    .bottomSection {
      @include grid(3, $space-large);

      @include phone {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
