/* stylelint-disable */
$sl-gold: #e7a615;
$light-squash: #fae89f;
$sl-blue: #002b5c;
$teal-blue: #0080a6;
$sinbad: #99ccdb;
$botticelli: #cde6ed;
$mine-shaft: #323232;
$sl-dark-grey: #666;
$brown-grey: #9b9b9b;
$snowgum: #e6e3e8;
$sl-grey: #ececec;
$sl-light-grey: #f4f4f4;
$white: #fff;
$brick: #aa2020;
$red: #df585c;
$misty-rose: #ffe0e0;
$green: #5ba053;
$shadow-grey: #888b8f;
$slDarkGrey: #666;
$reaRed: #e4002b;
$roman: #df5858;
$bluestone: #333f48;
$shadowstone: #21282e;
$pebble15: #e9ebed;
$pebble05: #f7f8f9;
$pebble60: #a5adb5;
$ocean: #00639E;
$ocean30: #bfd3f1;
$blueLight: #d5e2f6;
$blueExtraLight: #f0f5fc;
$asterisk: #ff6000;
$eggShellBlue: #00beb3;
$light-grey: #d3d3d3;
$darker-grey:  #697684;
/* stylelint-enable */
