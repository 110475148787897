@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/sizes.scss";

table.invoiceTable {
  border: .2rem solid $sl-grey;
  border-radius: .2rem;
  font-family: $font-body;
  font-size: $large;
  width: 100%;

  th {
    font-family: $font-body-bold;
    padding: 2rem;
  }

  tr {
    border: .1rem solid $sl-grey;
  }

  td {
    padding: 1rem 2rem;
  }
}

.pageTitle {
  font-family: $font-header-bold;
  font-size: $xxxlarge;
  margin-bottom: $size-xxlarge;
  margin-top: $size-mediumLarge;
}

.notice {
  padding: 2rem;
}

.download {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0 .5rem;
  padding: 0;
  text-decoration: underline;
}
