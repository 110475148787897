@import "media.scss";

.applicationsStatisticsSection {
  background-color: white;
  position: relative;

  & > h3 {
    background-color: rgb(240, 240, 240);
  }
}

.marker {
  display: inline-block;
  margin-right: var(--space-s);
  width: 12px;
}

.barMarker {
  height: 12px;
}

.splineMarker {
  height: 4px;
}

.previousYearItem {
  font-weight: bold;
}

.previousYearItem.dangerPreviousYearItem {
  color: red;
}

.spinnerContainer {
  align-items: center;
  box-sizing: content-box;
  display: flex;
  height: 400px;
  justify-content: center;
  padding: var(--space-large) 0 0;
}
