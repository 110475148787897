@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "grid.scss";

.title {
  font-family: Museo-Sans-500, serif;
  font-size: $xlarge;
  margin-bottom: var(--space-small);
  padding-left: var(--space-small);
  padding-top: var(--space-medium);
}

.signedForms {
  border: solid .2rem $sl-grey;
  border-radius: .2rem;
  margin-bottom: var(--space-medium);
  padding: var(--space-medium);

  .checkbox {
    flex-direction: row-reverse;
    justify-content: space-between;

    input {
      margin-right: 0;
    }
  }

  .formListHeader {
    display: flex;
    font-family: $font-body-bold;
    justify-content: space-between;
  }

  .externalLink {
    margin-top: .4rem;

    svg {
      margin-left: calc(var(--space) * .4);
      vertical-align: -.2rem;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
