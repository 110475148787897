@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.activityLog {
  .topBox {
    margin-bottom: 0;
  }

  .additionalAddNoteButtonBox {
    padding: var(--space-medium);
    border-top: none;
    border-bottom: none;
    margin-bottom: 0;
  }

  .logGroup:first-of-type {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .emptyHint {
    align-items: center;
    border-top: none;
    display: flex;
    height: 19rem;
    justify-content: center;
  }
}
