@import "shared/theme/fonts.scss";

.contentWrapper {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;

  .title {
    color: $sl-dark-grey;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}
