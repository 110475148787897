@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "media.scss";

$input-width: 19.5rem;
$input-spacing: 1.2rem;

.criteriaSelectField {
  display: inline-block;
  margin: 0 1.2rem 2rem 0;
  padding: 0;
  vertical-align: top;
  width: $input-width;

  label {
    color: $mine-shaft;
    font-family: $font-body-bold, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 0;
    width: inherit;
  }

  div {
    z-index: 501;
  }
}

.criteriaNumberField {
  display: inline-block;
  margin: 0 1.2rem 2rem 0;
  padding: 0;
  vertical-align: top;
  width: $input-width;

  label {
    color: $mine-shaft;
    font-family: $font-body-bold, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 0;
    width: inherit;
  }

  input {
    height: 3rem;
  }
}

.topUpContainer {
  display: inline-block;
}

.productsSearch {
  position: relative;
  text-align: left;

  .title {
    font-family: Museo-Sans-700, sans-serif;
    font-size: 2rem;
  }

  .applicationCriteria {
    background-color: $sl-grey;

    .criteriaWrapper {
      display: flex;

      > div {
        margin-right: $input-spacing;
        width: $input-width;

        > label {
          justify-content: flex-start;
        }
      }
    }

    .criteriaWrapperAddInputMarginTop {
      display: flex;

      > div {
        margin-right: $input-spacing;
        width: $input-width;

        > label {
          justify-content: flex-start;
        }
      }
    }

    .criteriaWrapperAddInputMarginTop > div:not(:first-child) > label > input {
      margin-top: 2.4rem !important;
    }
  }

  .searchButton {
    padding-left: var(--space-l);
  }

  .criteriaContainer {
    background-color: $sl-light-grey;
    border: solid .1rem darken($sl-grey, 10);
    border-radius: .2rem;
    margin-bottom: 2rem;
    min-width: 70rem;

    .criteriaSection {
      border-top: solid .1rem darken($sl-grey, 10);

      &:first-of-type {
        border-top: none;
      }

      .criteriaHeader {
        align-items: center;
        border-bottom: solid .1rem darken($sl-grey, 10);
        display: flex;
        font-family: Museo-Sans-700, sans-serif;
        font-size: 1.4rem;
        height: 5rem;
        justify-content: space-between;
        padding: 0 var(--space-l);

        & h2,
        & h3 {
          padding-bottom: 0;
        }

        .splitLoanTitle {
          align-items: baseline;
          display: flex;

          & > h3 {
            font-family: Museo-Sans-500, sans-serif;
            padding-left: var(--space);
          }
        }

        .criteria-Remove-subtitle {
          vertical-align: middle;
        }

        .criteriaTitle {
          font-size: 1.8rem;
        }
      }

      .criteriaBody {
        @include desktop {
          display: flex;
        }

        font-size: 1.4rem;

        .firstSection {
          @include tablet-portrait {
            border-bottom: solid .1rem darken($sl-grey, 10%);
            width: 100%;
          }

          display: inline-block;
          padding: var(--space-l);
          vertical-align: top;
          width: 66.2rem;
        }

        .featuresSection {
          @include desktop {
            border-left: solid .1rem darken($sl-grey, 10%);
            flex: 1;
          }

          @include tablet-portrait {
            width: 100%;
          }

          display: inline-block;
          max-width: 3 * $input-width + 2 * $input-spacing + 2rem * 2;
          min-width: 0;
          padding: 1.5rem;
          vertical-align: top;
        }
      }
    }
  }

  .buttonContainer {
    border-top: solid .1rem darken($sl-grey, 10%);
    padding: var(--space-l);
  }
}
