@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";

.container {
  border: 1px solid $sl-grey;
  margin-bottom: var(--space-m);
  padding: 1rem;
}

.wrapper {
  @include phone {
    display: flex;
    flex-direction: column;
  }

  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  :global {
    .input-label-wrapper {
      margin-bottom: 0;
      margin-right: 0;
      width: auto;
    }
  }
}

.assetType,
.vehicleType,
.institution {
  @include span(2);
}

.valueField {
  @include place(1,9);

  input {
    text-align: right;
  }
}

.accountName {
  @include place(2,1 / span 2);
}

.make {
  @include place(2,1) ;
}

.bsb {
  @include place(2,3);
}

.model {
  @include place(2,2 / span 2); }

.accountNumber {
  @include place(2,4 / span 2);
}

.year {
  @include place(2,4);
}

.hidden {
  visibility: hidden;
}

.inputWrapper {
  margin-bottom: 0;
}

.description {
  @include place(1,3 / span 2);
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}
