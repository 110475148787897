@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";

.wrapper {
  @include phone {
    display: flex;
    flex-direction: column;
  }

  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  :global {
    .input-label-wrapper {
      margin-bottom: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.primaryPurposeField {
  @include span(2);
}

.valuationTypeField {
  @include place(1,8);
}

.valueField {
  @include place(1,9);

  input {
    text-align: right;
  }
}

.asSecurityField {
  @include place(1,10);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.asConstructionField {
  @include place(3,3/span 2);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.securityLimit {
  @include place(1,10);
}

.rentalFrequencyField {
  @include column(8);

  :global(.react-select__clear-indicator) {
    display: none;
  }
}

.rentalIncomeField {
  @include column(9);

  input {
    text-align: right;
  }
}

.investmentPropertyCostFrequencyField {
  @include column(8);

  :global(.react-select__clear-indicator) {
    display: none;
  }
}

.investmentPropertyCostField {
  @include column(9);
  @include span(2);

  input {
    text-align: right;
  }
}

.propertyTypeField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include place(2,1/span 2);
}

.subTypeField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include place(2,3/span 2);
}

.hidden {
  visibility: hidden;
}

.inputWrapper {
  margin-bottom: 0;
}

.textClassName {
  justify-content: flex-end;
}

.addressField {
  @include span(3);
}

.labelWrapper {
  align-items: center;
  display: flex;
}

.label {
  margin-right: 5px;
}
