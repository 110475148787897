@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
}

.clearableModal {
  & [class*='Modal_body'] {
    display: block;
  }
}

.label {
  color: $sl-dark-grey;
  display: block;
  font-family: $font-body;
  font-size: 1.2rem;
  margin: 0;
  padding: var(--space-small) 0;
}

.value {
  font-family: $font-body;
  padding-right: unset;
}

.disabled {
  color: $mine-shaft;
}

.originalValue {
  color: $slDarkGrey;
  text-decoration: line-through;
}

.overrideValueWrapper {
  align-items: center;
  display: flex;
}

.overrideTextInput {
  input {
    text-align: right;
  }
}

.clearButton {
  font-size: 1.4rem;
  padding: var(--space-l) 0;
  white-space: nowrap;
}
