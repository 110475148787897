@import "grid.scss";
@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.wrapper {
  @include grid(5);

  @include phone {
    @include grid(1);
  }

  align-items: baseline;
  font-size: 1.1rem;

  .type {
    @include span(1);

    @include phone {
      @include span(1);
    }

    color: $sl-dark-grey;
  }

  .description {
    @include span(2);

    @include phone {
      @include span(1);
    }

    font-size: $regular;
    word-break: break-word;
  }

  .fullWidthDescription {
    @include span(4);

    @include phone {
      @include span(2);
    }
  }

  .details {
    @include span(2);

    @include phone {
      text-align: left;

      @include span(1);
    }

    color: $sl-dark-grey;
    text-align: end;
    text-transform: uppercase;
    white-space: pre-line;
    word-break: break-word;
  }

  .text {
    @include span(5);

    @include phone {
      @include span(1);
    }

    font-size: $regular;
    margin-top: var(--space-medium);
    white-space: pre-line;
    width: 100%;
    word-break: break-word;
  }
}
