@import "../../shared/components/productSearch/FeatureModal/FeatureModal";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

$input-width: 19.5rem;
$input-spacing: 1.2rem;

.products-search {
  position: relative;
  text-align: left;

  .title {
    font-family: Museo-Sans-700, sans-serif;
    font-size: 2rem;
  }

  .application-criteria {
    background-color: $sl-grey;
  }

  .criteria-container {
    background-color: $sl-light-grey;
    border: solid .1rem darken($sl-grey, 10);
    border-radius: .2rem;
    margin-bottom: 2rem;
    min-width: 70rem;

    .criteria-section {
      border-top: solid .1rem darken($sl-grey, 10);

      &:first-of-type {
        border-top: none;
      }

      .criteria-header {
        align-items: baseline;
        border-bottom: solid .1rem darken($sl-grey, 10);
        display: flex;
        flex-direction: row;
        font-family: Museo-Sans-700, sans-serif;
        font-size: 1.4rem;
        justify-content: space-between;
        padding: 1.5rem 1.5rem 0 1.5rem;

        .criteria-title {
          font-size: 1.8rem;

          .criteria-subtitle {
            font-family: Museo-Sans-500, sans-serif;
            padding-left: 1rem;
          }
        }

        .criteria-remove-subtitle {
          vertical-align: middle;
        }
      }

      .criteria-body {
        @include desktop {
          display: flex;
        }

        font-size: 1.4rem;

        .select-input * div {
          line-height: 1.8rem;
        }

        .first-section {
          @include tablet-portrait {
            border-bottom: solid .1rem darken($sl-grey, 10%);
            width: 100%;
          }

          display: inline-block;
          padding: 1.5rem;
          vertical-align: top;
          width: 66.2rem;
        }

        .features-section {
          @include desktop {
            border-left: solid .1rem darken($sl-grey, 10%);
            flex: 1;
          }

          @include tablet-portrait {
            width: 100%;
          }

          display: inline-block;
          max-width: 3 * $input-width + 2 * $input-spacing + 2rem * 2;
          min-width: 0;
          padding: 1.5rem;
          vertical-align: top;
        }

        .lenders-select {
          display: inline-block;
          width: 3 * $input-width + 2 * $input-spacing;
        }
      }
    }
  }

  .button-container {
    border-top: solid .1rem darken($sl-grey, 10%);
    padding: 2rem;
  }

  .search-button {
    padding-left: 2rem;
  }

  form {
    .form-input-field {
      display: inline-block;
      margin: 0 $input-spacing 2rem 0;
      padding: 0;
      vertical-align: top;
      width: $input-width;
    }

    label {
      color: $mine-shaft;
      display: flex;
      flex-direction: column;
      font-family: $font-body-bold, sans-serif !important;
      font-size: 1.4rem !important;
      width: inherit;

      > * {
        font-family: $font-body;
      }
    }
  }
}
