@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    color: $sl-dark-grey;
    font-size: $small;
    margin-bottom: .3rem;
  }
}
