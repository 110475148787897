@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.dateHeader {
  margin-bottom: var(--space-s);

  .year {
    color: $brown-grey;
    font-family: $font-body;
    margin-left: .5rem;
  }
}
