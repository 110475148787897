@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.container {
  border: 1px solid $sl-grey;
  margin-bottom: var(--space-m);
  padding: 1rem;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}
