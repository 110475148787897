@import "shared/theme/colours.scss";

.table {
  height: 90vh;

  .cell {
    height: 0;
    padding: var(--space-small) calc(var(--space-small) + 7px);
  }

  .cellWrapper {
    display: grid;
    height: 100%;
  }

  .headerCell {
    font-family: Museo-Sans-500, sans-serif;
    padding: var(--space-small);
  }

  .primativeCell {
    height: 0;
    padding: 0;
    vertical-align: middle;

    > div {
      height: 100%;

      > div {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
      }
    }
  }

  .lenderNameCell {
    vertical-align: middle;
  }

  .lenderNameHeaderCell {
    vertical-align: top;
  }

  .productNameCell {
    padding-right: 0;
  }

  .sortingCell {
    padding-right: calc(28px + var(--space-small) + 7px);
  }

  .cellItem {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-height: 3.5rem;
    padding: var(--space-small) 0 0;
  }

  .totalInfo {
    align-items: center;
    color: $mine-shaft;
    display: flex;
    font-family: Museo-Sans-500, sans-serif;
    justify-content: flex-end;
    padding: var(--space) 0 var(--space-small);
  }

  .productNameContainer {
    flex: 1;
    text-align: left;
  }

  .hint {
    font-family: Museo-Sans-300, sans-serif;
  }

  .headerContainer {
    width: 100%;
  }
}

.titleHead {
  color: $mine-shaft;
  font-family: Museo-Sans-500, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
}

.title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: var(--space-medium);
}

.error {
  color: $reaRed;
}

.serviceabilityInfo {
  align-items: center;
  display: flex;
}

.sortingHeaderContainer {
  & > div:nth-child(2) {
    margin-top: var(--space-small);
    width: calc(100% - 28px);
  }
}

.sortingHeaderCell {
  button {
    margin-right: var(--space-small);
    overflow: hidden;
  }
}
