@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.logWrapper {
  display: flex;

  .left {
    min-height: 7rem;
    position: relative;

    &::before {
      border: solid .05rem $bluestone;
      content: "";
      height: calc(100% - 2rem);
      left: 45%;
      position: absolute;
      top: 4rem;
    }

    .iconWrapper {
      background: $white;
      height: 2rem;
      margin-top: 2rem;
    }
  }

  &:last-child {
    .left {
      min-height: initial;

      &::before {
        display: none;
      }
    }
  }

  .right {
    flex: 1;
    margin-bottom: 1.5rem;
    margin-left: 1rem;

    .title {
      color: $sl-dark-grey;
      font-size: $small;
      margin-bottom: .5rem;
    }

    .lastModify {
      font-family: Museo-Sans-500;
    }
  }

  .header {
    display: flex;
  }
}
