@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "media.scss";
@import "../base/variables";

.reactSelect.large {
  :global {
    @include desktop {
      .react-select__menu {
        width: 80rem;
      }
    }

    @include tablet-portrait {
      .react-select__menu {
        width: 35rem;
      }
    }
  }
}

.reactSelect {
  :global {
    .react-select__control {
      @include input-border;

      min-height: initial;
      transition: none;

      &:focus {
        @include focus-border;
      }
    }

    .react-select__control--is-focused {
      @include focus-border;
    }

    .react-select__control--is-disabled {
      background-color: $pebble05;

      .react-select__indicator {
        color: $sl-dark-grey;
      }
    }

    .react-select__indicator-separator {
      margin-bottom: .9rem;
      margin-top: .9rem;
      width: .15rem;
    }

    .react-select__indicator {
      border-radius: .2rem;
      color: $ocean;
      cursor: pointer;
      margin: -.2rem .2rem;
      padding: 0 .6rem;
      position: relative;
      transition: all .2s ease-in-out;
      width: 2.8rem;

      &:hover {
        background: $sl-light-grey;
        color: darken($ocean, 5%);
      }
    }

    .react-select__value-container {
      @include input-typography;

      padding: .6rem;

      > * {
        margin: 0;
        padding: 0;
      }

      .react-select__placeholder {
        @include placeholder-color;

        white-space: nowrap;
      }
    }

    .react-select__menu {
      border-radius: .2rem;
      z-index: 600;
    }

    .react-select__menu-list {
      padding: 0;
    }

    .react-select__option--is-focused {
      background: $blueExtraLight;
    }

    .react-select__option--is-selected {
      background: $blueLight;
      color: $shadowstone;
    }

    .react-select__single-value--is-disabled {
      color: $sl-dark-grey;
    }
  }
}

.reactSelectError {
  :global {
    .react-select__control {
      border-color: $brick !important;
    }

    .react-select__indicator {
      color: $brick !important;
    }
  }
}
