@import "media.scss";
@import "shared/theme/spacing.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
$navbar-height: 5.2rem;

.summary {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$navbar-height} - var(--space-large) - var(--space-xxl));
}

.header {
  @include phone {
    align-items: start;
    flex-direction: column;
  }

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 var(--space-large);

  h1 {
    padding: 0;
  }
}

.mobiusTable {
  flex: 1 1 auto;
}

.mobiusTableRow {
  height: 35px;
}

.totalAmounts {
  margin-top: 1rem;
  position: absolute;
  right: var(--space);
  z-index: 100;
}

.amountHeaderCell {
  width: 100%;
}

.shouldSelectBusiness {
  border: solid .2rem $sl-grey;
  border-radius: .2rem;
  font-size: $regular;
  margin-top: 3.4rem;
  padding: var(--space-xxl);
  white-space: pre-wrap;
  width: 100%;
}

.selectInput {
  width: 100%;
}
