@import "grid.scss";

.title {
  font-family: Museo-Sans-500, serif;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

.defaultWrapper {
  padding: 1.6rem 1rem;
}

.defaultFieldWrapper {
  @include layout-details;
}
