@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.ownershipField {
  @include place(1, #{6 / 8});
}

.labelInputOverlay {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  overflow-wrap: anywhere;

  label {
    color: $sl-dark-grey;
    flex: 1;
    max-width: 10rem;
    padding: 0 var(--space) 0 0;

    + div {
      color: $sl-dark-grey;
      font-size: 1.1rem;
      min-height: unset;
      width: auto;
    }
  }

  input {
    border: none;
    border-bottom: .1rem solid $brown-grey;
    border-radius: unset;
    color: $sl-dark-grey;
    line-height: 0;
    padding: 0;
    text-align: center;
    width: 3rem;

    &:focus {
      border-color: $brown-grey;
      box-shadow: none;
    }
  }
}
