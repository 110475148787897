.arrow {
  display: flex;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }

  .head,
  .tail {
    border-style: solid;
  }

  .body {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-width: 0;
    padding: 0 .5rem;

    & > div {
      max-width: 100%;
      overflow: hidden;
      padding: .2ex 0;
      text-overflow: ellipsis;
    }
  }
}
