@import "media.scss";
@import "shared/theme/fonts.scss";

.amountNode {
  display: flex;
  justify-content: space-between;
}

.price {
  @include desktop {
    font-size: 2rem;
  }

  @include phone {
    font-size: 1.6rem;
  }
}

.number {
  align-self: center;
  font-size: 1.6rem;
  padding-left: var(--space-medium);

  span {
    font-size: 1rem;
    padding-left: var(--space-small);
  }
}

.summaryReport {
  background-color: rgb(240, 240, 240);
  display: flex;

  @include desktop {
    > div {
      margin-right: var(--space-medium);
    }
  }

  @include tablet-portrait {
    margin-bottom: 1rem;

    > div {
      margin-right: var(--space-medium);
    }
  }

  @include phone {
    margin-bottom: 1rem;
  }
}

.summarySmallSection {
  background-color: $white;
  display: flex;
  padding: 0 var(--space-large);

  @include tablet-portrait {
    flex-grow: 1;
  }

  @include phone {
    flex-grow: 1;
  }
}
