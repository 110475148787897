@import "shared/theme/colours.scss";

.featuresSelect {
  font-family: Museo-Sans-500, sans-serif;
  width: 100%;

  button {
    border: none;
    color: $ocean;
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
  }
}

.allFeaturesOverlay {
  background-color: rgba(0, 0, 0, .45);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600;
}

.allFeaturesModal {
  background: white;
  border: .1rem solid rgb(204, 204, 204);
  border-radius: .4rem;
  bottom: 4rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: Museo-Sans-500, sans-serif;
  font-size: 1.8rem;
  left: 4rem;
  margin: 3rem;
  outline: none;
  overflow: auto;
  padding: 3rem !important;
  position: absolute;
  right: 4rem;
  top: 4rem;

  .closeButton {
    background-color: transparent;
    border: none;
    float: right;
    margin: 1.5rem;
    padding-left: 7px;
    padding-right: 0;
    position: absolute;
    right: 1rem;
    top: 0;

    &::after {
      content: "\00d7";
      display: inline;
      font-size: 2.5rem;
    }

    &:disabled {
      color: $shadow-grey;
      cursor: not-allowed;
    }
  }

  .features-select .label {
    font-size: 1.8rem;
  }

  .featureModalBody {
    display: flex;
    flex: 5;
    overflow: auto;
  }

  .featureModalHeader {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 3rem;
  }

  .featureModalFooter {
    border-top: solid .1rem $sl-grey;
    display: flex;
    padding-top: 1.5rem;
  }

  .groupSection {
    display: inline-block;
    margin-bottom: 1rem;
    padding: 0 .5rem;
    vertical-align: top;
    width: 23.8rem;
  }

  .sectionTitle {
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
  }

  .checkboxItems {
    font-family: Museo-Sans-300, sans-serif;
    font-weight: normal;
  }
}
