@import "grid.scss";

.addButtonWrapper {
  @include grid_btn(2);
}

.addButton {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: var(--space);
  padding: 0 1.6rem;
}
