@import "shared/theme/spacing.scss";
@import "media.scss";
@import "grid.scss";

.container {
  @include grid($number-of-columns: 2, $spacing: $space-large, $collapse-on-phone: true);
}

.switch {
  margin-bottom: 0;
  margin-right: var(--space-medium);
}

.row {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: var(--space) 0;
  white-space: nowrap;

  .wrapper {
    align-items: center;
    display: flex;
  }

  @include phone {
    flex-wrap: wrap;
    white-space: normal;
    width: 100%;
  }

  span {
    white-space: pre-wrap;
  }
}

.outcome {
  margin-bottom: 0;
  margin-right: 0;
  white-space: normal;

  @include phone {
    width: 100% !important;
  }
}

.saveButton {
  border-radius: .2rem;
  height: 3rem;
  min-width: 5.2rem;

  @include phone {
    margin-top: var(--space-xxl);
    width: 100% !important;
  }
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space) 0;

  .linksTitle {
    padding-bottom: $space-medium;
  }

  @include span(1);
}

.formWrapper {
  @include span(1);
}

.links {
  @include grid($number-of-columns: 5, $spacing: $space-large);

  align-items: center;

  span {
    @include span(1);
  }

  a {
    @include span(4);
  }
}
