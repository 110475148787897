$size-none: 0;
$size-xxsmall: .2rem;
$size-xsmall: .5rem;
$size-small: .7rem;
$size-medium: 1rem;
$size-mediumLarge: 1.5rem;
$size-large: 2rem;
$size-xlarge: 2.5rem;
$size-xxlarge: 3rem;
$size-xxxlarge: 5rem;
$size-xxxxlarge: 7rem;

:export {
  large: $size-large;
  medium: $size-medium;
  mediumlarge: $size-mediumLarge;
  none: $size-none;
  small: $size-small;
  xlarge: $size-xlarge;
  xsmall: $size-xsmall;
  xxlarge: $size-xxlarge;
  xxsmall: $size-xxsmall;
  xxxlarge: $size-xxxlarge;
  xxxxlarge: $size-xxxxlarge;
}
