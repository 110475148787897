@import "grid.scss";
@import "shared/theme/fonts.scss";

.container {
  @include span(12);

  align-items: end;
  width: 100%;

  @include grid;

  & > * {
    @include phone {
      grid-column: span 12 !important;
    }
  }
}

.typeField {
  grid-column: 1 / span 3;
  margin: 0;
  order: 0;
  width: auto;

  @include phone {
    order: -1;
  }
}

.typeOtherDetailField {
  align-self: start;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 3;
  margin: 0;
  order: 7;
  width: 100%;

  @include phone {
    order: 0;
  }
}

.periodOfImpactField {
  @include span(1);

  margin: 0;
  order: 1;
  width: auto;

  @include tablet-portrait {
    @include span(2);
  }
}

.startDateField {
  @include span(2);

  margin: 0;
  order: 2;
  width: auto;

  :global(.dx-datebox) {
    height: 3.3rem;
    width: unset !important;
  }

  :global(.dx-placeholder) {
    font-family: museo-sans-300, sans-serif;
    font-size: 1.3rem;
  }
}

.amountField {
  @include span(2);

  margin: 0;
  order: 3;
  width: auto;

  > input {
    text-align: right;
  }
}

.repaymentPlanField {
  @include span(2);

  margin: 0;
  order: 4;
  width: 100%;

  @include tablet-portrait {
    div > * > button {
      align-items: center;
      min-width: 7rem;
      padding: 0;
    }
  }
}

.repaymentPlanInput {
  height: 3.3rem;
  margin: 0;
  width: 100%;
}

.repaymentPlanOtherDetailField {
  align-self: start;
  display: flex;
  flex-direction: column;
  grid-column: 9 / span 2;
  margin: 0;
  order: 8;
  width: 100%;

  @include tablet-portrait {
    grid-column: 10 / span 2;
  }
}

.deleteBtn {
  display: flex;
  grid-column: 11 / span 1;
  height: 3.3rem;
  order: 5;
  width: fit-content;

  @include tablet-portrait {
    grid-column: 12 / span 1;
    order: 9;
  }
}

.addBtn {
  align-items: center;
  display: flex;
  font-size: $regular;
  grid-column: 12 / span 1;
  height: 3.3rem;
  justify-content: space-between;
  min-width: unset;
  order: 6;
  padding: 0 var(--space-l);
  white-space: nowrap;
  width: fit-content;

  @include tablet-portrait {
    grid-column: 1 / span 2;
    order: 10;
  }
}

.readOnlyOptions {
  width: 100%;

  & > div {
    margin-bottom: var(--space-s);
  }
}

.readOnlyContainer {
  align-items: unset;
}
