@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "grid.scss";

.title {
  font-family: $font-body-bold;
  font-size: $xlarge;
  padding-bottom: var(--space-small);
  padding-left: var(--space-small);
}

.subTitle {
  font-family: $font-body-super-bold;
}

.textAreaWrapper {
  width: 100%;

  textarea {
    border-color: $sl-grey;
  }
}

.section {
  border: solid .2rem $sl-grey;
  border-radius: .2rem;
  margin-bottom: var(--space-medium);
  padding: var(--space-medium);
}

.generateSomaButtonContainer {
  @include grid(4);

  @include phone {
    @include grid(1);
  }

  button {
    padding: 0;
  }
}

.somasContent {
  margin-top: var(--space-medium);
}

.somasHeader {
  @include grid(5);

  font-family: $font-body-bold;
  margin-bottom: var(--space-medium);
}

.somasRow {
  @include grid(5);
}

.issued {
  justify-self: end;
}

.signed {
  justify-self: center;
}

.download {
  justify-self: end;
}

.downloadButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0 .5rem;
  padding: 0;
  text-decoration: underline;
}
