@import "shared/theme/colours.scss";
@import "grid.scss";
@import "media.scss";

.applicant {
  @include row(span 2);
}

.settlementDate {
  @include desktop {
    @include place(2, 2);
  }
}

.reconciliationDate {
  @include desktop {
    @include place(2, 3);
  }
}

.paidOutDate {
  @include desktop {
    @include place(2, 4);
  }
}

.lender {
  @include row(span 2);

  .lenderInfo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .portalLink {
      font-weight: 600;
      margin-top: .4rem;

      &:hover {
        text-decoration: none;
      }

      svg {
        margin-left: calc(var(--space) * .4);
        vertical-align: -.2rem;
      }
    }
  }
}
