@import "media.scss";
@import "grid.scss";
@import "shared/theme/colours.scss";

.contentNavWrapper {
  @include desktop {
    @include grid(12, var(--space-m));
  }

  @include tablet-portrait {
    @include grid(8, var(--space-m));
  }

  @include phone {
    @include grid(4, var(--space-m));
  }

  margin-bottom: calc(0px - var(--space-large));
  margin-top: calc(var(--space) * 3);
}

.leftNav {
  @include desktop {
    @include span(2);

    background-color: white;
    border-bottom: 0;
    border-left: 0;
    border-right: solid .1rem $sl-grey;
    border-top: 0;
    height: 100%;
    min-height: 66vh;
    z-index: 600;
  }

  @include tablet-landscape-touchable {
    @include span(12);

    border: solid .2rem $ocean;
    height: fit-content;
    min-height: 0;
    z-index: 500;
  }

  @include tablet-portrait {
    @include span(8);
  }

  @include phone {
    @include span(4);
  }

  @include row(1);

  height: fit-content;
  margin-right: 0;
}

.appContent {
  margin-bottom: var(--space-large);

  @include desktop {
    @include span(10);
  }

  @include tablet-landscape-touchable {
    @include span(12);
    @include row(2);
  }

  @include tablet-portrait {
    @include span(8);
    @include row(2);

    margin-bottom: 20rem;
  }

  @include phone {
    @include span(4);
    @include row(2);
  }
}
