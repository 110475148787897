@import "media.scss";

.noDataSection {
  padding: var(--space-large) 0 0;
  position: relative;
}

.noDataMessageContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.noDataMessage {
  @include phone {
    font-size: 1.4rem;
  }

  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  display: inline-block;
  font-size: 1.8rem;
  padding: var(--space-large);
}
