@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "media.scss";

.modal {
  @include phone {
    min-height: 32rem;
    width: calc(100% - var(--space-xl));
  }

  color: $mine-shaft;
  min-height: 36rem;
  width: 50rem;
}

.overlay {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.hiddenOverlay {
  opacity: 0;
}

.loadingContainer {
  text-align: center;
  width: 100%;
}

.retryMessage {
  margin: var(--space-xxl) 0;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-weight: bold;
  padding: var(--space-s) 0;
}

.categories {
  flex: 1;
  padding: var(--space) 0;
}

.noDateMessage {
  border: solid .2rem $sl-grey;
  border-radius: .2rem;
  font-size: $regular;
  margin: var(--space-xxl) 0;
  padding: var(--space-l);
  white-space: pre-wrap;
  width: 100%;
}

.input {
  @include phone {
    width: 100%;
  }

  margin: var(--space) 0;
  width: 35rem;
}

.errorMessage {
  color: $brick;
  padding-bottom: var(--space);
}

.addBtnContainer {
  align-items: center;
  display: flex;
}

.addBtn {
  align-items: center;
  display: inline-flex;
  height: 3.2rem;
  justify-content: space-between;
  min-width: unset;
  padding: 0 var(--space);

  & > div {
    margin-top: 1px;
  }
}

.hint {
  padding: 0 var(--space);
}

.errHint {
  color: $brick;
}
