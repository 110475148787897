@import "grid.scss";

.links {
  display: flex;
  flex-direction: column;
}

.linkGrid {
  @include desktop {
    @include span(2);
  }

  @include tablet-portrait {
    @include span(2);
  }

  @include phone {
    @include span(10);
  }
}

.splitTable {
  @include span(10);
}
