@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.taskList {
  margin-bottom: 3.2rem;

  ul {
    font-family: $font-body;
    font-weight: normal;
    padding-left: 0;
  }
}

.taskListTitle {
  font-family: $font-header-bold;
  font-size: $large;
  font-weight: 600;
  word-break: break-word;
}

.taskCreateForm {
  align-items: center;
  display: flex;
  height: 6rem;

  .formItem {
    flex: 1;
    margin-right: .5rem;

    input {
      border: .1rem solid $brown-grey;
      border-radius: .2rem;
      height: 3rem;
      padding-left: 1rem;
      width: 100%;

      &:focus {
        outline: .2rem solid $ocean;
      }
    }
  }

  button {
    height: 3rem;
    min-width: 3rem;
    padding: 0 1.5rem;
  }
}
