@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "grid.scss";

.wrapper {
  margin: 0 0 var(--space-m);
}

.sectionWrapper {
  border: 1px solid $sl-grey;
  margin-top: 0;
  padding: 1rem;
}

.contentWrapper {
  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  padding: 1rem;

  .companyIncomeWrapper {
    @include grid($number-of-columns: 10, $spacing: var(--space-medium));

    grid-column: 1/ 11;

    .incomeType {
      grid-column: 2/4;
    }

    .addbackType {
      grid-column: 4/6;
    }

    .details {
      grid-column: 6/8;
    }
  }

  @include tablet-portrait {
    @include grid($number-of-columns: 8, $spacing: var(--space-medium));

    .companyIncomeWrapper {
      @include grid($number-of-columns: 8, $spacing: var(--space-medium));

      grid-column: 1/ 9;

      .incomeType {
        grid-column: 1/3;
      }

      .addbackType {
        grid-column: 1/3;
        grid-row: 2/3;
      }

      .details {
        grid-column: 3/5;
        grid-row: 2/3;
      }

      .removeButton {
        grid-row-start: 2;
      }
    }
  }

  @include phone {
    @include grid($number-of-columns: 4, $spacing: var(--space-medium));

    .companyIncomeWrapper {
      @include grid($number-of-columns: 4, $spacing: var(--space-medium));

      grid-column: 1/ 5;

      .incomeType {
        grid-column: 1/3;
      }

      .addbackType {
        grid-column: 1/3;
        grid-row: 3/4;
      }

      .details {
        grid-column: 3/5;
        grid-row: 2/3;
      }
    }
  }
}

.actionWrap {
  align-items: center;
  background: $pebble15;
  display: grid !important;
  font-family: $font-body-bold;
  font-size: $xlarge;
  margin: 0 !important;
  min-height: 4.8rem;
  padding: 0 1rem;

  @include layout_details;

  :global {
    .show-more-icon {
      grid-column: span 1;
      grid-column-end: -1;
      grid-row: 1;
      justify-self: end;
    }

    .show-more-name {
      grid-column-end: -2;
      grid-column-start: 1;
      grid-row: 1;
    }
  }
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  grid-column: unset;
  grid-column-end: span 2;
  margin: 0;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;

  > label,
  .fieldTitle {
    margin-bottom: .6rem;
    padding: 0;
  }
}

.btnWrapper {
  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  .button {
    align-items: center;
    display: flex;
    grid-column: 1/3;
    justify-content: space-between;
    margin-top: var(--space-m);
    padding: 0 1rem;
    width: 100%;
  }

  @include phone {
    @include grid($number-of-columns: 4, $spacing: var(--space-medium));
  }
}

.remove {
  color: $roman;
  font-family: $font-open-sans;
  font-size: 1.3rem;
  grid-column: 1/11;
  justify-self: end;

  @include phone {
    grid-column: 1/5;
  }
}

.title {
  font-family: $font-header;
  font-size: $xlarge;
  grid-column-end: -1;
  grid-column-start: 1;
}
