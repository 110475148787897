@import "grid.scss";
@import "media.scss";
@import "shared/theme/spacing.scss";

.statusInput {
  margin: 0;
}

.container {
  background: white;
  border-radius: .2rem;
  box-shadow: 0 .8rem 1.6rem 0 rgba(0, 0, 0, .2);
  margin-top: .5rem;
  padding: 10px;
  position: absolute;
  width: 14rem;
  z-index: 20;

  .contentBody {
    width: 100%;
  }

  @mixin stack-up {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;
  }

  .buttonContainer {
    @include tablet-portrait {
      @include stack-up;
    }

    margin-top: $space-large;

    .button {
      height: 3rem;
      min-width: 10rem;
    }
  }
}
