@import "shared/theme/colours.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";
@import "media.scss";

.summary {
  @include tablet-portrait {
    @include grid($number-of-columns: 4);
  }

  @include phone {
    @include grid($number-of-columns: 2);
  }

  @include grid($number-of-columns: 6);

  border: solid .1rem $sl-grey;
  border-radius: .1rem;
  flex-wrap: wrap;
  grid-column-gap: 1.4rem;
  grid-row-gap: 1.8rem;
  padding: 1.5rem  .8rem;

  img {
    height: 2.2rem;
  }
}
