@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.popupWrapper {
  @include desktop {
    max-width: 58.2rem;
    width: auto;
  }

  @include tablet-portrait {
    max-width: 58.2rem;
    width: auto;
  }

  @include phone {
    width: 29.2rem;
  }

  font-family: Museo-Sans-300, sans-serif;

  .modal {
    width: 100%;
  }

  .applicationName {
    line-height: normal;
    margin: .8rem 0;
  }

  .dateTimeSection {
    display: flex;
    flex-direction: column;
  }

  .sectionTitle {
    color: $sl-dark-grey;
    font-size: 1.1rem;
    margin-bottom: .5rem;
  }

  .dateBoxWrapper {
    width: 27rem;

    input {
      min-height: 3rem;
    }
  }

  .errorDateBox {
    border-radius: 4px;
    box-shadow: 0 .5px 2px 0 $brick;
  }

  .errorMessage {
    color: $brick;
    font-size: 1.1rem;
    line-height: normal;
    margin-top: var(--space-s);
    max-width: fit-content;
    min-height: 2rem;
  }

  .loanSection {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .loanCard {
    @include phone {
      margin-right: 0;
    }

    background-color: $white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space);
    margin-right: var(--space);
    padding: var(--space) var(--space-medium);
    width: 27rem;

    &:nth-child(2n),
    &:last-child {
      margin-right: 0;
    }

    > span {
      display: block;
      flex-grow: 1;
      word-break: break-all;
    }
  }

  .accountNumberInput {
    height: 10rem;
    margin-bottom: 2rem;
    padding-top: var(--space-xl);

    > span {
      color: $sl-dark-grey;
      font-size: 1.1rem;
    }
  }

  .checkbox {
    color: $mine-shaft;
  }
}
