@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.errorPopUpWrapper {
  max-width: 44.8rem;
  width: 44.8rem;

  .errorHead {
    color: $mine-shaft;
    font-family: $font-header-bold;
    font-size: $xlarge;
    font-weight: normal;
    letter-spacing: .11px;
    line-height: normal;
  }

  .note {
    margin-bottom: var(--space-m);
    margin-top: var(--space-m);
  }

  .notesButton {
    align-items: center;
    display: flex;
    font-size: $regular;
    justify-content: space-around;
    padding-left: 1rem;
  }

  .errorSection {
    color: $mine-shaft;
    font-size: 13px;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: var(--space-m);
    margin-top: var(--space-l);

    .errorTitle {
      font-family: $font-body-bold;
      font-weight: bold;
    }

    .errorList {
      font-weight: normal;
      margin-bottom: 0;
      margin-top: var(--space);
      padding-left: 3rem;

      .errorItem {
        margin-top: 4px;
      }
    }
  }
}
