@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.collapsibleMessage {
  font-family: $font-body;
  font-size: $regular;
}

.closedBody {
  display: none;
}
