@import "shared/theme/colours.scss";

.accessCheck {
  margin-bottom: 0;

  & > input {
    flex-shrink: 0;
    margin-right: .8rem;
    margin-top: .2rem;
  }

  & > span {
    font-family: Museo-Sans-300, sans-serif;
    font-size: 1.4rem;
  }
}

.disabeldAccessCheck {
  & > span {
    color: $brown-grey;
  }
}
