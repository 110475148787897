@import "media.scss";
@import "shared/theme/colours.scss";

.timelineArrowArray {
  @include desktop {
    margin-top: var(--space-small);
    transform: unset;
    width: 100%;
  }

  color: $mine-shaft;
  font-family: Museo-Sans-300, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: var(--space);
  transform: scale(.5);
  transform-origin: 0 0;
  width: 200%;

  & [class~="arrow-array-item"] {
    min-width: auto;
  }

  & [class~="arrow-body"] {
    justify-content: flex-start;
    min-width: auto;
    padding: 0 var(--space);

    & > div {
      white-space: nowrap;
    }

    & * {
      @include desktop {
        display: unset;
      }

      display: none;
    }
  }
}

.timelineWrapper {
  @include desktop {
    border: none;
    margin-top: var(--space-small);
    padding: 0;
  }

  border: .1rem solid $sl-grey;
  margin-top: var(--space-medium);
  padding: 0 var(--space);
}

.statusName {
  @include desktop {
    display: none;
  }

  color: $sl-dark-grey;
  font-family: Museo-Sans-300, sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-top: calc(var(--space) * -.4);
  padding: 0 0 var(--space);
  text-align: center;
}
