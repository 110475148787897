@import "media.scss";
@import "shared/theme/colours.scss";

@mixin grid($number-of-columns: 12, $spacing: 1rem, $collapse-on-phone: false) {
  display: grid;
  grid-column-gap: $spacing;
  grid-row-gap: $spacing;
  grid-template-columns: repeat($number-of-columns, [col-start] 1fr);

  @if $collapse-on-phone {
    @include phone {
      > * {
        grid-column: col-start / -1 !important;
        grid-row: initial !important;
      }
    }
  }
}

@mixin span($width) {
  grid-column: span $width;
}

@mixin place($row, $column) {
  grid-column: $column;
  grid-row: $row;
}

@mixin row($row) {
  grid-row: $row;
}

@mixin column($column) {
  grid-column: $column;
}

@mixin layout-grid() {
  @include tablet-portrait {
    @include grid($number-of-columns: 8);
  }

  @include phone {
    @include grid($number-of-columns: 4);
  }

  @include grid($number-of-columns: 12);
}

@mixin layout-details {
  @include tablet-portrait {
    @include grid($number-of-columns: 8, $spacing: var(--space-medium));
  }

  @include phone {
    @include grid($number-of-columns: 4, $spacing: var(--space-medium));
  }

  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  > * {
    @include span(2);
  }
}

@mixin layout-half-details {
  @include tablet-portrait {
    @include grid($number-of-columns: 8, $spacing: var(--space-medium));
  }

  @include phone {
    @include grid($number-of-columns: 4, $spacing: var(--space-medium));
  }

  @include grid($number-of-columns: 5, $spacing: var(--space-medium));

  > * {
    @include span(2);
  }
}

@mixin layout-span($width) {
  @include phone {
    @if ($width > 4) {
      @include span(4);
    }
  }

  @include tablet-portrait {
    @if ($width > 8) {
      @include span(8);
    }
  }

  @include span($width);
}

@mixin span-full() {
  grid-column: col-start / -1;
}

@mixin grid-cards($width: 150px) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($width, 1fr));
}

@mixin colorful-divs() {
  background-color: $brown-grey;

  * {
    color: $white !important;
  }

  > div,
  section {
    background: blue;
    color: $white;

    > div,
    section {
      background: orange;
      color: $white;

      > div,
      section {
        background: purple;
        color: $white;

        > div,
        section {
          background-color: $sinbad;
          color: $white;
        }
      }
    }
  }
}

@mixin grid_btn($span) {
  @include layout_details;

  > button {
    grid-column: span $span;
  }
}

@mixin grid_btn_always_10($span) {
  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  > button {
    grid-column: span $span;
  }
}
