@import "media.scss";
@import "shared/theme/colours.scss";

.page {
  @include phone {
    padding: var(--space-xxl) var(--space) 0;
  }

  background-color: rgb(240, 240, 240);
  margin-left: calc(0px - var(--space-large));
  margin-top: calc(0px - var(--space-xxl));
  min-height: calc(100vh - 5.2rem - var(--space-xxl));
  padding: 0 calc(2 * var(--space-xxxl));
  padding-top: var(--space-xxl);
  width: calc(100% + calc(2 * var(--space-large)));
}

.businessSelector {
  @include less-than-desktop {
    align-items: flex-end;
    flex-direction: column-reverse;
    height: auto;
  }
}

.buttonContainer {
  @include less-than-desktop {
    margin-top: var(--space-medium);
  }

  display: flex;
}

.monthRangeSelector {
  @include phone {
    margin: var(--space) 0 var(--space-l);
    width: 100%;
  }

  margin: 0;
}

.header {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  justify-content: space-between;
}

.reportSectionContainer {
  padding: var(--space-large) 0;
}

.expectedSettlementReport {
  @include desktop {
    width: 33.33%;
  }
}
