@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "grid.scss";

.wrapper {
  margin: 0 0 var(--space-m);
}

.actionWrap {
  align-items: center;
  background: $pebble15;
  display: grid !important;
  font-family: $font-body-bold;
  font-size: $xlarge;
  margin: 0 !important;
  min-height: 4.8rem;
  padding: 0 1rem;

  @include layout_details;

  :global {
    .show-more-icon {
      grid-column: span 1;
      grid-column-end: -1;
      grid-row: 1;
      justify-self: end;
    }

    .show-more-name {
      grid-column-end: -2;
      grid-column-start: 1;
      grid-row: 1;
    }
  }
}

.buttonGroup {
  @include layout-details;

  margin: var(--space) 0 0;

  .button {
    align-items: center;
    display: flex;
    grid-column: unset;
    grid-column-end: span 2;
    justify-content: space-between;
    min-width: auto;
    padding: 0 1rem;
  }
}
