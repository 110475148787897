@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.dueDate {
  @include desktop {
    display: flex;
    padding-left: 0;
    width: fit-content;
  }

  border-bottom: solid .1rem $sl-grey;
  font-family: Museo-Sans-300, sans-serif;
  padding-left: var(--space-medium);

  .dueDateCell {
    @include desktop {
      border-right: solid .1rem $sl-grey;
      font-size: 1.4rem;
      width: 25rem;

      &:last-child {
        border-right: none;
      }
    }

    font-size: 1.5rem;
    letter-spacing: .28px;
    width: 100%;

    .statusStageCellTitle {
      display: none;
    }

    .dateName {
      order: 2;

      @include desktop {
        order: 1;
      }
    }

    .reasonContainer {
      order: 1;

      @include desktop {
        order: 2;
      }
    }

    .dueDateCellContent {
      @include desktop {
        align-items: center;
        font-size: 1.4rem;
        font-weight: normal;
        height: 5rem;
        letter-spacing: .17px;
        padding-left: calc(var(--space) * 1.3);
        vertical-align: middle;
      }

      align-items: center;
      display: flex;
      font-size: 1.5rem;
      font-weight: 600;
      height: 4.5rem;
      vertical-align: middle;

      h3 {
        font-weight: 600;
        padding-top: var(--space);
      }
    }

    .suitableOption {
      padding-right: 4rem;
    }
  }

  .dueDateManualCell {
    @include desktop {
      border-right: solid .1rem $sl-grey;
      font-size: 1.4rem;
      width: 25rem;

      &:last-child {
        border-right: none;
      }
    }

    font-size: 1.3rem;
    letter-spacing: .2px;
    width: 100%;

    .dueDateManualCellTitle {
      @include desktop {
        display: none;
      }

      font-weight: 600;
      height: 1.5rem;
    }

    .dueDateManualCellContent {
      @include desktop {
        align-items: center;
        display: flex;
        height: 5rem;
        letter-spacing: .17px;
        padding-left: calc(var(--space) * 1.3);
        padding-right: calc(var(--space) * 1.3);
        vertical-align: middle;
      }

      align-items: center;
      display: flex;
      height: 5rem;
      padding-right: var(--space-medium);
      vertical-align: middle;
    }
  }

  .errorMessage {
    color: $brick;
    font-size: 1.1rem;
    max-width: fit-content;
    padding: 0 calc(var(--space) * 1.3);
  }
}
