@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.expenseSectionWrapper {
  border: solid .5px $sl-grey;
}

.expenseContainer {
  margin-bottom: var(--space-medium);
}

.expenseSectionHeader {
  @include grid($number-of-columns: 4, $spacing: var(--space-medium));

  align-items: center;
  background-color: $pebble15;
  font-family: $font-header;
  font-size: 16px;
  font-weight: 500;
  grid-row-gap: 0;
  height: 4.2rem;
  padding: 0 var(--space);
}

.livingExpenseContainer {
  flex: 1;
}

.headerLabel {
  @include span(2);
}

.headerAmount {
  @include place(1, 3);

  text-align: right;
}
