@import "grid.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "media.scss";

.titleWrapper {
  margin: var(--space-l) 0;

  .sectionTitle {
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.6rem;
    margin: var(--space-m) 0;
  }

  &:first-child {
    margin-top: 0;

    .sectionTitle {
      margin-top: 0;
    }
  }
}
