@import 'media.scss';
@import 'shared/theme/fonts.scss';
@import 'grid.scss';
@import "shared/theme/colours.scss";

.container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  padding: 1rem 1rem 2rem 1rem;
}

.noDataMessageContainer {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 2rem 1rem 2rem 1rem;
  position: relative;
  width: 100%;
}

.noDataMessage {
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  display: inline-block;
  font-size: 1.8rem;
  padding: var(--space-large);
}

.title {
  font-family: museo-sans-700, sans-serif;
  font-size: 1.4rem;
}

.selectorWrapper {
  align-self: flex-end;
  z-index: 2;
}

.month {
  color: $sl-dark-grey;
}

.row {
  display: flex;
  flex-direction: row;
}

.name {
  color: $sl-dark-grey;
  width: 15rem;

  @include less-than-desktop {
    width: 8rem;
  }
}

.content {
  @include grid(12, 0);

  width: 100%;
}

.lastThreeChild {
  > div:nth-last-child(-n + 3) {
    color: $brown-grey;
  }
}

.separator {
  border: 1px solid $light-grey;
  width: 100%;
}

.currentPercentage {
  font-size: 27px;
  font-weight: 300;
}

.previousPercentage {
  font-size: 15px;
  font-weight: normal;
}

.textLarge {
  font-size: 27px;
  font-weight: 300;

  @include less-than-desktop {
    font-size: 18px;
  }
}

.textMiddle {
  font-size: 15px;
  font-weight: normal;

  @include less-than-desktop {
    font-size: 10px;
  }
}

.textRegular {
  font-size: 11px;
}

.textSmall {
  font-size: 8px;
}
