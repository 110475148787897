.features-select {
  font-family: Museo-Sans-500, sans-serif;
  width: 100%;

  button {
    border: none;
    color: $ocean;
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
  }
}

.all-features-overlay {
  background-color: rgba(0, 0, 0, .45);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600;
}

.all-features-modal {
  background: white;
  border: .1rem solid rgb(204, 204, 204);
  border-radius: .4rem;
  bottom: 4rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: Museo-Sans-500, sans-serif;
  font-size: 1.8rem;
  left: 4rem;
  margin: 3rem;
  outline: none;
  overflow: auto;
  padding: 3rem !important;
  position: absolute;
  right: 4rem;
  top: 4rem;

  .close-button {
    float: right;
    margin: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 0;
  }

  .features-select .label {
    font-size: 1.8rem;
  }

  .feature-modal-body {
    display: flex;
    flex: 5;
    overflow: auto;
  }

  .feature-modal-header {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 3rem;
  }

  .feature-modal-footer {
    border-top: solid .1rem $sl-grey;
    display: flex;
    padding-top: 1.5rem;
  }

  .group-section {
    display: inline-block;
    margin-bottom: 1rem;
    padding: 0 .5rem;
    vertical-align: top;
    width: 23.8rem;
  }

  .section-title {
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
  }

  .checkbox-items {
    font-family: Museo-Sans-300, sans-serif;
    font-weight: normal;
  }
}
