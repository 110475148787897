@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

:global {
  .table {
    :local {
      .cell {
        font-family: $font-body;
        font-size: $regular;
        padding: 2px 10px;
        vertical-align: middle;

        &.filterCell {
          padding: 5px;
        }
      }

      .headerCell {
        background: $white;
        font-family: $font-body-bold;
        font-size: $regular;
        line-height: 1.5rem;
        padding: 5px;
      }

      .shadowCell {
        border-right: none !important;
        overflow: visible;
        position: relative;

        &::before {
          background: linear-gradient(to right, rgb(204, 204, 204) 0%, rgba(255, 255, 255, 0) 100%);
          bottom: 0;
          content: "";
          display: block;
          position: absolute;
          right: -10px;
          top: 0;
          width: 10px;
        }
      }

      .selectionCell {
        text-align: center;
        vertical-align: middle;
      }

      .primativeCell {
        padding: 0;
        vertical-align: middle;
      }

      .shadowWrapper {
        padding-right: 10px;

        .cell {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
