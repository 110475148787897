@import "grid.scss";
@import "shared/theme/colours.scss";

.referrerWrapper {
  @include grid(10);

  @include tablet-portrait {
    @include grid(8);
  }

  @include phone {
    @include grid(8);

    border: none;
    border-bottom: solid .1rem $sl-grey;
    padding: .5rem;
  }

  align-items: center;
  border: solid .1rem $sl-grey;
  border-radius: .1rem;
  padding: 1.2rem 1rem;
}

.referrerItem {
  @include layout-span(2);

  @include phone {
    @include layout-span(4);
  }
}
