@import "shared/theme/colours.scss";

.container {
  border: .5px solid $brown-grey;
  display: flex;
  height: 3rem;
}

.input {
  height: 0;
  opacity: 0;
  width: 0;
}

.item {
  align-items: center;
  border-right: .5px solid $brown-grey;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;

  &:last-child {
    border-right: none;
  }
}

.input[type="radio"]:checked + label {
  background-color: $ocean;
  border: none;
  color: $white;
}

.input[type="radio"]:focus + label {
  outline: auto 5px -moz-focus-ring-color;
  outline: auto 5px -webkit-focus-ring-color;
  outline: auto 5px -ms-focus-ring-color;
  outline: auto 5px -o-focus-ring-color;
}

.input[type="radio"]:disabled:checked + label {
  background-color: $ocean30;
  color: $mine-shaft;
}
