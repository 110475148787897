@import "shared/theme/colours.scss";
@import "media.scss";
$color_grey: rgb(136, 139, 143);

.taskItem {
  @include desktop {
    box-shadow: 0 -.5px 0 0 $color_grey;
  }

  box-shadow: 0 -1px 0 0 $color_grey;
  display: flex;
  margin: 0;
  min-height: 3.4rem;

  .taskCheck {
    margin-bottom: 0;
    padding: var(--space);
  }

  &:hover {
    background: $blueExtraLight;
  }

  span,
  a {
    color: inherit;
    line-height: normal;
    padding: var(--space-small) 0;
    width: 100%;
    word-break: break-word;

    &:hover {
      color: $mine-shaft;
      cursor: pointer;
      text-decoration: none;

      .taskSummary {
        text-decoration: underline;
      }
    }

    .taskSummary {
      margin: var(--space-small) 0;
    }

    .taskInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .overDueTask {
      color: $brick;
      font-weight: bolder;
    }
  }

  .taskCompleted,
  .taskCompleted:hover {
    color: $color_grey;
    text-decoration: line-through;
  }

  .deleteTaskButton {
    color: $brick;
    font-size: 1.1rem;
  }
}

.alignCenter {
  align-items: center;
}

.taskItemDraggable {
  margin-right: 1rem;
}
