@import "media.scss";
@import "shared/theme/spacing.scss";

.form {
  :global {
    input,
    .select__value-container {
      font-size: 1.3rem !important;
    }
  }
}

.headerContent {
  @include phone {
    display: block;
    padding: var(--space) 0;
  }

  align-items: center;
  display: flex;
  justify-content: space-between;

  h1 {
    @include phone {
      padding-bottom: var(--space-medium);
      padding-top: var(--space-medium);
    }

    padding-top: var(--space-large);
  }
}

.buttonGroups {
  @include phone {
    button {
      width: 50%;
    }
  }

  display: flex;

  > * {
    margin: 0 $space;
  }
}
