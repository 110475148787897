@import "shared/theme/fonts.scss";

.tooltipContent {
  font-family: $font-body;
  font-size: 1.3rem;

  tr > td:nth-child(2) {
    padding-left: var(--space-large);
    text-align: right;
  }
}
