@import "shared/theme/fonts.scss";

.wrapper {
  display: flex;
  justify-content: space-between;

  h5 {
    align-items: center;
    display: flex;
    font-family: $font-body-bold;
    margin-bottom: 0;
  }

  .calculatedValue {
    font-family: $font-body-super-bold;
    font-size: $xlarge;
  }
}
