@import "grid.scss";
@import "media.scss";

.addressModal {
  @include tablet-portrait {
    @include column(#{2 / 12});
  }

  @include tablet-landscape {
    @include column(#{2 / 12});
  }
}

.container {
  @include grid($number-of-columns: 5, $spacing: var(--space-medium));

  > * {
    @include span(2);
  }

  :global {
    .input-label-wrapper {
      margin-bottom: 0;
      margin-right: 0;
      width: auto;
    }
  }

  .content {
    margin: 0;
  }

  .addressType {
    @include span(2);

    margin-top: var(--space-medium);
  }

  .nonStdAddress {
    @include span(5);
    @include row(2);
  }

  .buildingName {
    @include span(2);
    @include row(2);
  }

  .unitNo {
    @include span(1);
    @include row(2);
  }

  .floorNo {
    @include span(1);
    @include row(2);
  }

  .streetNo {
    @include span(1);
    @include row(3);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .streetName {
    @include span(1);
    @include row(3);
  }

  .streetType {
    @include span(1);
    @include row(3);
  }

  .suburb {
    @include column(1);
  }

  .state {
    @include column(2);
  }

  .postcode {
    @include column(3);
  }

  .country {
    @include column(4);
    @include span(2);
  }
}
