@import "media.scss";
@import "shared/theme/colours.scss";

.applicationsFilter {
  background-color: $sl-light-grey;
  border: solid .1rem darken($sl-grey, 10);
  border-radius: .2rem;
  color: $mine-shaft;
}

.filterHeader {
  border-bottom: solid .1rem darken($sl-grey, 10);
  padding: var(--space-medium) var(--space-medium) 0 var(--space-medium);
}

.fieldsContainer {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--space-large);
}

.field {
  @include phone {
    width: 100%;

    & > div {
      width: 100% !important;
    }
  }

  padding: var(--space-medium) var(--space-medium) 0;
  width: 24rem;
}

.filterItem {
  font-size: 1.3rem;
  width: 19.5rem;
}
