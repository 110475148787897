@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "media.scss";
$hover-tint: 5%;

.button {
  @include phone {
    height: 3.5rem;
    min-width: 10rem;
    padding: 0 2rem;
  }

  border: none;
  border-radius: .2rem;
  font-family: $font-body-bold;
  font-size: $large;
  height: 4rem;
  min-width: 20.4rem;
  padding: 0 4rem;

  &.disabled {
    background-color: $pebble05;
    border: none;
    color: $shadowstone;
    cursor: not-allowed;
  }
}

.primaryButton {
  @extend .button;

  background: $bluestone;
  color: $white;

  &:hover:not(.disabled) {
    background: $shadowstone;
  }
}

.secondaryButton {
  @extend .button;

  background: $white;
  border: solid .2rem $bluestone;
  color: $bluestone;

  &:hover:not(.disabled) {
    background: $pebble15;
  }
}

.nakedButton {
  @extend .button;

  background: $white;
  border: none;
  color: $bluestone;

  &.disabled {
    background-color: $white;
    border: none;
    color: rgb(165, 173, 181);
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    background: $pebble15;
    border: none;
  }
}

.linkButton {
  align-items: center;
  background-color: transparent;
  border: none;
  color: rgb(0, 99, 158);
  display: inline-flex;
  font-family: $font-body-bold;
  font-size: $regular;
  margin: 0 -.2rem;
  position: relative;

  &.disabled {
    cursor: text;
  }

  > * {
    padding: 0 .2rem;
  }

  :global(.htmlForMobile) & > * {
    padding: .2rem;
  }
}
