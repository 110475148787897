@import "shared/theme/colours.scss";
@import "media.scss";

.contentRtf {
  display: flex;
  justify-content: space-between;
  padding: 0;
  word-break: break-word;
}

.contentWithoutIcon {
  flex-grow: 1;

  strong {
    font-weight: bold;
  }
}

.editIcon {
  cursor: pointer;
  height: 100%;
  margin-left: 1rem;
}

.applicationNewNoteModal {
  @include phone {
    height: 48vh;
    min-height: 25rem;
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  @include tablet-portrait {
    height: 48vh;
    min-height: 25rem;
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  height: 50vh;
  width: 50vw;

  & > div[class*="Modal_body"]:nth-child(2) {
    overflow: hidden;
  }

  & > div[class*="Modal_footer"] {
    border-top: solid .1rem $sl-grey;
  }

  .newNoteModalTitle {
    font-weight: bold;
    padding-bottom: 0;
  }

  .applicationNoteEditor {
    padding: var(--space) 0;
    width: 100%;
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .saveNoteButton {
    min-width: 13rem;
  }

  .deleteNoteButton {
    border-color: $brick;
    color: $brick;
    min-width: 13rem;
    padding: 0 var(--space-xl);
  }

  .deleteNoteContent {
    align-items: center;
    display: flex;

    & span {
      padding-right: var(--space);
    }
  }
}
