@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.notification {
  border-radius: .4rem;
  font-family: $font-body;
  font-size: $regular;
  margin-bottom: .5rem;
  width: 100%;
}

.notificationIcon {
  align-items: center;
  padding: 0 1rem;
}

.notificationContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  word-break: break-word;

  .notificationSubtitle {
    color: $mine-shaft;
    font-family: Museo-Sans-300, sans-serif;
    font-size: 1.2rem;
    letter-spacing: .018rem;
    padding-top: .8rem;
  }

  .notificationTitle {
    font-size: 1.3rem;
    font-weight: 800;
    letter-spacing: .013rem;
    padding-top: .5rem;

    .collapseButton {
      background: transparent;
      color: $mine-shaft;
      letter-spacing: .013rem;
      outline: none;
    }
  }
}

.notificationAction {
  align-items: center;
  padding: 0 1rem;
}

.okButton {
  background-color: $sl-light-grey;
  border: solid .2rem $teal-blue;
  border-radius: .2rem;
  color: $teal-blue;
  font-family: $font-body;
  font-size: $regular;
  height: 2.7rem;
  min-width: 6.7rem;
  padding: 0;
  text-align: center;
  width: 6.7rem;
}

.info {
  background-color: $sl-light-grey;
  position: relative;

  &::before {
    border-left: solid .3rem $reaRed;
    border-radius: .4rem 0 0 .4rem;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
  }
}

.headerWrapper {
  align-items: center;
  display: flex;
  min-height: 5rem;
}
