@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "media.scss";

.taskWrapper {
  box-shadow: 0 -.1rem 0 0 $shadow-grey;
  display: flex;
  font-size: 1.3rem;
  padding: var(--space-small) 0;

  &:hover {
    background-color: $blueExtraLight;
    cursor: pointer;
  }
}

.checkBoxContainer {
  width: 4rem;

  .taskCheck {
    height: 100%;
    justify-content: center;
  }
}

.contentContainer {
  @include phone {
    display: block;
  }

  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 6rem;

  &.completed {
    * {
      color: $sl-dark-grey !important;
      text-decoration: line-through;
    }
  }

  .contentWrapper {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: space-between;
  }

  .name {
    font-family: $font-body;
    margin-bottom: var(--space-small);
  }

  .applicants {
    .label {
      color: $sl-dark-grey;
    }
  }

  .link {
    @include phone {
      flex-direction: column;
    }

    color: $mine-shaft;
    display: flex;
    flex: 1;

    &:hover {
      color: $mine-shaft;
      text-decoration: none;

      .name {
        text-decoration: underline;
      }
    }
  }
}

.extraInfoContainer {
  @include phone {
    align-items: flex-start;
    min-height: auto;
  }

  align-items: flex-end;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  min-height: 6rem;

  .lenderLogo img {
    height: 4rem;
    mix-blend-mode: multiply;
  }

  .timeHighlight {
    color: $brick;
  }
}

.placeholder {
  font-family: $font-body;
  font-size: 1.3rem;
  margin: var(--space-xxxl) 0;
}
