@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.householdExpenseWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  @include desktop {
    margin-bottom: -14rem;
    padding-bottom: 14rem;
  }
}

.buttonWrapper {
  @include grid(4, var(--space-m));

  grid-row-gap: 0;
  min-width: 50rem;
}

.buttonLabel {
  flex: 1;
}

.addButton {
  align-items: center;
  display: flex;
  grid-column: unset;
  grid-column-end: span 2;
  height: 4.2rem;
  padding: 0 1.5rem 0 0;
}
