@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

:global {
  .mobiusTable.mobiusTable.mobiusTable {
    border: .2rem solid $sl-grey;
    border-radius: .2rem;
    font-size: initial;
    overflow: auto;
    width: 100%;

    & > div {
      height: 100%;
    }

    th {
      background: $white;
      border: none;
      font-family: $font-body-bold;
      font-size: $regular;
      position: relative;
      vertical-align: text-top;

      &::after {
        background: linear-gradient(to bottom, rgb(204, 204, 204) 0%, rgba(255, 255, 255, 0) 100%);
        bottom: -10px;
        content: "";
        display: block;
        height: 10px;
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    .sortingButton {
      align-items: flex-start;
      padding: 0;

      & > span {
        overflow: visible;
      }

      & > span:last-child {
        margin-top: -4px;
      }
    }

    .loading-spinner {
      left: 0;
      position: absolute;
      right: 0;
    }

    :local {
      .tableRow {
        & > label,
        td {
          background: $white;
        }
      }

      .tableRow.oddRow {
        & > label,
        td {
          background: $sl-light-grey;
        }
      }

      .noDataContainer {
        left: 0;
        position: absolute;
        right: 0;
      }

      .noData {
        font-size: $regular;
        margin-left: auto;
        margin-right: auto;
        opacity: 1;
        width: 95%;
      }
    }

    .table {
      color: $mine-shaft;
      margin-bottom: 0;
    }
  }
}
