@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.stickyFooter {
  .phantomStyle {
    display: block;
    height: 5.2rem;
  }

  .stickyContainer {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 599;

    @include desktop {
      width: 85%;
    }

    @include tablet-landscape-touchable {
      width: 100%;
    }

    .shadow {
      background: linear-gradient(0deg, rgba(165, 173, 181, .25) 0%, rgba(165, 173, 181, 0) 38.54%);
      height: 1.5rem;

      @include desktop {
        height: 3rem;
      }
    }

    .stickyButton {
      align-items: center;
      background: $white;
      display: flex;
      justify-content: flex-end;

      .description {
        color: $bluestone;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-right: 1rem;
      }

      .button {
        background: $reaRed;
        border-radius: .3rem;
        height: auto;
        line-height: 2rem;
        margin: .8rem 1.6rem .8rem 0;
        min-width: 0;
        padding: .6rem 5.95rem;
      }

      .button:disabled {
        background: $pebble15;
        color: $pebble60;
      }
    }
  }
}
