@import "grid.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/spacing.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/sizes.scss";

.modal {
  [class~="modal_header"] {
    font-family: $font-header-bold;
    font-size: $xlarge;
  }
}

.fieldWrapper {
  border: solid .1rem $sl-grey;
  font-size: $regular;
  margin-top: $space-medium;
  padding: .3rem $space-medium $space-medium $space-medium;
}

.contentWrapper {
  display: inline-block;
  font-size: $regular;

  .productTitle {
    flex: 1;
    font-family: $font-body-bold;
    font-size: $regular;
    padding-bottom: var(--space);
  }

  .productsWrapper {
    align-items: stretch;
    display: flex;
    gap: $space;
    margin-bottom: 0;
    margin-top: $space-large;

    .productWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      max-width: 50%;
    }

    .productDetail {
      border: none;
      display: flex;
      justify-content: space-between;
      padding: $space-medium;
    }
  }
}

.button {
  font-size: $regular;
  height: 3rem;
  min-width: 13rem;
}

.serviceAbilityContent {
  margin-top: $space;
  padding: 0 $space-small;

  .detailWrapper {
    margin: var(--space-xxl)  -20px $space-small;
    max-height: 60vh;
    overflow-y: scroll;
    padding: 0 var(--space-xxl);
    /* stylelint-disable */
    border-bottom: 1px solid #e0e0e0;
    /* stylelint-enable */
    .inmostMetric {
      /* stylelint-disable */
      background: #E9EBED;
      /* stylelint-enable */
    }

    .metric {
      margin: 0 var(--space-xxl) $space var(--space-xxl);
      /* stylelint-disable */
      border: 1px solid #e0e0e0;
      /* stylelint-enable */
    }

    & > .metric {
      margin-left: 0;
      margin-right: 0;
    }

    .metrics {
      margin: $space-large 0;
    }

    .metricHeaderWrapper {
      align-items: flex-start;
      background: $pebble15;
      padding: $space-medium var(--space-xxl) $space-large $space-large;

      .metricHeader {
        padding-right: 40px;

        .metricHeaderContent {
          align-items: center;
          display: flex;
          font-family: $font-body-bold;
          justify-content: space-between;

          .metricHeaderDisplayName {
            font-size: $xlarge;
            line-height: var(--space-xxl);
          }

          .metricHeaderDisplayValue {
            font-size: $large;
          }
        }

        .description {
          font-size: 1.2rem;
          margin-bottom: 0;
          width: 80%;
        }
      }
    }

    .additionalValue {
      /* stylelint-disable */
      border: 1px solid #e0e0e0;
      /* stylelint-enable */
      margin: 0 var(--space-l) var(--space-l);

      .additionalValueItem {
        align-items: center;
        display: flex;
        height: 36px;
        justify-content: space-between;
        padding: 0 var(--space-xxl) 0 $space-large;

        &:nth-child(even) {
          /* stylelint-disable */
          background: #F8F8F8;
          /* stylelint-enable */
        }

        &:nth-child(odd) {
          background: $white;
        }
      }
    }

    .notInmostAdditionalValue {
      margin: $space-large var(--space-xxl) $space-large;
    }

    .detailTable {
      padding: 0 var(--space-l) var(--space-l);

      .tableWrapper {
        background: $white;
        /* stylelint-disable */
        border: 1px solid #e0e0e0;
        /* stylelint-enable */

        table {
          border-spacing: 0;
          margin-bottom: 0;

          tr {
            height: 35px;

            td,
            th {
              border: 0;
              vertical-align: top;
            }
          }

          thead {
            box-shadow: 0 $space $space -6px rgba(0, 0, 0, .2);
            font-size: 1.2rem;
            line-height: $xlarge;
            text-transform: uppercase;
          }

          tbody {
            font-size: $regular;
          }

          tfoot {
            font-family: $font-body-bold;
            font-size: $large;

            tr:nth-child(odd) {
              /* stylelint-disable */
              background: #F4F4F4;
              /* stylelint-enable */
            }
          }
        }
      }
    }
  }
}
