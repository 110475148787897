@import "shared/theme/colours";
@import "shared/theme/fonts";

.modalWrapper {
  width: 40rem;

  .header {
    color: $mine-shaft;
    font-family: $font-header-bold;
    font-size: $xlarge;
    font-weight: 900;
  }

  .modalBody {
    margin: var(--space-medium) 0;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;

    .button {
      min-width: 15rem;
      padding: 0;

      &:nth-child(2) {
        margin-left: var(--space-medium);
      }
    }
  }
}
