@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.chartContainer {
  display: flex;
  flex-wrap: wrap;
  padding: var(--space-large) 0 0;

  .chartTitle {
    font-size: 1.4rem;
    padding-right: var(--space-small);
    text-align: center;
    transform: rotateZ(180deg);
    white-space: nowrap;
    writing-mode: vertical-lr;
  }

  & > div:nth-child(4) {
    flex: 1;
  }
}

.perspectives {
  display: flex;
  outline: none;
  padding: 0 var(--space-xxxl);
  width: 100%;

  & > div {
    align-items: center;
    background-color: $sl-grey;
    border-radius: 1rem;
    color: $bluestone;
    cursor: pointer;
    display: flex;
    height: 2rem;
    margin: 0 var(--space-s);
    padding: 0 1rem;
    user-select: none;
  }

  & > div.filterBtn {
    font-family: $font-body-bold;
    font-size: 1.2rem;
    height: 2.3rem;
    justify-content: center;
    width: 9.8rem;
  }

  & > div.selected {
    background-color: $bluestone;
    color: white;
    font-weight: bold;
  }

  & > div.title {
    background-color: unset;
    color: unset;
    color: rgb(50, 50, 50);
    font-size: 1.2rem;
  }
}

.stepBackBtnContainer {
  bottom: 15px;
  left: 5px;
  position: absolute;
  z-index: 2;
}

.stepBackBtn {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  min-height: 0;
  min-width: 0;
  padding: 0 var(--space-small);
}
