@import "grid.scss";

.calculating {
  opacity: .5;
}

.fundingPosition {
  .sectionRow {
    .fieldWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > div:nth-child(2) {
        white-space: nowrap;
      }
    }
  }
}

.title {
  min-height: var(--space-xxxl);
}

.noteItem {
  margin-bottom: var(--space-l);
  padding: var(--space-xl);
  width: 100%;

  & > div {
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    padding: var(--space) 0;
  }

  .noteSummary {
    font-family: Museo-Sans-500, sans-serif;
    padding: var(--space) 0;
  }
}

.tooltip {
  cursor: pointer;
  font-size: 1.3rem;
  width: 100%;
}
