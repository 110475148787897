@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.taskListTemplates {
  font-family: Museo-Sans-300, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;

  &:last-child {
    margin-top: calc(var(--space) * 7);
  }

  .taskListTemplateTitle {
    @include phone {
      display: block;
    }

    align-items: center;
    display: flex;
    justify-content: space-between;

    h2 {
      padding-top: var(--space-medium);
    }
  }

  .taskListTemplateContent {
    border: .1rem solid $sl-grey;
    border-radius: .2rem;
    box-shadow: .1rem .1rem 0 0 $sl-grey;
    margin-top: var(--space-medium);

    .taskListTemplateRow {
      align-items: center;
      border-bottom: .1rem solid $sl-grey;
      display: flex;
      line-height: 2rem;
      padding: var(--space-medium);
      word-break: break-word;

      &:last-child {
        border-bottom: none;
      }

      .taskListTemplateName {
        cursor: pointer;
        flex-grow: 1;
      }

      .actionMenu {
        align-items: center;
        color: $ocean;
        cursor: pointer;
        display: flex;
        margin-left: var(--space-medium);
        min-width: 6rem;

        span {
          margin-right: var(--space);
        }
      }
    }
  }
}

.newTaskListTemplate {
  font-family: Museo-Sans-300, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;

  &.readonly {
    .taskListTaskRow {
      padding-left: 10px;
    }
  }

  .backLink {
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: var(--space-large);

    .backIcon {
      border: solid $teal-blue;
      border-width: 0 .2rem .2rem 0;
      cursor: pointer;
      display: inline-block;
      padding: calc(var(--space) * .3);
      transform: rotate(135deg);
    }

    .backButton {
      font-family: Museo-Sans-300, sans-serif;
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  h2 {
    word-break: break-word;
  }

  .newTemplateContent {
    @include phone {
      min-width: 20rem;
      width: 100%;
    }

    @include tablet-portrait {
      min-width: 20rem;
      width: 100%;
    }

    border: .1rem solid $sl-grey;
    border-radius: .2rem;
    box-shadow: .1rem .1rem 0 0 $sl-grey;
    margin-top: var(--space);
    padding: var(--space-large);
    width: 80rem;

    .taskListLabel {
      font-family: Museo-Sans-500, sans-serif;
      font-size: 1.4rem;
    }

    .newTemplateForm {
      @include phone {
        min-width: 20rem;
        width: 100%;
      }

      width: 50rem;

      .taskContent {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;

        :global {
          .input-label-wrapper {
            margin-right: 0;
            width: 100%;
          }
        }
      }

      .addTaskTemplateButton {
        height: 3.3rem;
        margin-bottom: var(--space-medium);
        margin-left: var(--space-medium);
        min-width: 4rem;
        padding: 0 var(--space-large);
      }

      .taskListTaskUl {
        padding-left: 0;

        .taskListTaskRow {
          align-items: center;
          border-top: .01rem solid $brown-grey;
          display: flex;
          margin: 0;
          min-height: 3.4rem;

          .dragIcon {
            display: flex;
            margin-right: var(--space-medium);
          }

          span {
            cursor: pointer;
            outline: none;
            padding: 1rem 0;
            width: 100%;
            word-break: break-word;
          }

          .removeButton {
            color: $brick;
            margin-left: var(--space);
            margin-right: 0;
          }
        }
      }
    }
  }

  .newTemplateFooter {
    @include phone {
      display: block;
      width: 100%;
    }

    @include tablet-portrait {
      width: 100%;
    }

    display: flex;
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
    height: 4rem;
    justify-content: space-between;
    margin-top: var(--space-large);
    width: 80rem;

    .cancelButton {
      margin-left: calc(var(--space) * 4);
    }

    .deleteButton {
      @include phone {
        margin-top: var(--space-medium);
      }

      align-items: center;
      border: solid .2rem $brick;
      border-radius: .2rem;
      color: $brick;
      display: flex;
      padding: 0 4rem;

      .deleteButtonText {
        margin-right: .4rem;
      }
    }
  }
}

.taskListTemplateTaskModal {
  @include phone {
    left: var(--space);
    min-height: 25rem;
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  @include tablet-portrait {
    left: var(--space);
    min-height: 25rem;
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  background: white;
  border-radius: 1px;
  left: 25%;
  padding: 0;
  position: fixed;
  top: 15%;
  width: 45vw;

  > div {
    padding: 0;

    > button {
      margin-right: var(--space);
    }
  }

  .modalHeader {
    font-family: $font-body-bold;
    font-size: $xlarge;
    font-weight: normal;
    line-height: normal;
    padding: var(--space-medium) calc(var(--space) * 3);
  }

  .modalContent {
    padding: 0 calc(var(--space) * 3);
    width: 100%;
  }

  .contentItem {
    margin-bottom: var(--space-large);
    margin-top: var(--space-medium);
    width: 100%;

    > div {
      padding: var(--space-small) 0;
    }

    > span {
      padding: 0;
    }

    > input {
      margin-top: var(--space);
    }

    > textarea {
      height: 9rem;
      margin-top: var(--space);
      padding: var(--space-small);
    }
  }

  .buttonGroup {
    margin-bottom: var(--space-large);
    margin-top: var(--space-medium);

    button {
      border-radius: .2rem;
      font-family: $font-body;
      font-size: $regular;
      font-weight: 600;
      height: 3rem;
      min-width: 13.2rem;
      padding: 0;
    }
  }
}

.deleteConfirmation {
  @include phone {
    left: var(--space);
    overflow: visible;
    position: fixed;
    top: var(--space);
    width: calc(100vw - var(--space-large));
  }

  left: 35%;
  position: fixed;
  top: 20%;
  width: 30rem;

  .confirmationInfo {
    font-family: $font-body;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: var(--space) 0 var(--space-large) 0;
  }

  .buttonsContainer {
    button {
      border-radius: .2rem;
      font-family: $font-body;
      font-size: $regular;
      font-weight: 600;
      height: 3rem;
      min-width: 13.2rem;
      padding: 0;
    }
  }

  & > div[class*="Modal_footer"] {
    border-top: solid .1rem $sl-grey;
  }
}
