@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";

.taskSection {
  margin: 0;
  padding: 0;
}

.taskSectionTitle {
  color: black;
  font-family: Museo-Sans-500, sans-serif;
  font-size: 1.6rem;
  padding-bottom: var(--space-l);
}
