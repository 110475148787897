@import "shared/theme/colours.scss";
$small: 1rem;
$regular: 1.3rem;
$large: 1.4rem;
$xlarge: 1.6rem;
$xxlarge: 1.8rem;
$xxxlarge: 2rem;
$font-header: museo-sans-500, sans-serif;
$font-header-bold: museo-sans-700, sans-serif;
$font-body: museo-sans-300, sans-serif;
$font-body-bold: museo-sans-500, sans-serif;
$font-body-super-bold: museo-sans-700, sans-serif;

$font-open-sans: 'Open Sans', sans-serif;

html {
  color: $mine-shaft;
  font-size: 62.5%;
}

p,
.paragraph {
  font-family: $font-body;
  font-size: $regular;
}

.content-label {
  color: $sl-dark-grey;
  font-family: $font-body;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.subtext {
  color: $sl-dark-grey;
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1;
}

h1,
h2,
h3,
h4 {
  color: $mine-shaft;
  margin: 0;
}

h1,
.header-1 {
  font-family: $font-header-bold;
  font-size: $xxxlarge;
  padding-bottom: var(--space-large);
}

h2,
.header-2 {
  font-family: $font-header-bold;
  font-size: $xxlarge;
  padding-bottom: var(--space-medium);
}

h3,
.header-3 {
  font-family: $font-header;
  font-size: $xlarge;
  padding-bottom: var(--space);
}

h4,
.header-4 {
  font-family: $font-body-bold;
  font-size: $large;
  padding-bottom: var(--space);
}

:export {
  fontbody: $font-body;
  fontbodybold: $font-body-bold;
  fontheader: $font-header;
  fontheaderbold: $font-header-bold;
  large: $large;
  regular: $regular;
  small: $small;
  xlarge: $xlarge;
  xxlarge: $xxlarge;
  xxxlarge: $xxxlarge;
}
