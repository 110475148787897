@import "grid.scss";
@import "media.scss";
@import "shared/theme/spacing.scss";

.loanStatusInput {
  input {
    text-overflow: ellipsis;
  }
}

.container {
  @include phone {
    height: 25rem;
    overflow: scroll;
    width: 25rem;
  }

  @include tablet-portrait {
    width: 30rem;
  }

  background: white;
  border-radius: .2rem;
  box-shadow: 0 .8rem 1.6rem 0 rgba(0, 0, 0, .2);
  margin-top: .5rem;
  padding: 10px;
  position: absolute;
  width: 70rem;
  z-index: 20;

  .contentBody {
    @include grid(3, $space-large);

    @include phone {
      display: flex;
      flex-direction: column;
    }

    @include tablet-portrait {
      display: flex;
      flex-direction: column;
    }

    margin-top: $space-large;
    width: 100%;

    .loanStatusSection {
      @include phone {
        margin-bottom: $space-medium;
      }

      @include tablet-portrait {
        margin-bottom: $space-medium;
      }
    }
  }

  @mixin stack-up {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;
  }

  @mixin lower-button-spacing {
    margin-left: 0;
    margin-top: $space-medium;
  }

  .buttonContainer {
    @include phone {
      @include stack-up;
    }

    @include tablet-portrait {
      @include stack-up;
    }

    margin-top: $space-large;

    :nth-child(2) {
      @include phone {
        @include lower-button-spacing;
      }

      @include tablet-portrait {
        @include lower-button-spacing;
      }

      margin-left: $space-medium;
    }
  }
}
