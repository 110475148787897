@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";

.optOutModal {
  padding: 0;
  width: 45rem;

  [class~="modal_header"] {
    padding: var(--space-small) var(--space-large);
  }

  [class~="modal_footer"] {
    padding: var(--space-medium) var(--space-large);
  }

  .optOutModalHeader {
    flex: 1 1;
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
  }

  .optOutModalBody {
    height: 20rem;
    margin: var(--space) 0;
    width: 100%;

    .optOutReason {
      span {
        color: $sl-dark-grey;
        margin-bottom: .4rem;
      }

      textarea {
        height: 15rem;
        resize: none;
      }
    }
  }

  .optOutModalButton {
    height: 3rem;
    min-width: 13rem;
  }
}
