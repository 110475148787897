@import "grid.scss";
@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";

.complianceModal {
  @include tablet-portrait {
    display: initial;
  }

  @include phone {
    display: initial;
  }

  align-self: center;
  height: fit-content;
  max-width: 52rem;
}

.compliance {
  padding: $space-medium;

  .info {
    margin-top: $space-large;
  }

  .declaration {
    margin-top: $space-large;

    .checkBoxLabel {
      align-items: initial;
    }

    .checkBoxInput {
      margin-top: .3rem;
    }
  }

  .boldText {
    font-family: $font-body-bold;
  }
}
