@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.sectionHeader {
  align-items: center;
  background-color: $pebble15;
  display: grid !important;
  min-height: 4.8rem;
  padding: 0 1rem;

  @include layout_details;

  :global {
    .show-more-icon {
      grid-column: span 1;
      grid-column-end: -1;
      grid-row: 1;
      justify-self: end;
    }

    .show-more-name {
      grid-column-end: -2;
      grid-column-start: 1;
      grid-row: 1;
    }
  }

  span {
    font-family: $font-body-bold;
    font-size: 16px;
  }
}

.addButtonWrapper {
  @include grid_btn_always_10(2);
}

.addButton {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--space);
  min-width: unset;
  padding: 0 1rem;
}

.sectionWrap {
  margin-bottom: 1.5rem;
}
