$tablet-portrait-width: 768px;
$tablet-landscape-max-width: 1366px;
$desktop-width: 1024px; // desktop & tablet landscape mode

@mixin phone {
  @media screen and (max-width: #{$tablet-portrait-width - 1px}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: #{$desktop-width - 1px}) and (min-width: #{$tablet-portrait-width}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin less-than-desktop {
  @media screen and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin touch-device {
  @media (hover: none) {
    @content;
  }
}

@mixin tablet-landscape { // tablet landscape only
  @media screen and (min-width: #{$desktop-width}) and (max-width: #{$tablet-landscape-max-width}) {
    @content;
  }
}

@mixin tablet-landscape-touchable {
  @include tablet-landscape {
    @include touch-device {
      @content;
    }
  }
}

:export {
  desktop_width: $desktop-width;
  tablet_landscape_max_width: $tablet-landscape-max-width;
  tablet_width: $tablet-portrait-width;
}
