@import "media.scss";
@import "shared/theme/spacing.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

$navbar-height: 5.2rem;

.loansSummary {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$navbar-height} - var(--space-large) - var(--space-xxl));

  .nakedButton {
    font-size: $large;
    margin-right: var(--space-s);
    padding: 0 var(--space-xxl);
  }

  .viewAllCheck {
    color: $mine-shaft;
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.4rem;
    margin: 0;
    padding: 2rem 0;
  }
}

.header {
  @include phone {
    align-items: start;
    flex-direction: column;
  }

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 var(--space-large);

  h1 {
    padding: 0;
  }
}

.toolBar {
  @include phone {
    button {
      width: 50%;
    }
  }

  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.loansSummaryMobiusTable {
  flex: 1 1 auto;
}

.loansSummaryMobiusTableRow {
  height: 35px;
}

.categoriesCell {
  overflow: visible;
}

.categoriesFilterCell {
  :global(.input-label-wrapper) {
    margin: 0 0 0 var(--space-s);
  }
}

.categoriesPopover {
  max-width: 35rem;
  min-width: 20rem;
}
