@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";

.overrideSectionWrapper {
  @include layout-details;

  grid-row-gap: 0;
}

.fieldWrapper {
  grid-column: unset;
  grid-column-end: span 2;
}

.input {
  width: 100%;

  :global(.dx-datebox) {
    min-height: 3.3rem;
    padding: .3rem;
  }

  :global(.dx-datebox .dx-texteditor-input-container input) {
    font-family: Museo-Sans-500, sans-serif;
    font-size: 1.3rem;
  }
}

.withError {
  border-color: $brick !important;
}

.summaryWrapper {
  background: $pebble05;
  padding: .5rem 2rem;
}

.sectionWrapper {
  padding: .5rem 2rem;

  @include desktop {
    > div {
      margin-bottom: 0;
    }
  }
}

.multiRowFieldWrapper {
  > div {
    justify-content: unset;
  }
}

.singleColumnWrapperOnDesktop {
  @include desktop {
    grid-column-end: span 1;
  }
}

.singleColumnWrapper {
  grid-column-end: span 1;
}

.quadrupleColumnWrapper {
  grid-column-end: span 4;
}

.atFirstColumnOnMobile {
  @include tablet-portrait {
    grid-column-start: 1;
  }

  @include phone {
    grid-column-start: 1;
  }
}

.atFirstColumnOnDesktop {
  @include desktop {
    grid-column-start: 1;
  }
}

.atSecondColumnOnDesktop {
  @include desktop {
    grid-column-start: 2;
  }
}

.atSeventhColumnOnDesktop {
  @include desktop {
    grid-column-start: 7;
  }
}

.atSixthColumnOnDesktop {
  @include desktop {
    grid-column-start: 6;
  }
}

.atFirstColumnOnTablet {
  @include tablet-portrait {
    grid-column-start: 1;
  }
}

.atFifthColumnOnTablet {
  @include tablet-portrait {
    grid-column-start: 5;
  }
}

.atFifthColumnOnDesktop {
  @include desktop {
    grid-column-start: 5;
  }
}

.atThirdColumnOnDesktop {
  @include desktop {
    grid-column-start: 3;
  }
}

.atFifthRowOnMobile {
  @include phone {
    grid-row: 5;
  }
}

.dx-datebox {
  min-height: .33rem;
}

.spouseNameInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.radiobutton {
  align-items: center;
  display: flex;
  margin-top: 1.5rem;

  input {
    margin-right: .4rem;
  }
}

.noLabel {
  align-self: end;
}

.deletedWarning {
  @include tablet-portrait {
    grid-column: 7/span 2;
  }

  @include phone {
    grid-column: 3/span 2;
  }

  align-items: center;
  color: $reaRed;
  display: flex;
  font-family: $font-header;
  font-size: $regular;

  > svg {
    flex-shrink: 0;
    margin-left: 3.5rem;
    margin-right: 1.6rem;
  }
}

.override {
  margin-top: 2.3rem;

  input[type=checkbox] {
    align-self: center;
    margin-top: 0;
  }
}
