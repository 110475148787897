@import 'grid.scss';
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

@mixin common {
  margin: 0;
  width: 100%;
}

.container {
  border: 1px solid $sl-grey;
  margin-bottom: var(--space-m);
  margin-top: 0;
  padding: 1rem;
}

.fields {
  @include layout_details;
}

.typeField {
  @include desktop {
    @include column(1/span 2);
  }

  @include common;
}

.lenderField {
  @include desktop {
    @include column(3/span 2);
  }

  @include common;
}

.securedByField {
  @include desktop {
    @include column(5/span 3);
  }

  @include common;
}

.input {
  height: 3.3rem;
  margin: 0;
  width: 100% !important;
}

.ownershipsField {
  overflow-wrap: anywhere;

  @include desktop {
    @include place(2/span 5, 5/span 1);
  }

  @include common;
}

.amountField {
  @include common;
  @include column(span 1);

  > input {
    text-align: right;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row1Column8 {
  @include desktop {
    grid-column: 8/span 1;
    grid-row: 1;
  }
}

.row1Column9 {
  @include desktop {
    grid-column: 9/span 1;
    grid-row: 1;
  }
}

.toBePaidOutField {
  @include desktop {
    @include column(10/span 1);
  }

  @include common;
}

.repaymentTypeField,
.existingLoan {
  @include desktop {
    @include column(1/span 2);
  }

  @include common;
}

.interestTypeField {
  @include desktop {
    @include column(3/span 1);
  }

  @include common;
}

.originalTermField {
  @include desktop {
    @include column(4/span 1);
  }

  @include common;
}

.remainingTermYearField {
  @include desktop {
    @include column(3/span 1);
  }

  @include column(span 1);
  @include common;
}

.remainingTermMonthField {
  @include desktop {
    @include column(4/span 1);
  }

  @include column(span 1);
  @include common;
}

.remainingTermDense {
  @include desktop {
    @include row(2);
  }
}

.taxDeductibleField {
  @include desktop {
    @include column(6/span 1);
  }

  @include common;

  > div {
    height: 3.3rem;
  }
}

.interestRateField {
  @include desktop {
    @include column(7/span 1);
  }

  @include common;
}

.repaymentsField {
  @include desktop {
    @include column(8/span 1);
  }

  @include common;

  > input {
    text-align: right;
  }
}

.frequencyField {
  @include desktop {
    @include column(9/span 1);
  }

  @include common;
}

.clearingAmount {
  @include desktop {
    @include column(10/span 1);
  }

  @include common;
}

.accountNameField {
  @include column(1/span 2);
  @include common;

  align-self: flex-start;
}

.bsbField {
  @include desktop {
    @include column(3/span 2);
  }

  @include common;

  align-self: flex-start;
}

.accountNumberField {
  @include desktop {
    @include column(3/span 2);
  }

  @include common;
}

.descriptionField {
  @include desktop {
    @include column(1/span 2);
  }

  @include common;
}

.readOnlyNumber {
  justify-content: flex-end;
}

.typeField,
.lenderField,
.loanLimitField,
.ownershipsField,
.securedByField,
.repaymentTypeField,
.interestTypeField,
.originalTermField,
.outstandingBalanceField,
.remainingTermMonthField,
.remainingTermYearField,
.interestRateField,
.repaymentsField,
.frequencyField,
.clearingAmount,
.toBePaidOutField,
.taxDeductibleField,
.existingLoan {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}

.readOnlyOptions {
  width: 100%;

  & > div {
    margin-right: var(--space);
  }
}
