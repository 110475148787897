@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";

.radiobutton {
  align-items: center;
  display: flex;
  margin-top: 1.5rem;

  input {
    margin-right: .4rem;
  }
}

.multiRowFieldWrapper {
  > div {
    justify-content: unset;
  }
}

.summaryWrapper {
  background: $pebble05;
  padding: .5rem 2rem;
}

.overrideSectionWrapper {
  @include layout-details;

  grid-row-gap: 0;
}

.deletedWarning {
  @include tablet-portrait {
    grid-column: 7/span 2;
  }

  @include phone {
    grid-column: 3/span 2;
  }

  align-items: center;
  color: $reaRed;
  display: flex;
  font-family: $font-header;
  font-size: $regular;

  > svg {
    flex-shrink: 0;
    margin-left: 3.5rem;
    margin-right: 1.6rem;
  }
}

.input {
  width: 100%;

  :global(.dx-datebox) {
    min-height: 3.3rem;
    padding: .3rem;
  }

  :global(.dx-datebox .dx-texteditor-input-container input) {
    font-family: Museo-Sans-300, sans-serif;
    font-size: 1.3rem;
  }
}

.deletedWarningSection {
  align-self: center;

  @include desktop {
    grid-column: 9/span 2;
  }

  @include tablet-portrait {
    grid-column: 7/span 2;
  }
}
