@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "shared/theme/colours.scss";

.reasonSection {
  margin: var(--space-l) 0;

  .reasonTitle {
    color: $sl-dark-grey;
    font-size: 1.1rem;
    margin-bottom: var(--space);
  }
}
