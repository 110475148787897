@import 'media.scss';

.header {
  align-items: center;
  display: grid;
  grid-column-gap: var(--space-m);

  @include desktop {
    grid-template-columns: repeat(9, [col-start] 1fr);
  }

  @include tablet-portrait {
    grid-template-columns: repeat(7, [col-start] 1fr);
  }

  @include phone {
    grid-template-columns: repeat(3, [col-start] 1fr);
  }

  > span {
    line-height: 4rem;

    @include desktop {
      grid-column: 1 / span 5;
    }

    @include tablet-portrait {
      grid-column: 1 / span 4;
    }

    @include phone {
      grid-column: 1 / span 3;
    }
  }

  .labelWrapper {
    margin: 0;
    text-align: right;
    width: 100%;

    .textClassName {
      justify-content: flex-end;
    }

    label {
      cursor: inherit;
    }
  }
}
