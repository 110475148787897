@import "media.scss";
@import "shared/theme/colours.scss";

.link {
  color: rgb(0, 99, 158);
  display: inline-block;
  font-family: Museo-Sans-300, serif;
  font-size: 1.3rem;
  line-height: 1.3rem;
  margin-bottom: .4rem;

  & > svg {
    margin-right: var(--space-small);
    vertical-align: bottom;
  }
}

.disclaimer {
  color: $mine-shaft;
  font-family: museo-sans-100, sans-serif;
  font-size: 12px !important;
}
