@import "media.scss";
@import "grid.scss";
@import "shared/theme/fonts.scss";

.contentNavWrapper {
  @include desktop {
    @include grid(12, var(--space-m));
  }

  @include tablet-portrait {
    @include grid(8, var(--space-m));
  }

  @include phone {
    @include grid(4, var(--space-m));
  }

  font-size: $regular;
  margin-top: calc(var(--space) * 3);
}

.leftNav {
  @include desktop {
    @include span(2);
  }

  @include tablet-portrait {
    @include span(8);
  }

  @include phone {
    @include span(4);
  }

  @include row(1);

  font-size: $regular;
  height: fit-content;
  margin-right: 0;
}

.appContent {
  @include desktop {
    @include span(10);
  }

  @include tablet-portrait {
    @include span(8);
    @include row(2);
  }

  @include phone {
    @include span(4);
    @include row(2);
  }

  :global {
    .form-input-field.select {
      width: 100% !important; // fix width of selects
    }
  }
}
