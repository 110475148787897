@import "shared/theme/colours.scss";

.category {
  display: flex;
  padding: var(--space) 0;

  &:hover {
    background-color: $sl-grey;
  }
}

.deletedCategory {
  color: $shadow-grey;
}

.name {
  padding: 0 var(--space-s);
  transition: color .3s;
  width: 35rem;
  word-break: break-all;
}

.deletedName {
  text-decoration: line-through;
}

.deleteBtnContainer {
  flex: 1;
  padding: 0 var(--space-s);
  text-align: center;
}

.btn {
  transition: color .3s;
}

.deleteBtn {
  color: $brick;
}

.revertBtn {
  color: $teal-blue;
}
