@import "shared/theme/colours.scss";
@import "media.scss";
@import "grid.scss";

.needs {
  @include grid;

  @include phone {
    grid-row-gap: 0;
  }

  margin: var(--space) var(--space);

  .left {
    @include span(8);

    @include phone {
      @include span(12);
    }

    & > div {
      width: 100%;
    }
  }

  .right {
    @include span(4);

    @include phone {
      @include span(12);
    }

    & > div {
      width: 100%;
    }
  }
}

.commonSection {
  margin: var(--space) var(--space);

  & > span {
    color: $sl-dark-grey;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--space);

    @include grid;
  }
}

.item {
  @include span(4);

  @include tablet-portrait {
    @include span(6);
  }

  @include phone {
    @include span(12);
  }
}

.radioGroup {
  > label {
    color: black;
    font-family: OpenSans, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
  }

  width: 100%;
}

.multipleSelect {
  margin-top: var(--space);
  width: 100%;
}

.input {
  height: 3.3rem;
  margin: 0;
  width: 100% !important;
}

.selectWrapper {
  display: flex;
  justify-content: space-between;
}

.select {
  width: 50%;
}

.otherDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.readOnlyOptions {
  width: 100%;

  & > div {
    margin-bottom: var(--space-s);
  }
}
