@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.householdExpenseWrapper {
  display: flex;
  flex-direction: column;
  min-width: 50rem;
  padding-right: var(--space);
  width: 50rem;
}

.householdExpenseTitle {
  color: $slDarkGrey;
  display: block;
  font-size: 1.2rem;
  padding-bottom: .4rem;
}

.householdSelect {
  width: 100%;
}

.checkboxDropdown {
  border: solid .5px $brown-grey;
  margin-bottom: 0;
  width: 100%;
}

.readOnlyOptions {
  width: 100%;

  & > div {
    display: inline-block;
    margin-right: var(--space);
  }
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--space);

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}

.householdTotal {
  margin-bottom: var(--space-l);
  margin-top: var(--space-xxxl);

  .header {
    font-family: $font-header;
    font-size: $xlarge;
    margin-bottom: var(--space-s);
  }

  .content {
    align-items: center;
    background-color: $pebble15;
    grid-row-gap: var(--space-m);
    padding: var(--space-xxl) var(--space);

    .monthlyTotal {
      color: $slDarkGrey;
      font-family: $font-header;
      font-size: 1.1rem;

      @include place(1, 1 / span 2);
    }

    .monthlyTotalValue {
      color: $mine-shaft;
      font-family: $font-header;
      font-size: $regular;
      padding: 0 var(--space);
      text-align: end;

      @include place(1, 3);
    }

    .annualTotal {
      color: $slDarkGrey;
      font-family: $font-header;
      font-size: 1.1rem;

      @include place(2, 1 / span 2);
    }

    .annualTotalValue {
      color: $mine-shaft;
      font-family: $font-header;
      font-size: $regular;
      padding: 0 var(--space);
      text-align: end;

      @include place(2, 3);
    }

    @include grid($number-of-columns: 4, $spacing: var(--space-medium));
  }
}

.dependentInput {
  display: flex;
  flex-direction: column;
  height: 7rem;
  justify-content: flex-start;
  margin-bottom: var(--space);
}

.householdLabel {
  align-items: center;
  background-color: $pebble15;
  display: flex;
  font-family: $font-header;
  font-size: $xlarge;
  font-weight: 500;
  height: 4.2rem;
  margin-bottom: var(--space-m);
  padding: 0 var(--space);
}
