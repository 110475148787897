@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";

.filtersWrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  p {
    flex-shrink: 0;
    line-height: 2.6rem;
    margin: 0;
    width: 6rem;
  }

  .filterTags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .filter {
      background: $sl-grey;
      border-radius: 1.1rem;
      color: $bluestone;
      cursor: pointer;
      font-family: $font-body-bold;
      font-size: $regular;
      height: 2.3rem;
      line-height: 2.3rem;
      margin: .3rem $space;
      min-width: 8rem;
      padding: 0 var(--space);
      position: relative;
      text-align: center;

      &:last-child {
        color: $sl-dark-grey;

        &::before {
          background-color: $sl-grey;
          content: "";
          display: block;
          height: 100%;
          left: -.8rem;
          position: absolute;
          width: 1px;
        }
      }

      &.selected {
        background: $bluestone;
        color: $white;
      }
    }
  }
}
