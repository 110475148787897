@import "shared/theme/fonts.scss";
@import "shared/theme/sizes.scss";

.externalInfoLink {
  display: inline-block;
  font-family: $font-body-bold;
  line-height: $size-large;
  margin-bottom: $size-medium;
}

.text {
  display: inline-block;
  font-size: $large;
  margin-left: $size-medium;
  vertical-align: middle;
}
