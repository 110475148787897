@import "grid.scss";
@import "shared/theme/fonts.scss";

.titleWrapperStyle {
  font-size: $xlarge;
}

.contributions {
  @include desktop {
    @include span(3);
  }

  @include tablet-portrait {
    @include span(4);
  }

  @include phone {
    @include span(10);
  }

  display: flex;
  flex-direction: column;

  .contributionsWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .contributionsContentWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .fundsAvailableSubSection {
    @include grid(11);
  }

  .otherFundsSection {
    flex: 1 1 auto;
  }
}

.applicationCosts {
  display: flex;
  flex-direction: column;

  .stampDutyContainer {
    flex: 1;
  }

  .stampDutyInfo {
    margin-left: var(--space-small);
    vertical-align: middle;
  }

  .lmiSubSection {
    @include grid(11);
  }

  .lmiHeader {
    @include span(5);
  }

  .lmiField {
    @include span(11);
  }

  .lmiFields {
    padding: 0;

    @include span(11);
  }
}

.construction {
  .otherCostsSectionWrapper {
    margin-top: var(--space-medium);
  }
}

.infoSection {
  flex: 1;
}

.externalInfoLink {
  @include span(6);

  justify-self: end;
}

.externalInfoText {
  display: inline;
  font-size: 1.2rem;
  margin-left: var(--space-small);
}

.text {
  margin-left: var(--space-small);
}

.infoSectionContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fieldsContainerWrapper {
  padding: 0;
}

.fundingWorksheetWrapper {
  @include grid($number-of-columns: 6, $spacing: var(--space-medium));

  .somaCommentsSection {
    padding-bottom: var(--space);
  }

  @include desktop {
    .contributionsPanel,
    .costsPanel,
    .summaryPanel {
      @include span(2);
    }

    .summaryPanel {
      height: fit-content;
      position: sticky;
      top: var(--space);
    }

    .contributionsPanel {
      bottom: var(--space);
      height: fit-content;
      position: initial;
      top: var(--space);

      .somaCommentsSection,
      .saveButton {
        display: none;
      }
    }
  }

  @include tablet-portrait {
    .contributionsPanel,
    .costsPanel,
    .summaryPanel {
      @include span(3);

      height: fit-content;
    }

    .contributionsPanel {
      order: 3;

      .somaCommentsSection,
      .saveButton {
        display: none;
      }
    }

    .costsPanel {
      order: 1;
    }

    .summaryPanel {
      order: 2;
      position: sticky;
      top: var(--space);
    }
  }

  @include phone {
    .contributionsPanel,
    .costsPanel,
    .summaryPanel {
      @include span(6);
    }

    .contributionsPanel {
      order: 3;

      .saveButton {
        width: 100%;
      }
    }

    .costsPanel {
      order: 2;
    }

    .summaryPanel {
      order: 1;

      .somaCommentsSection,
      .saveButton {
        display: none;
      }
    }
  }
}

.fieldsWrapper {
  @include grid($number-of-columns: 1, $spacing: var(--space-medium));
}

.assetFieldsWrapper {
  @include grid(11);

  & > div {
    grid-column: span 8;
  }
}

.readOnlyAssetFieldsWrapper {
  @include grid(11);

  & > div {
    grid-column: span 11;
  }
}

.fieldWrapperClassName {
  display: flex;
  justify-content: space-between;
}

.basicFunds {
  padding: var(--space-medium);
}

.sectionHeader {
  font-family: Museo-Sans-500, sans-serif;
  font-size: 1.6rem;
  margin: var(--space-m) 0;
}

.emptyCostToPurchase {
  @include desktop {
    @include span(3);
  }

  @include tablet-portrait {
    @include span(4);
  }

  @include phone {
    @include span(10);
  }

  .emptyMessage {
    padding: var(--space-m);
    text-align: center;
  }
}

.refinanceSubSection {
  @include grid(11);

  .liabilityFieldWrapper {
    @include span(8);

    display: flex;
    flex-direction: column;

    & > div {
      flex: 1 1 auto;
      padding-bottom: 1rem;
    }
  }

  .refinanceFieldsContainer {
    @include span(8);

    padding: 0;
  }

  .othersWrapper {
    @include span(11);
  }

  .readonlyFieldsContainer {
    @include span(11);

    padding: 0;
  }
}

.costToPurchase {
  display: flex;
  flex-direction: column;

  .costToPurchaseWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .costToPurchaseContentWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .costToPurchaseSubSection {
    @include grid(11);
  }

  .otherCostsSubSection {
    align-content: start;
    flex: 1 1 auto;

    @include grid(11);
  }

  .otherCostsSubSectionViewOnly {
    align-content: start;
    flex: 1 1 auto;
  }

  .governmentChargesHeader {
    @include span(5);
  }

  .governmentChargesFields {
    padding: 0;

    @include span(11);
  }

  .subSectionHeader,
  .overrideTextField {
    @include span(8);
  }

  .otherCostsWrapper {
    @include span(11);
  }
}

.sideBySideTextInput {
  @include span(8);

  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;

  label {
    vertical-align: middle;
    width: 100%;
  }

  input {
    text-align: right;
  }
}

.sideBySideSelectBox {
  @include span(8);

  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  label {
    vertical-align: middle;
    width: 100%;
  }

  & > div {
    width: 100%;
  }
}

.sideBySideSwitch {
  @include span(8);

  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  label {
    vertical-align: middle;
  }
}

.section {
  padding: var(--space-medium);
}

.subSection {
  padding: var(--space-medium) 0;
}

.title {
  min-height: var(--space-xxxl);
}

.lenderSelectWidth {
  margin-right: unset;
  width: 100%;
}

.lenderSelect {
  width: 100%;
}

.baseLoanAmount {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  text-align: left;

  label {
    font-family: Museo-Sans-500, sans-serif;
    vertical-align: middle;
    width: 100%;
  }

  input {
    text-align: right;
  }
}

.suggestedLoanAmountField {
  align-items: center;
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  padding: var(--space-m) 0 0;

  & > div:last-child {
    font-family: Museo-Sans-500, sans-serif;
    font-size: $regular;
  }
}

.somaComments {
  textarea {
    height: 14.7rem;
  }

  width: 100%;
}
