@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/spacing.scss";
@import "media.scss";

.modal {
  [class~="modal_header"] {
    font-family: $font-header-bold;
    font-size: $xlarge;
  }

  input {
    text-align: right;
  }
}

.productItem {
  border-top: solid .1rem $sl-grey;
  margin-top: var(--space-large);
  padding-bottom: var(--space-large);
  padding-top: var(--space-xl);
  width: 100%;

  > h2 {
    font-family: $font-header;
    font-size: $xlarge;
  }
}

.rowHeader {
  padding-top: var(--space-xl);
}

.productRow {
  display: flex;
  flex-wrap: wrap;

  @include less-than-desktop {
    > div {
      width: 100%;
    }

    margin-bottom: 2rem;
  }

  @include tablet-landscape {
    > div {
      width: 100%;
    }

    margin-bottom: 2rem;
  }
}

.form {
  padding-top: var(--space-large);
}

.offPanelLenderFields {
  display: flex;
}

.sectionRow {
  display: flex;
  flex-direction: column;
}

.footer {
  border-top: solid .1rem $sl-grey;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: var(--space-large);
  padding: var(--space-xxl) 0 0;
  width: 100%;

  > button {
    margin-top: var(--space-xxl);
    width: 25%;
  }
}

.info {
  max-width: fit-content;
}
