@import "grid.scss";
@import "media.scss";
@import "shared/theme/colours.scss";

.extraInfoGrid {
  @include layout-details;
}

.interestRateDescription {
  @include tablet-portrait {
    @include span(4);
  }

  @include phone {
    @include span(4);
  }

  @include desktop {
    @include span(6);
  }
}

.interestRateDescription p {
  color: $darker-grey;
  font-size: 1.2rem;
  margin: 0;
}

.interestRateAlertDetail {
  font-size: 1.4rem;
}

.interestRateGrid {
  @include tablet-portrait {
    @include span(4);
  }

  @include phone {
    @include span(4);
  }

  @include desktop {
    @include span(6);
  }
}

.slimInterestRateGrid {
  @include tablet-portrait {
    @include span(4);
  }

  @include phone {
    @include span(4);
  }

  @include desktop {
    @include span(4);
  }
}

.interestRateColumnWrapper {
  @include tablet-portrait {
    @include grid($number-of-columns: 4);
  }

  @include phone {
    @include grid($number-of-columns: 2);
  }

  @include desktop {
    @include grid($number-of-columns: 6);
  }
}

.slimInterestRateColumnWrapper {
  @include tablet-portrait {
    @include grid($number-of-columns: 4);
  }

  @include phone {
    @include grid($number-of-columns: 2);
  }

  @include desktop {
    @include grid($number-of-columns: 4);
  }
}

.identifiersGrid {
  @include tablet-portrait {
    @include span(4);

    margin-left: -1rem;
  }

  @include phone {
    @include span(4);

    margin-top: -1.6rem;
  }

  @include desktop {
    @include span(4);

    margin-left: -1rem;
  }
}

.identifiersColumnWrapper {
  @include tablet-portrait {
    @include grid($number-of-columns: 4);

    > * {
      @include span(2);
    }
  }

  @include phone {
    @include grid($number-of-columns: 2);
  }

  @include desktop {
    @include grid($number-of-columns: 4);

    > * {
      @include span(2);
    }
  }
}

.loanLinkGrid {
  @include span(2);

  padding: 1.6rem 1rem;
  white-space: pre-wrap;
}

.loanLink {
  word-break: break-word;
}

.title {
  font-family: Museo-Sans-500, serif;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}
