@import 'media.scss';
@import "shared/theme/colours.scss";
@import "shared/theme/spacing.scss";
@import 'shared/theme/fonts.scss';

.loanCriteriaSummaryFeatures {
  @include tablet-portrait {
    margin-top: 0 !important;
    width: 100% !important;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-large);
}

.text {
  margin-top: 1rem;
}

.container {
  background-color: $sl-light-grey;
  border: solid 1px $light-grey;
  border-radius: .2rem;
  color: $mine-shaft;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-body;
  font-size: $regular;
  justify-content: space-between;
  letter-spacing: .2px;
  line-height: normal;
  margin: 0 0 var(--space-xxl) 0;
  padding: $space-medium;
  position: relative;

  .buttonContainer {
    display: inline-flex;
    flex-direction: column;

    .topButton {
      margin-bottom: $space,;
    }
  }
}
