@import "shared/theme/colours.scss";

.modal {
  border-radius: 6.8px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, .2);
  height: 313px;
  width: 492px;

  > div {
    padding-left: 31px;
    padding-right: 31px;
  }
}

.header {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .28px;
}

.label {
  color: $mine-shaft;
  font-family: Museo-Sans-300, sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 60px;
}

.button {
  border-radius: 3.6px;
  margin-bottom: 1.5rem;
  min-width: 15.6rem;
  padding: 0;
}

.select {
  width: 26.2rem;
}
