@import "shared/theme/fonts.scss";

.commissionManagementReportDetails {
  font-size: $regular;
}

.title {
  margin-top: 2.5rem;
}

.subTitle {
  font-family: $font-body;
  font-size: $xlarge;
  margin-top: .3em;
}
