@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.listWrapper {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: initial;
  text-align: left;
  width: 100%;
  word-break: break-word;
}

.newValue {
  font-family: $font-body-super-bold;
}
