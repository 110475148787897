
@import "shared/theme/fonts.scss";

.backLink {
  align-items: center;
  display: inline-flex;
  font-size: $regular;
  font-weight: 600;
  margin-left: -5px;
}
