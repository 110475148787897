@import "grid.scss";

.container {
  @include grid;

  align-items: flex-end;
  padding: var(--space);

  & > * {
    @include phone {
      grid-column: span 12 !important;
    }
  }
}

.readOnlyContainer {
  align-items: unset;
}

.yesOrNo {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.yesOrNoSelect {
  margin: 0;
  width: fit-content;

  & > div {
    height: 3.3rem;
  }

  & label {
    padding: 0 var(--space-m);
  }
}

.isPlannedField {
  @include span(7);

  @include tablet-portrait {
    @include span(8);
  }

  margin: 0;
  width: auto;

  & > label {
    color: black;
    font-family: OpenSans, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.mitigantTypeField {
  @include span(5);

  @include tablet-portrait {
    grid-column: 9 / 13;
  }

  margin: 0;
  width: auto;
}

.sectionTitle {
  @include span(12);

  color: black;
  font-family: OpenSans, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
}

.matureAgeField {
  @include span(2);

  margin: 0;
  width: auto;

  & > input {
    width: 50%;
  }

  & > label {
    white-space: nowrap;
  }
}

@include less-than-desktop {
  .matureAgeField {
    & > input {
      width: 100%;
    }
  }
}

.retireDuringLoanTermField {
  grid-column: 3 / 8;
  justify-content: flex-end;

  & > div:first-child {
    padding-right: var(--space);
  }

  @include tablet-portrait {
    grid-column: 4 / 9;
  }
}

.repaymentMethodField {
  @include span(3);

  @include tablet-portrait {
    @include span(4);
  }

  margin: 0;
  width: auto;
}

.repaymentMethodInput {
  height: 3.3rem;
  margin: 0;
  width: 100%;
}

.repaymentMethodOtherDetailField {
  display: flex;
  flex-direction: column;
  grid-column: 8 / span 3;
  margin: 0;
  width: 100%;

  @include tablet-portrait {
    grid-column: 9 / span 4;
  }
}

.readOnlyOptions {
  width: 100%;

  & > div {
    margin-bottom: var(--space-s);
  }
}
