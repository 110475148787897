@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

@mixin sectionSpace {
  margin-bottom: var(--space-large);
  margin-top: var(--space-medium);
}

.taskDetailModal {
  background: white;
  border-radius: 1px;
  padding: 0;
  width: 76.8rem;

  > div {
    padding: 0;

    > button {
      margin-right: var(--space);
    }
  }
}

.modalHeader {
  font-family: $font-body-bold;
  font-size: $xlarge;
  font-weight: normal;
  padding: var(--space-medium) calc(var(--space) * 3);
}

.modalContent {
  padding: 0 calc(var(--space) * 3);
  width: 100%;
}

.labelWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: .4rem 0 .5rem 0;
}

.contentItem {
  width: 100%;

  > div {
    padding: var(--space-small) 0;
  }

  > span {
    padding: 0;
  }

  > input {
    margin-top: var(--space);
  }

  > textarea {
    height: 9rem;
    margin-top: var(--space);
    padding: var(--space-small);
  }
}

.dateTimeLabel {
  @include desktop {
    padding-left: .5rem;
  }

  width: 100%;

  .clearDueDate {
    font-size: 1.1rem;
    padding: 0;
    text-decoration: underline;
  }

  div.customizedDateTimePicker {
    height: 3.2rem;
    margin: 0;
    padding: 0;
    //TODO: need to figure out the blurry border for chrome when use transform
    position: relative;
    top: .1rem;

    input {
      font-size: 1.4rem;
      padding: 0 .5rem;
    }
  }
}

.buttonGroup {
  @include sectionSpace;

  button {
    border-radius: .2rem;
    font-family: $font-body;
    font-size: $regular;
    font-weight: 600;
    height: 3rem;
    min-width: 13.2rem;
    padding: 0;
  }

  .deleteButton {
    border-color: $brick;
    color: $brick;
    float: right;

    .buttonText {
      margin-right: 1rem;
    }

    * {
      vertical-align: middle;
    }
  }
}

.formRow {
  @include sectionSpace;

  &.flex {
    @include desktop {
      display: flex;
    }
  }
}

.formRowCol {
  @include sectionSpace;

  @include desktop {
    margin: 0;
  }

  flex: 1;

  > div {
    margin: 0;
  }
}

.noDataMessage {
  flex: 1;
  font-family: Museo-Sans-700, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  height: 20rem;
  padding: var(--space-large);
  text-align: center;
}
