@import 'grid.scss';
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.wrapper {
  :global {
    .input-label-wrapper {
      margin-bottom: 0;
      margin-right: 0;
      width: auto;
    }
  }
}

.container {
  border: 1px solid $sl-grey;
  margin-bottom: var(--space-m);
  margin-top: 0;
  padding: 1rem;
}

.fieldSection {
  @include grid($number-of-columns: 10, $spacing: var(--space-medium));

  @include phone {
    display: flex;
    flex-direction: column;
  }

  .type {
    @include span(2);
  }

  .governmentBenefits {
    @include place(2, 1 / span 2);
  }

  .details {
    @include place(1, 3 / span 2);
  }

  .frequency {
    @include place(1, 8);
  }

  .income {
    @include place(1, 9);

    input {
      text-align: right;
    }
  }

  .incomeAmountText {
    justify-content: flex-end;
  }
}

.btnWrapper {
  @include grid_btn(2);

  > button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: var(--space);
    padding: 0 1rem;
  }
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}
