@import "grid.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "media.scss";

.listWrapper {
  padding: 0;

  .list {
    list-style: none;
    margin-bottom: var(--space-medium);

    @include grid(11);
  }

  .amountField {
    & > input {
      text-align: right;
    }
  }

  .field {
    margin: 0;
    width: auto;

    @include span(4);
  }

  .buttonContainer {
    grid-column-end: 12;
    grid-column-start: 9;
    width: auto;
  }

  .buttonGroup {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .addButton {
    align-items: center;
    display: flex;
    font-size: $regular;
    height: 3.2rem;
    justify-content: space-between;
    margin-bottom: calc(var(--space-small) * .3);
    min-width: unset;
    padding: 0 var(--space);
  }

  .removeButton {
    color: $reaRed;
    font-family: $font-body;
    height: 3.2rem;
    justify-content: center;
  }
}
