@import "shared/theme/colours.scss";

.contentWrapper {
  border: solid 1px $sl-grey;

  > * {
    &:not(:first-of-type) {
      border-top: solid 1px $sl-grey;
    }
  }
}

.titleWrapper {
  align-items: center;
  background-color: $pebble15;
  border: solid .05rem $sl-grey;
  border-bottom: 0;
  border-radius: .1rem;
  color: $mine-shaft;
  display: flex;
  font-family: Museo-Sans-500, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
  padding: 1rem 1.5rem;
}

.wrapper {
  margin-bottom: var(--space-large);
}
