@import "shared/theme/fonts.scss";
@import "grid.scss";
@import "media.scss";

@mixin modalWrapper {
  background-color: rgba(0, 0, 0, .45);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: calc(var(--space-large) * 3) 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

@mixin modal {
  background-color: $white;
  border: .1rem solid rgb(204, 204, 204);
  border-radius: .4rem;
  font-family: $font-body;
  font-size: $regular;
  margin: auto;
  outline: none;
  overflow: visible;
  padding: var(--space) 0 var(--space-large) 0;
}

// TODO: Change flex to grid for small

.modalWrapperSmall {
  @include modalWrapper;

  display: flex;
}

.modalSmall {
  @include modal;

  display: flex;
  flex-direction: column;
  width: 30em;
}

.modalWrapperMedium {
  @include modalWrapper;
  @include grid;
}

.modalMedium {
  @include modal;
  @include column(#{4 / 10});

  @include phone {
    @include phone {
      @include column(#{1 / 13});

      margin: 0 1rem;
    }
  }
}

.modalWrapperLarge {
  @include modalWrapper;
  @include grid;
}

.modalLarge {
  @include modal;
  @include column(#{3 / 11});

  @include phone {
    @include column(#{1 / 13});

    margin: 0 1rem;
  }
}

.closeButton {
  background-color: transparent;
  border: none;
  padding-left: 7px;
  padding-right: 0;

  &::after {
    content: "\00d7";
    display: inline;
    font-size: 2.5rem;
  }

  &:disabled {
    color: $shadow-grey;
    cursor: not-allowed;
  }
}

.body {
  display: flex;
  flex: 5;
  padding: 0 var(--space-large);
}

.header {
  align-items: center;
  border-bottom: solid .1rem $sl-grey;
  display: flex;
  padding: 0 var(--space-large) var(--space) var(--space-large);
}

.headerContent {
  flex: 1;
  font-family: $font-body-bold;
  font-size: $xlarge;
}

.footer {
  display: flex;
  padding: var(--space-medium) var(--space-large) 0 var(--space-large);
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
