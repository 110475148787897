@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "../base/variables";

.autocomplete {
  & > div {
    position: relative;
    width: 100%;
  }
}

.autocompleteInput {
  @include base-input;

  font-family: museo-sans-300, sans-serif;
  font-size: 1.3rem;
  line-height: 1.5;
  overflow: hidden;
  text-indent: .2rem;
  text-overflow: ellipsis;
  width: 100%;
}

.menu {
  background-color: hsl(0, 0%, 100%);
  border-radius: .2rem;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, .1), 0 4px 11px hsla(0, 0%, 0%, .1);
  box-sizing: border-box;
  margin: .8rem 0;
  max-height: 30rem;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 600;
}

.item {
  background-color: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: .8rem 1.2rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.emptyItem {
  color: hsl(0, 0%, 60%);
  text-align: center;
}

.highlightedItem {
  background-color: $blueExtraLight;
}
