@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/sizes.scss";
@import "media.scss";

.container {
  align-items: center;
  background-color: $sl-light-grey;
  border-bottom: .2rem solid $sl-grey;
  box-shadow: inset 0 .4rem .8rem 0 rgba(0, 0, 0, .13);
  display: flex;
  font-family: Museo-Sans-300, sans-serif;
  font-size: 1.4rem;
  height: 7.5rem;
  justify-content: flex-end;
  margin: calc(var(--space) * -3) 0 var(--space-large) calc(var(--space-large) * -1);
  padding: calc(var(--space) * 1.3) var(--space-large);
  width: calc(100% + var(--space-large) * 2);

  .businessSelectorContainer {
    display: flex;

    @include phone {
      align-items: center;
      flex: 1;
      padding-left: var(--space);
    }
  }
}

.select {
  width: 30rem;

  @include phone {
    width: 100%;
  }
}

.label {
  color: $mine-shaft;
  font-family: $font-body-bold;
  padding: 1.5rem 2rem;

  @include phone {
    padding: 0 var(--space-s);
  }
}

.optionComponent {
  border-bottom: .1rem solid $sl-grey;

  &:last-child {
    border-bottom: none;
  }
}

.error {
  font-family: $font-body;
  font-size: $large;
  min-height: 3.3rem;
  padding: $size-small;
  text-align: center;
}

.option {
  display: flex;
  flex-direction: column;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bizOwners {
    display: inline-block;
    max-width: 100%;
    padding: .2ex 0;
  }

  .bizname {
    font-family: $font-body-bold;
  }

  .activeFlag {
    margin-left: 1rem;
  }
}
