.showMoreWrap {
  .icon,
  .content {
    transition: all .3s ease;
  }

  .header {
    align-items: center;
    display: flex;

    &:hover {
      cursor: pointer;
    }

    .icon {
      height: 2.4rem;
      width: 2.4rem;

      svg {
        vertical-align: middle;
      }
    }

    .name {
      flex: 1;
    }
  }

  .content {
    display: none;
  }
}

.showMore {
  > .content {
    display: block;
  }

  > .header {
    .icon {
      transform: rotate(180deg);
    }
  }
}
