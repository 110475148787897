
.addressLabel {
  display: flex;
  justify-content: space-between;

  .enterManuallyButton {
    font-size: 1.2rem;
    padding: 0 .4rem 0 0;
  }
}
