@import "shared/theme/colours.scss";
@import "grid.scss";

.formWrapper {
  margin: 2rem 0;
}

.showMoreHeader {
  align-items: center;
  background: $pebble15;
  display: grid !important;
  font-family: museo-sans-500, sans-serif;
  font-size: 1.6rem;
  margin: 0 !important;
  min-height: 4.8rem;
  padding: 0 1rem;

  @include layout_details;

  :global {
    .show-more-icon {
      grid-column: span 1;
      grid-column-end: -1;
      justify-self: end;
    }

    .show-more-name {
      grid-column-end: -2;
      grid-column-start: 1;
      grid-row: 1;
    }
  }
}

.btnWrapper {
  @include grid_btn(2);

  > button {
    margin-top: 1rem;
    min-width: unset;
    padding: 0;
  }
}
