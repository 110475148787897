@import "../../../theme/colours";
@import "../../../theme/fonts";

@mixin placeholder-color {
  color: $brown-grey;
  opacity: 1;
}

@mixin input-border {
  border: .1rem solid $brown-grey;
  border-radius: .2rem;
}

@mixin focus-border {
  border-color: $ocean;
  box-shadow: 0 0 0 1px $ocean;
  outline: none;
}

$input-padding: .8rem;
$input-color: $mine-shaft;
$input-width: 24rem;

@mixin input-typography {
  font-family: $font-body !important;
  font-size: $regular;
  line-height: 1.5;
  text-indent: .2rem;
}

@mixin base-input {
  @include input-border;

  color: $mine-shaft;
  padding: .6rem;
}

@mixin divider {
  background-color: darken($sl-grey, 10%);
  margin-bottom: .7rem;
  margin-top: .7rem;
  width: .1rem;
}

@mixin input-wrapper-label {
  margin-bottom: var(--space-medium);
  width: $input-width;
}
