@import "media.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "grid.scss";

.sectionWrapper {
  border: 1px solid $sl-grey;
  margin-bottom: var(--space-m);
  margin-top: 0;
  padding: 1rem;

  @include layout-details;
}

.fullWidthSection {
  @include tablet-portrait {
    grid-column: span 8;
  }

  @include phone {
    grid-column: span 4;
  }

  grid-column: span 10;

  @include layout-details;
}

.halfWidthSection {
  @include tablet-portrait {
    grid-column: span 8;
  }

  @include phone {
    grid-column: span 4;
  }

  grid-column: span 5;
  height: fit-content;

  @include layout-half-details;
}

.title {
  font-family: $font-header;
  font-size: $xlarge;
  grid-column-end: -1;
  grid-column-start: 1;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  grid-column: unset;
  grid-column-end: span 2;
  margin: 0;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;

  > label,
  .fieldTitle {
    margin-bottom: .6rem;
    padding: 0;
  }
}

.multiRowField {
  justify-content: space-between;
}

.thinCheckbox {
  grid-column-end: span 2;
  margin-bottom: 0 !important;

  > input {
    align-self: center !important;
    margin-top: 0 !important;
  }
}

.checkBox {
  margin-top: .6rem;
  word-break: normal;

  > input {
    align-self: center !important;
    margin-top: 0 !important;
  }
}

.datePicker {
  margin: 0 !important;
  width: 100% !important;
}

.phoneInput {
  border: .1rem solid $brown-grey !important;
  border-radius: .2rem !important;
  color: $mine-shaft !important;
  font-family: $font-body !important;
  font-size: $regular !important;
  padding-bottom: 15px !important;
  padding-left: 40px !important;
  padding-top: 16px !important;
  text-indent: .2rem !important;
  width: 100% !important;

  @include phone {
    padding-bottom: 17px !important;
    padding-top: 17px !important;
  }

  &:focus {
    border-color: $ocean !important;
    box-shadow: 0 0 0 1px $ocean !important;
    outline: none !important;
  }
}

.phoneButton {
  > div {
    padding-left: 5px !important;
    transform: scaleX(.8) scaleY(.8) !important;
    width: 40px !important;
  }
}

.phoneDropDown {
  border-radius: 2px !important;
  font-family: $font-body !important;
  font-size: $regular !important;
}

.valueItem {
  input {
    text-align: right;
  }
}

.valueItemText {
  justify-content: flex-end;
}

.buttonGroupHeader {
  margin-bottom: .5rem;
}

.incomeButtonGroup {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonWrapper {
  height: 3.3rem;
}

.closeButtonWrapper {
  width: 25%;
}

.addButtonWrapper {
  width: 75%;
}

.closeButton {
  height: 100%;
  margin: 0;
  padding: 0;
}

.incomeAddButton {
  align-items: center;
  display: flex;
  font-size: $regular;
  height: 100%;
  justify-content: space-between;
  min-width: unset;
  padding: 0 var(--space);
  width: 100%;
}

.financialYearField {
  @include desktop {
    grid-column-end: span 1;
    grid-column-start: -4;
  }

  @include tablet-portrait {
    grid-column-start: 3;
  }
}

.singleColumnWrapperOnDesktop {
  @include desktop {
    grid-column-end: span 1;
  }
}

.atFirstColumnOnDesktop {
  @include desktop {
    grid-column-start: 1;
  }
}

.singleColumnWrapper {
  grid-column-end: span 1;
}

.atFirstColumnOnMobile {
  @include tablet-portrait {
    grid-column-start: 1;
  }

  @include phone {
    grid-column-start: 1;
  }
}

.atThirdColumnOnDesktop {
  @include desktop {
    grid-column-start: -4;
  }
}

.atThirdColumnOnTablet {
  @include tablet-portrait {
    grid-column-start: 3;
  }
}

.atFourthColumnOnDesktop {
  @include desktop {
    grid-column-start: 4;
  }
}

.atFifthColumnOnDesktop {
  @include desktop {
    grid-column-start: -2;
  }
}

.atLastColumnOnMobile {
  @include tablet-portrait {
    grid-column-end: span 2;
    grid-column-start: 7;
  }

  @include phone {
    grid-column-end: span 2;
    grid-column-start: 3;
  }
}

.remove {
  color: $roman;
  font-family: $font-open-sans;
  font-size: 1.3rem;
  justify-self: end;
  width: auto;

  @include tablet-portrait {
    @include place(3, 8);
  }

  @include phone {
    @include place(3, 4);
  }

  @include place(2, 10);
}

.addressField {
  grid-column: 1/4;
  width: auto;
}
