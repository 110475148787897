@import "grid.scss";

.wrapper {
  @include layout-details;
}

.saveButton {
  grid-column: unset;
  grid-column-end: span 2;
  min-width: unset;
  padding: 0;
}
