@import "shared/theme/colours.scss";
@import "shared/theme/styles.scss";
@import "media.scss";

.routeItem {
  @include desktop {
    margin: 0;
    padding: 0 var(--space-medium);
  }

  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  color: $sl-dark-grey;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-family: Museo-Sans-700, sans-serif;
  font-size: 1.3rem;
  justify-content: space-between;
  margin: 0 var(--space-medium);
  min-height: 5.2rem;
  padding: 0 var(--space);
  position: relative;
  text-align: left;
  transition: border-width .2s;

  &:hover {
    color: $sl-dark-grey;
    text-decoration: none;
  }
}

.routeItemShrink {
  flex-shrink: 0;
}

.routesGroupItem {
  border: none;
  margin: 0;
  padding: 0;

  .routeItem {
    padding-left: var(--space-xxxl);
  }

  .popoverTrigger {
    margin: 0 var(--space-m);
  }
}

.activedRoute {
  border-bottom-color: $reaRed;
  border-style: solid;
  border-top-color: transparent;
  border-width: .4rem 0;
}

.popoverTrigger {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 5.2rem;
  padding: var(--space-large) var(--space);
  width: 100%;
}

.routesMenu {
  background-color: $sl-light-grey;
  min-width: 100%;
  overflow: hidden;
  transition: max-height .2s;

  & > a {
    @include desktop {
      padding: var(--space-large) calc(var(--space-large) * 2);
    }

    border-bottom: 1px solid $sl-grey;
    border-top: none;
    font-family: Museo-Sans-300, sans-serif;
    white-space: nowrap;

    &:hover {
      background-color: $sl-light-grey;
    }
  }
}

.overlayMenu {
  @include shadow;

  left: 0;
  position: absolute;
  top: 100%;
  z-index: 2020;
}
