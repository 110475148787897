@import "shared/theme/colours.scss";

.bubble {
  box-shadow: .5px 1px 2.5px 0 rgba(0, 0, 0, .3);
  margin-right: 1rem;
  margin-top: .2rem;
  padding: .7rem;
  position: relative;

  &::before {
    border-bottom: solid .5rem transparent;
    border-left: solid .5rem transparent;
    border-right: solid .5rem rgba(0, 0, 0, .09);
    border-top: solid .5rem transparent;
    content: "";
    left: -1rem;
    position: absolute;
    top: .8rem;
    width: 0;
  }

  &::after {
    border-bottom: solid .7rem transparent;
    border-left: solid .7rem transparent;
    border-right: solid .7rem $white;
    border-top: solid .7rem transparent;
    content: "";
    left: -1.1rem;
    position: absolute;
    top: .6rem;
    width: 0;
  }
}
