@import "shared/theme/colours.scss";

.contentWrapper {
  line-height: 1.8rem;

  .collapseButton {
    background: transparent;
    letter-spacing: .013rem;
    outline: none;
  }
}
