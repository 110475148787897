@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/spacing.scss";
@import "grid.scss";

.override {
  margin-top: 2.3rem;

  input[type=checkbox] {
    align-self: center;
    margin-top: 0;
  }
}

.wrapper {
  border: 1px solid $sl-grey;
  margin-bottom: 2rem;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;

  .removeButton {
    color: $roman;
    font-family: $font-open-sans;
    font-size: 1.3rem;
  }
}

.editInLotusNotesLinkRow {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;

  .editInLotusNotesLink {
    background: none;
    border: none;
    color: $ocean;
    font-family: $font-open-sans;
    font-size: 1.3rem;
    letter-spacing: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}
