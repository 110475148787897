@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.extraInfoContainer {
  @include phone {
    align-items: flex-start;
    flex-direction: column;
    line-height: initial;
  }

  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: $regular;
  justify-content: space-between;
  padding-top: var(--space);
  width: 100%;

  .leftInfo {
    @include phone {
      align-items: flex-start;
      flex-direction: column;
      line-height: initial;
    }

    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: $regular;
    word-break: break-word;

    .lenderLogo {
      @include phone {
        padding-right: 0;
      }

      padding-right: var(--space-l);

      img {
        height: 4rem;
      }
    }

    .applicantNames {
      @include phone {
        margin: var(--space) 0;
        padding-right: 0;
      }

      padding-right: var(--space);
    }
  }

  .applicationNumber {
    @include phone {
      text-align: left;
    }

    color: $sl-dark-grey;
    font-size: 1.1rem;
    letter-spacing: initial;
    line-height: normal;
    text-align: end;
    white-space: pre-wrap;

    .applicationNumberLink {
      padding-left: .3rem;
      vertical-align: unset !important;
    }
  }
}
