@import "media.scss";

.selectWrapper {
  height: 16rem;
  padding: 1rem 0 1rem 0;
  width: 100%;

  .select {
    padding: 1rem;
    z-index: 1000;
  }

  > :first-child {
    padding: 0 0 1rem 0;
  }

  > :last-child {
    padding: 0 0 1rem 0;
  }
}

.popupWrapper {
  @include phone {
    width: 30rem;
  }

  overflow: visible;
  padding: var(--space) 0 1rem 0;
  width: 25rem;
}

.selectItem {
  width: 19.5rem;
}
