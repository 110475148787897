@import "shared/theme/colours.scss";

.overlay {
  background: rgba(165, 173, 181, .4);
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.show {
  align-items: center;
  display: flex;
  justify-content: center;
}
