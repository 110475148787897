@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";

.templateListModal {
  overflow: visible;
}

.templateSelectorWrapper {
  height: 11rem;
  padding-top: .5rem;
  width: 100%;
}

.btnAddTasks {
  height: 3rem;
  min-width: 19rem;
}
