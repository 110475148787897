@import "media.scss";
@import "shared/theme/fonts.scss";

.container {
  display: flex;
  margin-bottom: var(--space-medium);

  @include tablet-portrait {
    flex-direction: column;
  }

  @include phone {
    flex-direction: column;
  }
}
