@import "media.scss";
@import "shared/theme/fonts.scss";

.summaryReport {
  background-color: rgb(240, 240, 240);
  display: flex;

  @include desktop {
    > div {
      margin-right: var(--space-medium);
    }
  }

  @include tablet-portrait {
    > div {
      margin-right: var(--space-medium);
    }
  }

  @include phone {
    flex-direction: column;
  }
}

.summarySmallSection {
  background-color: $white;
  display: flex;
  padding: 0 var(--space-large);

  @include phone {
    margin-bottom: 1rem;
  }
}

.summaryLargeSection {
  background-color: $white;
  display: flex;
  padding: 0 var(--space-large);

  @include desktop {
    div:first-child {
      margin-right: var(--space-medium);
    }
  }
}
