@import "media.scss";
@import "shared/theme/fonts.scss";

.summaryTileSection {
  background-color: $white;
  border-radius: 2px;
  column-gap: 12px;
  display: grid;
  font-size: 1.4rem;
  grid-template-columns: auto 3fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: var(--space-s);
  row-gap: 3px;
}

.title {
  align-self: center;
  color: $mine-shaft;
  font-family: $font-body-super-bold;
  font-size: 1.4rem;

  @include phone {
    font-size: 1.2rem;
  }
}

.itemKey {
  align-self: center;
  color: $sl-dark-grey;
  padding-right: var(--space-s);

  > p {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

.currentItemAmount {
  align-self: center;
  border-bottom: 1px solid $mine-shaft;
  color: $mine-shaft;
  font-family: $font-open-sans;

  > div {
    font-size: 10px;
  }

  @include desktop {
    font-size: 2rem;
  }

  @include phone {
    font-size: 1.6rem;
  }
}

.previousItemAmount {
  align-self: center;
  color: $mine-shaft;
  font-family: $font-open-sans;
  font-size: 1.5rem;

  @include phone {
    font-size: 1.4rem;
  }
}
