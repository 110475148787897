@import "grid.scss";

.purposeDetails {
  @include row(span 2);
}

.refinanceReason {
  @include column(9);

  @include phone {
    @include column(3 / span 2);
  }

  @include tablet-portrait {
    @include column(7 / span 2);
  }
}

.isTopUp {
  @include desktop {
    @include column(3 / span 2);
  }
}

.combinedLoanAmount {
  @include desktop {
    @include column(5 / span 2);
  }
}

.loanTermInput {
  display: flex;

  & div:last-child {
    margin-right: 0;
  }
}
