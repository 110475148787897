@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";

.expenseWrapper {
  @include grid($number-of-columns: 4, $spacing: var(--space-medium));

  align-items: center;
  grid-row-gap: 0;
  padding: 0 var(--space);
}

.upperBorder {
  border-top: dotted .2rem $sl-grey;
}

.typeLabel {
  width: 100%;

  @include span(2);
}

.amount {
  @include place(1, 3);

  margin: 0 var(--space) var(--space) 0;

  input {
    text-align: right;
  }
}

.alignTextRight {
  justify-content: flex-end;
}

.unit {
  @include place(1, 4);

  align-self: end;
  margin: 0 var(--space) var(--space) 0;
}

.monthlyValue {
  display: none;
}

.investmentExpenseLabel {
  margin: var(--space) 0;
}

.investmentUnit {
  align-self: unset;
}
