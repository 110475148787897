@import "shared/theme/colours.scss";

.linkTitle {
  align-items: center;
  border-bottom: none;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  padding: .9rem 1rem 0 1rem;
  padding-bottom: .4rem;
}

.activeLink {
  border-bottom: .4rem solid $sl-gold;
  color: $mine-shaft;
  padding-bottom: none;
}

ul.alignRight {
  left: auto !important;
  right: -5px;
}
