@import "shared/theme/fonts.scss";
@import "shared/theme/colours.scss";
@import "media.scss";

.placeholder {
  font-family: $font-body;
  font-size: 1.3rem;
  margin: var(--space-xxxl) 0;
}

.logGroup {
  border: none !important;
  margin-bottom: 0 !important;
  padding: var(--space-small) 0 !important;

  & [class~="log_wrapper_item_left"] {
    &::before {
      display: none;
    }
  }
}
