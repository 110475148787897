@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "grid.scss";
@import "media.scss";

.applicant {
  grid-column: 1/span 1;
  grid-row: span 2;
}

.setFirstItem {
  order: -1;
}

.lenderInfoWrapperWithOneSpan {
  grid-row: span 1;

  @include tablet-portrait {
    grid-column: 4/span 1;
    grid-row: 1/span 1;
  }
}

.lenderInfoWrapperWithTwoSpan {
  grid-row: span 2;

  @include tablet-portrait {
    grid-column: 4/span 1;
    grid-row: 1/span 2;
  }
}

.lenderBrokerCodeWithLenderSpanOne {
  @include tablet-portrait {
    grid-column: 4/span 1;
    grid-row: 2/span 1;
  }
}

.lenderBrokerCodeWithLenderSpanTwo {
  @include tablet-portrait {
    grid-column: 4/span 1;
    grid-row: 3/span 1;
  }
}

.lenderInfoWrapperNew {
  .lenderInfo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .portalLink {
      color: $ocean;
      font-weight: 600;
      margin-top: .4rem;
      text-align: center;

      &:hover {
        text-decoration: none;
      }

      svg {
        margin-left: calc(var(--space) * .4);
        vertical-align: -.2rem;
      }
    }
  }
}

.lenderInfoWrapper {
  grid-row: span 2;

  .lenderInfo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .portalLink {
      color: $ocean;
      font-weight: 600;
      margin-top: .4rem;
      text-align: center;

      &:hover {
        text-decoration: none;
      }

      svg {
        margin-left: calc(var(--space) * .4);
        vertical-align: -.2rem;
      }
    }
  }
}

.dateInput {
  :global(.dx-texteditor-input-container) {
    @include phone {
      max-width: 15rem;
    }

    max-width: 20rem;

    > input {
      min-height: 1.25rem !important;
    }
  }

  :global(.dx-dropdowneditor-input-wrapper) {
    @include phone {
      min-width: 15rem;
    }

    min-width: 20rem;
  }

  :global(.dx-datebox.dx-editor-outlined.dx-datebox-calendar) {
    border: none;
    font-family: $font-body;
    font-size: 1.4rem;
  }

  :global(.dx-texteditor.dx-texteditor.dx-texteditor.dx-state-focused) {
    box-shadow: none;
  }

  :global(.dx-placeholder) {
    @include desktop {
      padding: 0;

      &::before {
        line-height: 1.4rem;
        padding: 0;
      }
    }

    align-items: center;
    display: flex;
  }

  input {
    @include desktop {
      margin: 0;
      padding: 0;
    }
  }
}
