@import "shared/theme/colours.scss";

.box {
  border: solid .05rem $sl-grey;
  margin-bottom: var(--space-medium);
  padding: var(--space-medium);
}

.curvy {
  border-radius: .85rem;
}
