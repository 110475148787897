@import "shared/theme/colours.scss";

.confirmDeleteModal {
  width: 44rem;
}

.message {
  color: $brick;
  padding: var(--space-medium) 0;

  & > p {
    font-size: 1.4rem;
  }
}

.buttonsContainer {
  display: flex;
}

.deleteButton {
  align-items: center;
  border-color: $brick;
  color: $brick;
  display: flex;
  justify-content: space-between;
  margin-right: var(--space);
  min-width: 14rem;
  padding: var(--space-s) var(--space);
}

.cancelButton {
  min-width: 14rem;
  padding: var(--space-s) var(--space);
}
