@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";
@import "shared/theme/sizes.scss";

table.tableStyles {
  border: .2rem solid $sl-grey;
  border-radius: .2rem;
  font-family: $font-body;
  font-size: $large;
  width: 100%;

  th {
    font-family: $font-body-bold;
    padding: 2rem;
  }

  tr {
    border: .1rem solid $sl-grey;
  }

  td {
    padding: 1rem 2rem;
  }
}

.link {
  font-family: $font-body-bold !important;
}

.tableRow {
  border: .1rem solid $sl-grey;
}

.notice {
  padding: 2rem;
}
