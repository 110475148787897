@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

$input-width: 19.5rem;
$input-spacing: 1.2rem;

.select {
  display: inline-block;
  width: $input-width;

  label {
    color: $mine-shaft;
    font-family: $font-body-bold, sans-serif;
    font-size: $large;
  }
}

.lendersSelect {
  display: inline-block;
  width: 3 * $input-width + 2 * $input-spacing;
}

.purposeCategory {
  display: flex;

  .select {
    margin-bottom: 2rem;
    width: $input-width;

    label {
      color: $mine-shaft;
      font-family: $font-body-bold, sans-serif;
      font-size: $large;
      margin-bottom: 0;
      padding: 0 0 7px 0;
      width: inherit;
    }
  }
}

.label {
  display: inline-block;
  margin: 0 1.2rem 2rem 0;
  padding: 0;
  vertical-align: top;
  width: $input-width;

  label {
    color: $mine-shaft;
    font-family: $font-body-bold, sans-serif;
    font-size: $large;
    margin-bottom: 0;
    padding: 0;
    width: inherit;
  }
}

.errorMessage {
  color: $brick;
  margin-top: .7rem;
  width: inherit;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
}

.input {
  border: .05rem solid $brown-grey;
  border-radius: .2rem;
  color: $mine-shaft;
  display: block;
  font-family: $font-body;
  font-size: $large;
  height: 3rem;
  margin-top: .7rem;
  padding: 0 .7rem;
  width: 48%;
}

.error {
  border-color: $brick;
}

.inputText {
  height: 3rem;
  margin-top: .7rem;
}

.short {
  width: 5.8rem;
}
