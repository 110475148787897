@import "grid.scss";
@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.loanDetails {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.loanIndex {
  margin-right: 5rem;
}

.loanTitleWrapper {
  @include phone {
    align-items: flex-start;
    flex-direction: column;
  }

  justify-content: space-between;
}

.inactiveLoanTag {
  @include phone {
    align-self: flex-start;
    margin-left: 0;
  }

  align-self: center;
  color: $brick;
  display: inline-block;
  flex-shrink: 0;
  font-family: OpenSans, sans-serif;
  font-size: $regular;
  font-weight: normal;
  line-height: normal;
  margin-left: 1.3rem;
}

.title {
  font-family: Museo-Sans-500, serif;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

.linksWrapper {
  padding: 0 1rem;
  width: 100%;

  svg {
    margin-left: calc(var(--space) * .4);
    vertical-align: -.2rem;
  }

  .link {
    color: $ocean;
    display: block;
    font-family: Museo-Sans-300, serif;
    font-size: 1.3rem;
    margin-bottom: .4rem;
  }
}
