@import "shared/theme/colours.scss";
$navbar-height: 5.2rem;

.top-navbar {
  background-color: $pebble05;
  box-shadow: 0 .2rem .9rem 0 rgba(0, 0, 0, .2);
  color: $sl-dark-grey;
  display: flex;
  flex: 1;
  font-family: Museo-Sans-700, sans-serif;
  font-size: 1.3rem;
  height: $navbar-height;
  justify-content: space-between;
  letter-spacing: .02rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: auto;

  & * {
    min-width: 0;
  }

  .section {
    display: flex;
    height: 100%;
  }

  .dd-header {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
    text-align: left;

    a {
      color: inherit;
      display: inline-block;
      height: 100%;
      text-decoration: none;
    }
  }

  ul {
    display: block;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;

    li {
      background-color: $sl-light-grey;
      box-shadow: 0 .8rem 1.6rem 0 rgba(0, 0, 0, .2);
      display: block;
      margin: auto;
      max-width: 23rem;
      min-width: 13rem;
      padding: 1.4rem 1.8rem;
      position: relative;
      z-index: 1;

      a {
        color: inherit;
        display: block;
        text-decoration: none;
      }

      button {
        font-family: Museo-Sans-300, sans-serif;
        font-weight: normal;
      }
    }
  }

  ul.dd-user-list {
    left: auto;
    right: 0;
  }

  .dropdown-trigger {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;

    .username {
      width: fit-content;
    }

    .business-name {
      font-family: Museo-Sans-500, sans-serif;
      max-width: 18rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dropdown-content {
    background-color: white;
    cursor: pointer;

    &:focus-within {
      outline: 1px dotted $mine-shaft;
      outline: 5px auto -webkit-focus-ring-color;
      z-index: 2;
    }

    .dropdown-item,
    button.dropdown-item {
      align-items: center;
      background-color: transparent;
      border: none;
      color: $sl-dark-grey;
      display: flex;
      font-family: Museo-Sans-300, sans-serif;
      justify-content: space-between;
      padding-bottom: .1rem;
      text-align: left;
      width: 100%;

      &:active {
        color: inherit;
        font-family: Museo-Sans-500, sans-serif;
      }
    }

    button.dropdown-item:focus {
      outline: none;
    }

    .initials {
      align-items: center;
      display: flex;
      padding: 0;

      span {
        background-color: $sl-grey;
        border-radius: 50%;
        font-family: Museo-Sans-700, sans-serif;
        padding: .7rem;
      }
    }
  }
}

.arrow-down {
  border-left: .7rem solid transparent;
  border-right: .7rem solid transparent;
  border-top: .7rem solid $sl-dark-grey;
  height: 0;
  margin-left: 1rem;
  width: 0;
}
