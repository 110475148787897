@import "grid.scss";
@import "media.scss";
@import "shared/theme/colours.scss";
@import "shared/theme/fonts.scss";

.container {
  @include grid(5);

  @include phone {
    @include grid(1);
  }

  align-items: baseline;
  font-size: 1.1rem;

  .tabUpdated {
    @include span(1);

    @include phone {
      @include span(1);
    }

    color: $sl-dark-grey;
  }

  .mainSummary {
    @include span(2);

    @include phone {
      @include span(1);
    }

    font-size: $regular;
    word-break: break-word;
  }

  .updates {
    @include span(2);

    @include phone {
      text-align: left;

      @include span(1);
    }

    color: $sl-dark-grey;
    text-align: end;
    text-transform: uppercase;
    white-space: pre-line;
    word-break: break-word;
  }
}
